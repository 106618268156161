import ApiConnector from "../api/apiConnection";
import {SubmissionError, actionTypes} from "redux-form";
import store from '../store/store'
import _ from 'lodash'
import React from "react";
import { notification } from 'antd';
import i18next from 'i18next';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_ERROR = "LOGIN_ERROR";

export const LOGIN_CHECK = 'LOGIN_CHECK';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';

export const SHOW_FORGOTTEN_PASS_MODAL = 'SHOW_FORGOTTEN_PASS_MODAL'
export const HIDE_FORGOTTEN_PASS_MODAL = 'HIDE_FORGOTTEN_PASS_MODAL'

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_ARTICLES = 'ADD_ARTICLES';
export const ADD_IMAGES = 'ADD_IMAGES';
export const ADD_RELATED = 'ADD_RELATED';

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const MODIFY_AMOUNT = 'MODIFY_AMOUNT';
export const CLEAR_CART = 'CLEAR_CART';
export const BEFORE_REDIRECT_TO_SUMMARY = 'BEFORE_REDIRECT_TO_SUMMARY';

export const ADD_PREMIUM_DELIVERY = 'ADD_PREMIUM_DELIVERY'
export const REMOVE_PREMIUM_DELIVERY = 'REMOVE_PREMIUM_DELIVERY'

export const SHOW_SIDE = 'SHOW_SIDE';
export const HIDE_SIDE = 'HIDE_SIDE';

export const GET_CUSTOMER_DATA = 'GET_CUSTOMER_DATA';
// export const GET_ARTICLE_GROUPS = 'GET_ARTICLE_GROUPS'
export const GET_EMAIL = 'GET_EMAIL'
export const GET_ORDERS = 'GET_ORDERS';
export const LOAD_MORE_ORDERS = 'LOAD_MORE_ORDERS';

export const GET_ORDER_ITEMS = 'GET_ORDER_ITEMS';
export const SET_DELIVERY_COST = 'SET_DELIVERY_COST';
export const ACCEPT_NEWSLETTER = 'ACCEPT_NEWSLETTER';

export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_INSTALLATION_COUNTRY = 'GET_INSTALLATION_COUNTRY'
export const SET_CITY = 'SET_CITY'
export const SET_STREETS = 'SET_STREETS'
export const SET_ADDRESS_TYPE = 'SET_ADDRESS_TYPE'

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const SHOW_MOBILE_MENU = 'SHOW_MOBILE_MENU'

export const GET_SEARCH_VALUE = 'GET_SEARCH_VALUE'

export const GET_CAMPAIGNS = 'GET_CAMPAIGNS'


export const STORE_SELECTED = 'STORE_SELECTED'

export const REQUIRED_INVOICE_ADDRESS = 'REQUIRED_INVOICE_ADDRESS'

export const SHOW_POSTAL_ADDRESS_MODAL = 'SHOW_POSTAL_ADDRESS_MODAL'
export const SHOW_DELIVERY_ADDRESS_MODAL = 'SHOW_DELIVERY_ADDRESS_MODAL'
export const SHOW_INVOICE_ADDRESS_MODAL = 'SHOW_INVOICE_ADDRESS_MODAL'

export const GET_ARTICLE_CATEGORIES = 'GET_ARTICLE_CATEGORIES';

export const CHECK_STORES = 'CHECK_STORES'
export const GUEST_DELIVERY_ADDRESS = 'GUEST_DELIVERY_ADDRESS'
export const GUEST_INVOICE_ADDRESS = 'GUEST_INVOICE_ADDRESS'

export const SET_ADMINISTRATIVE_FEE = 'SET_ADMINISTRATIVE_FEE';
export const REMOVE_ADMINISTRATIVE_FEE = 'REMOVE_ADMINISTRATIVE_FEE';

export const GET_TENANT_DATA = 'GET_TENANT_DATA';

export const SHOW_CUSTOMER_TYPE_MODAL = 'SHOW_CUSTOMER_TYPE_MODAL';
export const SET_CUSTOMER_TYPE = 'SET_CUSTOMER_TYPE';

export const SHOW_CUSTOMER_SETTINGS_MODAL = "SHOW_CUSTOMER_SETTINGS_MODAL";

export const GET_TEMPORARY_CUSTOMER = "GET_TEMPORATY_CUSTOMER";

export const SET_NUMBER_OF_FORMS = "SET_NUMBER_OF_FORMS";


export function changeLanguage(langFunction) {
    return dispatch => {
        dispatch({
            type: CHANGE_LANGUAGE,
            payload: {
                language: langFunction
            }
        });
    }
}

export function getTenantData() {
    return (dispatch) => {
        privateGetTenantData(dispatch)
    }
}

export function showMobileMenu() {
    return {
        type: SHOW_MOBILE_MENU
    }
}



export function selectStore(customerNumber) {
    return dispatch => {
        ApiConnector.selectStore(customerNumber)
            .then( response => {
                dispatch({
                    type: STORE_SELECTED
                })
                dispatch(getCustomerData())
            })
    }
}

function privateGetTenantData(dispatch) {
        ApiConnector.getTenantData()
        .then(response => {
            const tenantId = store.getState().auth.tenantId
            response.data.filter(tenant => {
                if(tenantId === tenant.id) {
                    dispatch({
                    type: GET_TENANT_DATA,
                    payload: {
                        currency: tenant.currency,
                        vat: tenant.vat === null ? 0 : tenant.vat,
                        businessPrivateToggle: tenant.businessPrivateToggle,
                        publicTenant: tenant.publicTenant,
                        adminOrderFormType: tenant.adminOrderFormType
                    }
                })
            }
        })
    })
}

export function login(username, password) {
    const user = {
        username,
        password
    }
    return (dispatch) => {
        dispatch({
            type: LOGIN_CHECK,
        });
        return ApiConnector.login(user)
            .then(response => {
                if(response.data.access_token){
                    if(response.data.admin === true) {
                        throw new SubmissionError({
                            _error: `${i18next.t('notification.error_login_cust')}`
                        })
                    }

                    const user = {
                        access_token: response.data.access_token,
                        refresh_token: response.data.refresh_token,
                        roles: response.data.roles,
                        username: response.data.username,
                        tenant_id: response.data.tenant_id,
                    };

                    localStorage.setItem('user', JSON.stringify(user));

                    dispatch({
                        type: LOGIN,
                        user
                    });

                    privateGetTenantData(dispatch);

                    ApiConnector.checkStores()
                        .then(response => {
                            dispatch({
                                type: CHECK_STORES,
                                hasStores: response.data
                            });
                            dispatch(getCustomerData());
                            dispatch(getArticleCategories())
                        });
                    ApiConnector.getEmail()
                        .then(response => {
                            dispatch({
                                type: GET_EMAIL,
                                email: response.data.email
                            })
                        })

                } else {
                    throw new SubmissionError({
                        _error: `${i18next.t('notification.error_login')}`
                    })
                }
            })
            .catch((error) => {
                if(error.errors) {
                    throw new SubmissionError({
                        _error: error.errors._error
                    })
                }
                throw new SubmissionError({
                    _error: `${i18next.t('notification.login_failed')}`
                })
            })
    }
}

export function logout() {
    localStorage.removeItem('user');
    return {
        type: LOGOUT
    }
}

export function loginRequest() {
    return {
        type: LOGIN_REQUEST
    }
}

export function refreshToken(data) {
    const user = {
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        roles: data.roles,
        username: data.username
    };
    localStorage.setItem('user', JSON.stringify(user));
    return {
        type: REFRESH_TOKEN,
        user
    }
}

export function showCustomerTypeModal(visible = false) {
    return {
        type: SHOW_CUSTOMER_TYPE_MODAL,
        payload: {
            visible: visible
        }
    }
}

export function showCustomerSettingsModal(visible = false) {
    return {
        type: SHOW_CUSTOMER_SETTINGS_MODAL,
        payload: {
            visible: visible
        }
    }
}


export function showForgottenPassModal() {
    return {
        type: SHOW_FORGOTTEN_PASS_MODAL
    }
}

export function hideForgottenPassModal() {
    return {
        type: HIDE_FORGOTTEN_PASS_MODAL
    }
}
export function showPostalAddressModal() {
    return {
        type: SHOW_POSTAL_ADDRESS_MODAL
    }
}
export function showDeliveryAddressModal() {
    return {
        type: SHOW_DELIVERY_ADDRESS_MODAL
    }
}
export function showInvoiceAddressModal() {
    return {
        type: SHOW_INVOICE_ADDRESS_MODAL
    }
}

export function setCustomerType(customerType) {

    localStorage.setItem('privateCustomerType', customerType ? "true" : "false");

    return dispatch => {
        dispatch({
            type: SET_CUSTOMER_TYPE,
            payload: {
                privateCustomerType: customerType
            }
        });
    }
}

function hideEmail(email) {
    let hiddenEmail = "";
    for (let i = 0; i < email.length; i++) {
        if (i > 2 && i< email.indexOf("@") ) {
            hiddenEmail += "*";
        } else {
            hiddenEmail += email[i];
        }
    }
    return hiddenEmail;
}

export function getUsername(values) {
    return (dispatch) => {
        ApiConnector.forgotPassword(values)
            .then((response) => {
                const userEmail = hideEmail(response.data.email);
                    const openNotificationWithIcon = (type) => {
                        notification[type]({
                            duration: 0,
                            message: `${i18next.t('notification.username_sent')} ${userEmail}`
                        })
                    };
                    dispatch({
                        type: HIDE_FORGOTTEN_PASS_MODAL
                    });
                    openNotificationWithIcon('success')
            })
            .catch(() => {
                const openNotificationWithIcon = (type) => {
                    notification[type]({
                        duration: 0,
                        message: i18next.t('notification.error'),
                        description: i18next.t(`notification.no_email-${values.tenantId}`)
                    })
                };
                dispatch({
                    type: HIDE_FORGOTTEN_PASS_MODAL
                });
                openNotificationWithIcon('error');
            })
    }
}

export function postNewPassword(data, history) {
    return (dispatch) => {
        return ApiConnector.resetPassword(data)
            .then((response) => {

                if(response.data.errors) {
                    throw new SubmissionError({
                        _error: response.data.errors
                    })
                }
                if(response.data.success) {
                    history.push('/')
                }
            })
            .catch((error) => {
                if(error.errors && error.errors._error) {
                    if(error.errors._error.errors) {
                        const errorMessages = error.errors._error.errors.map(error => {
                            return <div key={error.message}>{error.message}</div>
                        })
                        throw new SubmissionError({
                            _error: <div>{errorMessages}</div>
                        })
                    } else {
                        const errorMessages = error.errors._error.map(error => {
                            return <div key={error}>{error}</div>
                        })
                        throw new SubmissionError({
                            _error: <div>{errorMessages}</div>
                        })
                    }

                }
            })

    }
}

export function getCustomerData() {
    return (dispatch) => {
        ApiConnector.getCustomerData()
            .then(response => {
                dispatch({
                    type: GET_CUSTOMER_DATA,
                    customer: response.data
                })
            })
    }
}

export function getShippingCost(cart) {
    const cartContents = cart.items
    return (dispatch) => {
        if(Object.keys(cartContents).length > 0) {
            const totalWeight = _(cartContents)
                .pickBy(product => {
                    return !product.article.freeShipping
                })
                .map(cartItem => {
                    return cartItem.amount*(cartItem.article.weight)
                })
                .reduce( (a,b) => {
                    return a + b
                })
            ApiConnector.getDeliveryCost(totalWeight, Object.keys(cartContents) )
                .then(response => {
                    dispatch({
                        type: SET_DELIVERY_COST,
                        deliveryCost: response.data

                    })
                })
        } else {
            dispatch({
                type: CLEAR_CART
            })
        }
    }
}

export function checkLogin() {
    return (dispatch) => {
            dispatch({
                type: LOGIN_CHECK,
            })
            ApiConnector.checkLogin()
                .then(response => {
                    if(response.data.success) {
                        if(response.data.admin) {
                            dispatch({
                                type: LOGOUT,
                            })
                            return
                        }
                        dispatch({
                            type: LOGIN,
                            hasStores: response.data.hasStores
                        })
                        dispatch(getCustomerData())
                        ApiConnector.getEmail()
                            .then(response => {
                                dispatch({
                                    type: GET_EMAIL,
                                    email: response.data.email,
                                    temporaryPassword: response.data.temporaryPassword
                                })
                            })
                        dispatch(getArticleCategories())
                        ApiConnector.refreshToken()
                    }
                    else {
                        dispatch(logout())
                    }
                })
                .catch(() => {
                    dispatch({
                        type: LOGIN_ERROR,
                    })
                    if(store.getState().auth.loggedIn) {
                        dispatch(logout())
                    }
                })
    }
}

export function getProduct(id, allowRelatedCall = true) {
    return (dispatch) => {
        ApiConnector.getProduct(id)
            .then(response => {
                const articleContainer = response.data
                if(!articleContainer) {
                    dispatch({
                        type: ADD_PRODUCT,
                        product: {
                            id,
                            nonExistant: true,
                            articles: []
                        }
                    })
                    return
                }
                dispatch({
                    type: ADD_PRODUCT,
                    product: articleContainer
                })
                ApiConnector.getArticles(articleContainer)
                    .then(response => {
                        const customerPayer = store.getState().customer.payer
                        const articles = response.data.map(article => {
                            if(article.payer === 'ALWAYS_CUSTOMER') {
                                article.payer = 'CUSTOMER'
                            } else {
                                article.payer = customerPayer
                            }
                            if(article.payer === 'TENANT') {
                                article.price = 0
                            }
                            return article
                        })
                        dispatch({
                            type: ADD_ARTICLES,
                            containerId: articleContainer.id,
                            articles
                        })
                    })
                ApiConnector.getRelated(articleContainer)
                    .then(response => {
                        dispatch({
                            type: ADD_RELATED,
                            containerId: articleContainer.id,
                            related: response.data
                        })
                        if(allowRelatedCall) {
                            response.data.forEach(relatedContainer => {
                                dispatch(getProduct(relatedContainer.id, false))
                            })
                        }
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export function getRecommendedProducts() {
    return (dispatch) => {
        return ApiConnector.getRecommendedProducts()
            .then(response => {
                if(!Array.isArray(response.data)) {
                    return
                }
                dispatch({
                    type: GET_PRODUCTS,
                    products: response.data
                })
                response.data.forEach(articleContainer => {
                    return ApiConnector.getArticles(articleContainer)
                        .then(response => {
                            const customerPayer = store.getState().customer.payer
                            const articles = response.data.map(article => {
                                if(article.payer === 'ALWAYS_CUSTOMER') {
                                    article.payer = 'CUSTOMER'
                                } else {
                                    article.payer = customerPayer
                                }
                                if(article.payer === 'TENANT') {
                                    article.price = 0
                                }
                                return article
                            });
                            dispatch({
                                type: ADD_ARTICLES,
                                containerId: articleContainer.id,
                                articles
                            })
                        })
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export function getProducts(limit, offset = 0, articleGroup, pattern) {
    return (dispatch) => {
        ApiConnector.getProducts(limit, offset, parseInt(articleGroup, 10), pattern )
            .then(response => {
                if(!Array.isArray(response.data)) {
                    return
                }
                dispatch({
                    type: GET_PRODUCTS,
                    products: response.data
                })
                response.data.forEach(articleContainer => {
                    ApiConnector.getArticles(articleContainer)
                        .then(response => {
                            const customerPayer = store.getState().customer.payer
                            const articles = response.data.map(article => {
                                if(article.payer === 'ALWAYS_CUSTOMER') {
                                    article.payer = 'CUSTOMER'
                                } else {
                                    article.payer = customerPayer
                                }
                                if(article.payer === 'TENANT') {
                                    article.price = 0
                                }
                                return article
                            })
                            dispatch({
                                type: ADD_ARTICLES,
                                containerId: articleContainer.id,
                                articles
                            })
                        })
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export function getOrder(orderNumber) {

    return (dispatch) => {
        ApiConnector.getOrder(orderNumber)
            .then(response => {
                dispatch({
                    type: GET_ORDERS,
                    orders: [response.data]
                })
            })
    }
}

export function getOrders(pattern = "") {
    return (dispatch) => {
        ApiConnector.getOrders(pattern)
            .then(response => {
                dispatch({
                    type: GET_ORDERS,
                    orders: response.data
                })
            })
    }
}

export function cancelOrder(orderNumber) {
    return (dispatch) => {
        return ApiConnector.cancelOrder(orderNumber)
            .then((response) => {
                if(response.status) {
                    dispatch(getOrders())
                    notification.success({
                        message: `${i18next.t('notification.success')}`,
                        description: `${i18next.t('notification.order_canceled_success')}`
                    });
                } else {
                    notification.error({
                        message: `${i18next.t('notification.error')}`,
                        description: `${i18next.t('notification.order_canceled_error')}`
                    });
                }
            })
            .catch(() => {
                notification.error({
                    message: `${i18next.t('notification.error')}`,
                    description: `${i18next.t('notification.order_canceled_error')}`,
                    duration: 0
                });
            })
    }
}

export function addToCart(product, article, amount, showSide=true) {
    return {
            type: ADD_TO_CART,
            product,
            article,
            amount,
            showSide
        }

}
export function getOrderItems(orderNumber) {
    return (dispatch) => {
        ApiConnector.getOrderItems(orderNumber)
            .then(response => {
                dispatch({
                    type: GET_ORDER_ITEMS,
                    orderItems: response.data,
                    orderNumber
                })
            })
    }
}

export function removeFromCart(articleId) {
    return {
        type: REMOVE_FROM_CART,
        articleId
    }
}

export function showSideCart(showStatus) {
    if(showStatus) {
        return {
            type: SHOW_SIDE
        }
    } else {
        return {
            type: HIDE_SIDE
        }
    }
}

export function modifyAmountInCart(articleId, amount) {
    return {
        type: MODIFY_AMOUNT,
        articleId,
        amount
    }
}

export function addPremiumDelivery() {
    return {
        type: ADD_PREMIUM_DELIVERY
    }
}

export function removePremiumDelivery() {
    return {
        type: REMOVE_PREMIUM_DELIVERY
    }
}

export function changeEmail(newEmail, temporaryPassword) {
    return (dispatch) => {
        return ApiConnector.changeEmail(newEmail, temporaryPassword)
            .then(response => {
                console.log(response)
                if(response.data.fieldErrors) {
                    throw new SubmissionError({
                        _error: response.data.fieldErrors
                    })
                }
                dispatch(checkLogin());
                const openNotificationWithIcon = (type) => {
                    notification[type]({
                        message: i18next.t('notification.success'),
                        description: i18next.t('notification.email_changed')
                    });
                };
                openNotificationWithIcon('success');
            })
            .catch((error) => {
                if(error.errors && error.errors._error) {
                    const errorMessages = Object.values(error.errors._error).map( errorMessage => {
                        return <div>{errorMessage}</div>
                    })
                    throw new SubmissionError({
                        _error: <div>{errorMessages}</div>
                    })
                }
                const openNotificationWithIcon = (type) => {
                    notification[type]({
                        message: i18next.t('notification.error')
                    });
                };
                openNotificationWithIcon('error');
            })
    }
}

export function changePassword(currentPassword, newPassword) {
    return (dispatch) => {
        return ApiConnector.changePassword(currentPassword, newPassword)
            .then(response => {
                if(response.data.fieldErrors) {
                    throw new SubmissionError({
                        _error: response.data.fieldErrors
                    })

                }
                dispatch(checkLogin());
                const openNotificationWithIcon = (type) => {
                    notification[type]({
                        message: i18next.t('notification.success'),
                        description: i18next.t('notification.pass_changed')
                    });
                };
                openNotificationWithIcon('success');
            })
            .catch((error) => {
                if(error.errors && error.errors._error) {
                    const errorMessages = Object.values(error.errors._error).map( errorMessage => {
                        return <div>{errorMessage}</div>
                    })
                    throw new SubmissionError({
                        _error: <div>{errorMessages}</div>
                    })
                }
                const openNotificationWithIcon = (type) => {
                    notification[type]({
                        message: i18next.t('notification.error'),
                        description: i18next.t('notification.pass_incorrect'),
                        duration: 0
                    });
                };
                openNotificationWithIcon('error');
            })
    }
}

export function clearRedirectedToSummary() {
    return {
        type: BEFORE_REDIRECT_TO_SUMMARY,
        payload: false
    }
}

export function placeOrder(data, history) {
    return (dispatch) => {
        return ApiConnector.createOrder(data)
            .then(response => {
                if(response.data.fieldErrors) {
                    throw new SubmissionError({
                        _error: response.data.fieldErrors
                    })
                }
                else if(response.data.operationError) {
                    throw new SubmissionError({
                        _error: response.data.operationError
                    })
                }
                dispatch({
                    type: CLEAR_CART
                })
                dispatch({
                    type: BEFORE_REDIRECT_TO_SUMMARY,
                    payload: true
                })
                history.push({
                    pathname: `/checkout/summary/${response.data.orderNumber}`
                });
                ApiConnector.sendConfirmationEmail(response.data)
                dispatch(acceptNewsletter(data.allowNewsletter));

                if(store.getState().auth.tenantId === 4) {
                    switch(process.env.REACT_APP_STAGE.trim()) {
                        case 'dev':
                            break;
                        case 'uat':
                            ApiConnector.sendConfirmationEmail({
                                ...response.data,
                                email: "testmail@wapasolutions.se"
                            });
                            break;
                        case 'prod':
                            ApiConnector.sendConfirmationEmail({
                                ...response.data,
                                email: "les.kundtjanst@loomis.com"
                            });
                            break;
                        default:
                            break;
                    }

                } else if (store.getState().auth.tenantId === 6) {
                    ApiConnector.sendConfirmationEmail({
                        ...response.data,
                        email: "EStoreOrder@loomis.com"
                    });
                }
            })
            .catch((error) => {
                if(error.errors && error.errors._error) {
                    const errorMessage = Object.values(error.errors._error);
                    throw new SubmissionError({
                        _error: <div>{errorMessage}</div>
                    });
                }
            })
    }
}

export function sendAnotherConfirmationEmail(data) {
    return () => {
        ApiConnector.sendConfirmationEmail(data)
            .then(() => {
                const openNotificationWithIcon = (type) => {
                    notification[type]({
                        message: i18next.t('notification.email_sent')
                    })
                };
                openNotificationWithIcon('success')
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export function createAccount(data) {
    return (dispatch) => {
        return ApiConnector.createAccount(data)
            .then(response => {
                if(response.data.success === true) {
                    const values = {
                        username: data.email,
                        password: data.password
                    }
                    dispatch(login(values.username, values.password))
                }
                else {
                    if(response.data.errors && response.data.errors.errors) {
                        const errorMessages = _.map( response.data.errors.errors, errorMessage => {
                           if(_.includes(errorMessage, 'username')) {
                               return <div key={errorMessage.field}>This email address is already used. Enter a different one.</div>
                           }
                           return <div key={errorMessage.field}>{errorMessage.message}</div>
                        })
                        throw new SubmissionError({
                            _error: <div>{errorMessages}</div>
                        })
                    }
                }
            })
    }
}

export function changeAddress(addressName, addressData) {
    return (dispatch) => {
        return ApiConnector.changeAddress(addressName, addressData)
            .then(response => {
                if(response.data.fieldErrors) {
                    throw new SubmissionError({
                        _error: response.data.fieldErrors
                    });
                }
                dispatch(getCustomerData());
            })
            .catch((error) => {
                if(error.errors && error.errors._error) {
                    const errorMessages = Object.values(error.errors._error).map( errorMessage => {
                        return <div>{errorMessage}</div>
                    })
                    throw new SubmissionError({
                        _error: <div>{errorMessages}</div>
                    })
                }
            })
    }
}

export function addGuestDeliveryAddress(addressData) {
    return (dispatch) => {
        dispatch({
            type: GUEST_DELIVERY_ADDRESS,
            addressData
        })
    }
}
export function addGuestInvoiceAddress(addressData) {
    return (dispatch) => {
        dispatch({
            type: GUEST_INVOICE_ADDRESS,
            addressData
        })
    }
}

export function getAddressType(totalWeight) {
    return (dispatch) => {
        ApiConnector.getAddressType(totalWeight)
            .then(response => {
                dispatch({
                    type: SET_ADDRESS_TYPE,
                    addressType: response.data
                })
            })
    }
}


export function getTemporaryCustomer() {
    return (dispatch) => {
        ApiConnector.getEmail()
            .then(response => {
                dispatch({
                    type: GET_TEMPORARY_CUSTOMER,
                    temporaryPassword: response.data.temporaryPassword
                })
            })
    }
}

export function getCountries() {
    return (dispatch) => {
        ApiConnector.getCountries()
            .then(response => {
                dispatch({
                    type: GET_COUNTRIES,
                    countries: response.data
                })
            })
        ApiConnector.getInstallationCountry()
            .then(response => {
                dispatch({
                    type: GET_INSTALLATION_COUNTRY,
                    country: response.data
                })
            })
    }
}

export function getSearchValue(value) {
    return {
        type: GET_SEARCH_VALUE,
        value
    }
}

export function getCampaigns(limit, offset) {
    return (dispatch) => {
        ApiConnector.getCampaigns(limit, offset)
            .then(response => {
                dispatch({
                    type: GET_CAMPAIGNS,
                    campaigns: response.data
                })
                response.data.forEach (campaign => {
                    campaign.articleContainers.forEach(articleContainer => {
                        dispatch({
                            type: ADD_PRODUCT,
                            product: articleContainer
                        })
                        ApiConnector.getArticles(articleContainer)
                            .then(response => {
                                const customerPayer = store.getState().customer.payer;
                                const articles = response.data.map(article => {
                                    if(article.payer === 'ALWAYS_CUSTOMER') {
                                        article.payer = 'CUSTOMER'
                                    } else {
                                        article.payer = customerPayer
                                    }
                                    if(article.payer === 'TENANT') {
                                        article.price = 0
                                    }
                                    return article
                                });

                                dispatch({
                                    type: ADD_ARTICLES,
                                    containerId: articleContainer.id,
                                    articles
                                })
                            })
                    })
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function loadMoreOrders() {
    return {
        type: LOAD_MORE_ORDERS
    }
}

export function acceptNewsletter(accept) {
    return (dispatch) => {
        ApiConnector.changeNewsletterSetting(accept)
            .then( () => {
                dispatch( getCustomerData() )
            })
    }
}

export function requiredInvoiceAddress() {
    return (dispatch) => {
        ApiConnector.requiredInvoiceAddress()
            .then( (response => {
                dispatch({
                    type: REQUIRED_INVOICE_ADDRESS,
                    required: response.data
                })
            }))
    }
}

export function getArticleCategories() {
    return (dispatch) => {
        return ApiConnector.getArticleCategories()
            .then(response => {
                dispatch({
                    type: GET_ARTICLE_CATEGORIES,
                    articleCategories: response.data
                });
            })
    }
}

export function getAdministrativeFee(){
    return (dispatch) => {
        ApiConnector.getAdministrativeFee()
            .then(response => {
                let article = response.data;
                if(article.id) {
                    dispatch({
                        type: SET_ADMINISTRATIVE_FEE,
                        article: article
                    });
                } else {
                    dispatch({
                        type: SET_ADMINISTRATIVE_FEE,
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export function removeAdministrativeFee(){
    return {
        type: REMOVE_ADMINISTRATIVE_FEE,
    }
}

export function checkNumberOfForms(cart) {
    if (!cart) {
        return {
            type: SET_NUMBER_OF_FORMS,
            payload: 0
        }
    }
    const number = Object.values(cart).reduce((accumulator, item) => {
        return item.article.numberOfForms > 0 ? accumulator + item.article.numberOfForms : accumulator
    }, 0);

    return {
        type: SET_NUMBER_OF_FORMS,
        payload: number
    }
}
