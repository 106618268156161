import {GET_COUNTRIES, GET_INSTALLATION_COUNTRY, LOGOUT, SET_STREETS} from "../actions/actions";


const initialState = {
    validPostcodes: [],
    streets: [],
    countries: [],
    installationCountry: 'Sweden'
}

export default function(state = initialState, action ) {
    switch(action.type) {
        case GET_COUNTRIES:
            return {
                ...state,
                countries: action.countries.map(country => country.text)
            }
        case GET_INSTALLATION_COUNTRY:
            return {
                ...state,
                installationCountry: action.country
            }
        case SET_STREETS:
            return {
                ...state,
                streets: action.streets
            }
        case LOGOUT:
            return initialState
        default:
            return state
    }
}