import React from 'react';
import {checkBox} from "../../components/form/formFields";
import {Field} from "redux-form";
import i18next from 'i18next';
import {withRouter} from "react-router-dom";

const Newsletter = (props) => {
    return (
        <div className="confirm">
            <div className="confirm-content">
                <Field
                    name='allowNewsletter'
                    label={i18next.t('checkout_page.newsletter_subscribe')}
                    defaultChecked={props.allowNewsletter}
                    component={checkBox}
                />
            </div>
            <div className="confirm-content">
                <button type="submit" className="confirm-content__button">{i18next.t('checkout_page.finish_purchase')}</button>
            </div>
            <div className="confirm-content">
                <p className="confirm-content__small-text">{i18next.t('checkout_page.terms_agreement')}</p>
            </div>
        </div>
    );
};
export default withRouter(Newsletter);