import React from 'react'
import {textField} from "../../components/form/formFields";
import {Field, reduxForm} from "redux-form";
import {Alert} from "antd";
import {connect} from "react-redux";
import {changeEmail} from "../../actions/actions";
import i18next from 'i18next';

const validate = (values) => {
    const errors = {}
    if(!values.newEmail) {
        errors.newEmail = i18next.t('errors.required')
    }
    if(values.newEmail === values.currentEmail) {
        errors.newEmail = i18next.t('errors.identical')
    }
    return errors
}

let ChangeEmail = (props) => {

    function changeEmail(values) {
        return props.changeEmail(values.newEmail)
            .then( () => {
                props.reset()
            })
    }
    const {handleSubmit, error, temporaryCustomer} = props;
    return (
        <React.Fragment>
            <h3 className="field-wrapper__title">{i18next.t('account.settings.email')}</h3>
            <p className="caption">{i18next.t('account.settings.email_description')}</p>
            <form onSubmit={handleSubmit(changeEmail)}>
                { error && <Alert
                    message="Error"
                    description={error}
                    type="error"
                    style={{marginBottom: '10px'}}
                    showIcon
                /> }
                <Field
                    name='currentEmail'
                    label={i18next.t('account.settings.email')}
                    placeholder={i18next.t('account.settings.email')}
                    component={textField}
                    maxLength="50"
                    type="email"
                    readOnly
                    style={{display: temporaryCustomer ? "none" : "block"}}
                />
                <Field
                    name='newEmail'
                    label={i18next.t('account.settings.new_email')}
                    placeholder={i18next.t('account.settings.new_email')}
                    component={textField}
                    maxLength="50"
                    type="email"
                />
                <button className="information-address__button link-button link-button--neg" type='submit'>
                    <span property="name" className="link-button__name link-button__name--neg">{i18next.t('account.settings.save')}</span>
                </button>
                {/*<Link to="#" className="information-address__button link-button link-button--neg" onClick={handleSubmit(changeEmail)}>*/}
                    {/*<span property="name" className="link-button__name link-button__name--neg">{i18next.t('account.settings.save')}</span>*/}
                {/*</Link>*/}
            </form>
        </React.Fragment>
    )
}
function mapStateToProps(state) {
    return {
        initialValues: {
            currentEmail: state.customer.email
        }
    }
}
ChangeEmail = reduxForm({
    form: 'email',
    validate,
    enableReinitialize: true
})(ChangeEmail);
export default connect(mapStateToProps, {changeEmail})(ChangeEmail)
