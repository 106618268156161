import "babel-polyfill";
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import './style/main.scss';
import App from './containers/App';
import registerServiceWorker, {unregister} from './registerServiceWorker';
import Root from "./Root";

ReactDOM.render(
    <Root>
        <App />
    </Root>,
    document.getElementById('root'));
// registerServiceWorker();
unregister();
