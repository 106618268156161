import React, {Component} from 'react';
import {Field, formValueSelector, reduxForm} from "redux-form";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {checkBox, selectBox, textField, numberField} from "../../components/form/formFields";
import { showInvoiceAddressModal, changeAddress, addGuestInvoiceAddress, getCountries } from '../../actions/actions';
import store from '../../store/store';
import i18next from 'i18next';
import {Card, Alert, Button} from "antd";

const validate = (values, props) => {
    const errors = {}
    if(!values.invoiceRecipient && props.requiredInvoiceAddress) {
        errors.invoiceRecipient = i18next.t('errors.required')
    }
    if(!values.invoiceStreetName) {
        errors.invoiceStreetName = i18next.t('errors.required')
    }
    const state = store.getState()
    if(!values.invoiceZipCode) {
        errors.invoiceZipCode = i18next.t('errors.required')
    } else if(values.invoiceCountry && values.invoiceCountry.toLowerCase() === state.address.installationCountry.toLowerCase() && ( values.invoiceZipCode.length !== 5 || !/^\d+$/.test(values.invoiceZipCode) ) ) {
        errors.invoiceZipCode = 'Invalid'
    }
    if(!values.invoiceCity) {
        errors.invoiceCity = i18next.t('errors.required')
    }
    if(!values.invoiceCountry) {
        errors.invoiceCountry = i18next.t('errors.required')
    }
    return errors
};

class invoiceAddressForm extends Component {
    constructor(props) {
        super(props);
        this.changeInvoiceAddress = this.changeInvoiceAddress.bind(this);
        this.renderPostCode = this.renderPostCode.bind(this);
        this.cancelModal = this.cancelModal.bind(this);
    }

    cancelModal() {
        this.props.reset();
        this.props.showInvoiceAddressModal();
    }

    async changeInvoiceAddress(values) {
        const invoiceAddress = {
            recipient: values.invoiceRecipient,
            streetName: values.invoiceStreetName,
            streetNumber: values.invoiceStreetNumber,
            streetLetter: values.invoiceStreetLetter,
            zipCode: values.invoiceZipCode,
            city: values.invoiceCity,
            country: values.invoiceCountry,
            invoiceReference: values.invoiceReference
        };
      
        invoiceAddress.gln = values.gln;
        invoiceAddress.electronicInvoiceSystemAddress = values.electronicInvoiceSystemAddress;
        invoiceAddress.electronicOperator = values.electronicOperator;

        if(!invoiceAddress.streetNumber) {
            invoiceAddress.streetNumber = null
        }
        if(values.includePDFInvoice) {
            invoiceAddress.emailInvoiceAddress = values.emailInvoiceAddress
        } else {
            invoiceAddress.emailInvoiceAddress = ""
        }
        const changeAddress = () => {
            // If a guest user - save delivery address to the app state
            if (this.props.guest) {
                return this.props.addGuestInvoiceAddress(invoiceAddress);
            }
            // If a registered user - send delivery address to the backend
            return this.props.changeAddress('invoiceAddress', invoiceAddress);
        };
        await changeAddress();
        //this.cancelModal();
    }

    renderPostCode() {
        if(this.props.country) {
            return <Field
                name='invoiceZipCode'
                label={`${i18next.t('account.information.postcode')}*`}
                placeholder={`${i18next.t('account.information.postcode')}*`}
                component={textField}
                maxLength="50"
                type="text"
                extraClasses='field-content--25'
                autocomplete="off"
            />
        }
    }

    renderCityAndStreet() {
        if(this.props.city || this.props.country ) {
            return <React.Fragment>
                <Field
                    name='invoiceCity'
                    label={`${i18next.t('account.information.city')}*`}
                    placeholder={`${i18next.t('account.information.city')}*`}
                    component={textField}
                    maxLength="50"
                    type="text"
                    extraClasses='field-content--50'
                />
                <Field
                    name='invoiceStreetName'
                    label={`${i18next.t('account.information.street_name')}*`}
                    placeholder={`${i18next.t('account.information.street_name')}*`}
                    component={textField}
                    maxLength="50"
                    type="text"
                    extraClasses='field-content--50'
                />
                <Field
                    name='invoiceStreetNumber'
                    label={i18next.t('account.information.street_number')}
                    placeholder={i18next.t('account.information.street_number')}
                    component={numberField}
                    maxLength="50"
                    extraClasses='field-content--25'
                />

                <Field
                    name='invoiceStreetLetter'
                    label={i18next.t('account.information.street_number_letter')}
                    placeholder={i18next.t('account.information.street_number_letter')}
                    component={textField}
                    maxLength="50"
                    type="text"
                    extraClasses='field-content--25'
                />
            </React.Fragment>
        }
    }

    render() {
        const {handleSubmit, error} = this.props;
        return (
            <Card title={i18next.t('checkout_page.invoice_address')}>
                <form onSubmit={handleSubmit(this.changeInvoiceAddress)} id="invoiceAddressModal">
                    <div className="field-wrapper">
                        <h3 className="field-wrapper__title">{i18next.t('account.information.recipient')}{this.props.requiredInvoiceAddress ? '*':''}</h3>
                        <Field
                            name='invoiceRecipient'
                            label={`${i18next.t('account.information.recipient')}${this.props.requiredInvoiceAddress ? '*':''}`}
                            placeholder={`${i18next.t('account.information.recipient')}${this.props.requiredInvoiceAddress ? '*':''}`}
                            component={textField}
                            maxLength="50"
                            type="text"
                            extraClasses='field-content--100'
                        />
                    </div>
                    <div className="field-wrapper">
                        <h3 className="field-wrapper__title">{i18next.t('account.information.address')}*</h3>
                        <Field
                            name='invoiceCountry'
                            label={`${i18next.t('account.information.country')}*`}
                            placeholder={`${i18next.t('account.information.country')}*`}
                            component={selectBox}
                            options={this.props.countries}
                            extraClasses='field-content--25'
                            readOnly = {!this.props.publicTenant}
                        />
                        {this.renderPostCode()}
                        {this.renderCityAndStreet()}
                    </div>

                    <div className="field-wrapper field-wrapper--margin-top">
                        <h3 className="field-wrapper__title">{i18next.t('account.information.invoice_reference')}</h3>
                        <Field
                            name='invoiceReference'
                            label={i18next.t('account.information.invoice_reference')}
                            placeholder={i18next.t('account.information.invoice_reference')}
                            component={textField}
                            maxLength="50"
                            extraClasses='field-content--100'
                            type="text"
                        />
                    </div>

                    {
                        this.props.tenantId == 5 ? null : (
                            <div className="field-wrapper field-wrapper--margin-top">
                                <h3 className="field-wrapper__title">{i18next.t('checkout_page.global_loc_number')}</h3>
                                <Field
                                    name='gln'
                                    label={i18next.t('checkout_page.global_loc_number')}
                                    placeholder={i18next.t('checkout_page.global_loc_number')}
                                    component={textField}
                                    maxLength="50"
                                    type="text"
                                    extraClasses='field-content--100'
                                />
                            </div>
                        )
                    }


                    {
                        this.props.tenantId == 5 ? null : (
                            <div className="field-wrapper field-wrapper--margin-top">
                                <Field
                                name='includePDFInvoice'
                                label={i18next.t('account.information.confirm_pdf')}
                                component={checkBox}
                                />
                                <Field
                                name="emailInvoiceAddress"
                                label={i18next.t('account.information.email_invoice_address')}
                                placeholder={i18next.t('account.information.email_invoice_address')}
                                component={textField}
                                maxLength="50"
                                extraClasses='field-content--100'
                                type="email"
                                readOnly = {!this.props.modifyInvoiceEmail}
                                />
                            </div>
                        )
                    }



                    { error && <Alert
                        message="Error"
                        description={error}
                        type="error"
                        style={{marginTop: '10px'}}
                        showIcon
                    /> }
                    <Button key="back" onClick={this.cancelModal} style={{marginRight: 10}}>{i18next.t('checkout_page.cancel')}</Button>
                    <Button key="submit" type="primary" onClick={handleSubmit(this.changeInvoiceAddress)}>
                        {i18next.t('checkout_page.save')}
                    </Button>
                </form>
            </Card>
        )
    }
}

const selector = formValueSelector('invoiceAddress')
function mapStateToProps(state) {

    let initialValues = {};
    if(state.customer.invoiceAddress && state.customer.invoiceAddress.recipient) {
        const {invoiceAddress} = state.customer;

        initialValues = {...initialValues,
            invoiceRecipient: invoiceAddress.recipient ? invoiceAddress.recipient : '',
            invoiceStreetName: invoiceAddress.streetName ? invoiceAddress.streetName : '',
            invoiceStreetNumber: invoiceAddress.streetNumber ? invoiceAddress.streetNumber : '',
            invoiceStreetLetter: invoiceAddress.streetLetter ? invoiceAddress.streetLetter : '',
            invoiceZipCode: invoiceAddress.zipCode ? invoiceAddress.zipCode : '',
            invoiceCity: invoiceAddress.city ? invoiceAddress.city : '',
            invoiceCountry: invoiceAddress.country ? invoiceAddress.country : '',
            invoiceReference: invoiceAddress.invoiceReference ? invoiceAddress.invoiceReference : '',
            emailInvoiceAddress: invoiceAddress.emailInvoiceAddress ? invoiceAddress.emailInvoiceAddress : '',
            gln: invoiceAddress.gln ? invoiceAddress.gln: '',

        }
    } else {
        initialValues = {
            ...initialValues,
            invoiceCountry: state.address.installationCountry
        }
    }
    return {
        initialValues,
        country: selector(state, 'invoiceCountry'),
        postCode: selector(state, 'invoiceZipCode'),
        streetName: selector(state, 'invoiceStreetName'),
        countries: state.address.countries,
        streets: state.address.streets,
        city: selector(state, 'invoiceCity'),
        modifyInvoiceEmail: selector(state, 'includePDFInvoice'),
        showModal: state.layout.showInvoiceAddressModal,
        guest: state.auth.guest,
        publicTenant: state.auth.publicTenant,
        isProcessing: state.auth.isProcessing,
        tenantId: state.auth.tenantId,
        requiredInvoiceAddress: state.cart.requiredInvoiceAddress
    }
}

invoiceAddressForm = reduxForm({
    form: 'invoiceAddress',
    validate,
    enableReinitialize: true
})(invoiceAddressForm);

export default withRouter( connect(mapStateToProps, {showInvoiceAddressModal, getCountries, changeAddress, addGuestInvoiceAddress})(invoiceAddressForm) );


