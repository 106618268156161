import {
    CLEAR_CART,
     SET_NUMBER_OF_FORMS
} from "../actions/actions";

const initialState = {
    numberOfForms: 0
}

export default function(state = initialState, action) {
    switch(action.type) {
        case CLEAR_CART:
            return initialState
        case SET_NUMBER_OF_FORMS:
            return {
                numberOfForms: action.payload
            }
        default:
            return state
    }
}

