import React from 'react';
import {withRouter} from 'react-router-dom';
import Login from './LoginPage/Login';
import CreateAccount from './LoginPage/CreateAccount';
import BreadCrumbs from '../components/navigation/BreadCrumbs';
import i18next from 'i18next';
import {connect} from "react-redux";

const LoginPage = (props) => {

    function renderCreateAccount() {
        if (props.publicTenant) {
            return <CreateAccount tenantId={props.tenantId} />
        }
    }

    return (
        <div className="wrapper">

        <BreadCrumbs pagename={i18next.t('loginpage.page_name')} />

        <main className="main typeset">
            <div className="c">
                <div className="main-content">
                    <div className="placeholder">
                        <div className="main-content__primary main-content__primary--full">
                            <div className="my-account-login">

                                <Login />
                                {renderCreateAccount()}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        </div>
    );
}
function mapStateToProps(state) {
    return {
        publicTenant: state.auth.publicTenant,
        tenantId: state.auth.tenantId
    }
}
export default withRouter( connect(mapStateToProps, null) (LoginPage) );
