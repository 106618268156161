import React, { Component } from 'react';
import {Link, withRouter} from 'react-router-dom';
import {LinkButtonDelete, LinkButtonEdit} from '../../components/links/LinkButton';
import {IMAGE_PATH, SERVER_URL} from "../../config/config";
import {removeFromCart, addToCart, modifyAmountInCart} from "../../actions/actions";
import {connect} from "react-redux";
import { Popconfirm, message, InputNumber } from 'antd';
import i18next from 'i18next';
import ReactHtmlParser from 'react-html-parser';


class CartItem extends Component  {
    constructor(props) {
        super(props);
        this.state = {
            amount: props.cartItem.amount,
            isEditing: false,
            articleId: props.cartItem.article.id
        }
        this.changeAmount = this.changeAmount.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.saveCart = this.saveCart.bind(this);
    }

    confirm(articleId) {
        const that = this;
        that.props.removeFromCart(articleId);
        message.success(i18next.t('cart_item.delete_confirm'));
    }

    changeAmount(value) {
        this.setState({amount: value});
    }
    toggleEdit() {
        this.setState({isEditing: !this.state.isEditing})
    }
    changeArticle(article) {
        this.setState({
            articleId: article.id,
            price: article.price
        })
    }

    saveCart() {
        if(this.props.cartItem.article.id !== this.state.articleId ) {
            this.props.removeFromCart(this.props.cartItem.article.id);
            const article = this.props.cartItem.product.articles.find(art => art.id === this.state.articleId)
            this.props.addToCart(this.props.cartItem.product, article, this.state.amount, false)
            this.props.modifyAmountInCart(this.state.articleId, this.state.amount)

        } else if (this.props.cartItem.amount !== this.state.amount) {
            this.props.modifyAmountInCart(this.state.articleId, this.state.amount)
        }
        this.setState({isEditing: false})
    }

    render() {
        const {cartItem} = this.props;
        const notAlternativePrice = isNaN(parseFloat(cartItem.article.price))

        // Image path
        let imagePath = ''
        if(cartItem.product.images.length > 0) {
            imagePath = `${SERVER_URL}${IMAGE_PATH}${cartItem.product.images[0].fileName}`
        }

        // QuantityList
        const that = this
        const quantityList = cartItem.product.articles.map (article => {
            let classes = "product-choices-list__item"
            if(article.id === that.state.articleId) {
                classes = "product-choices-list__item product-choices-list__item--active"
            }
            return <li className={classes} onClick={() => that.changeArticle(article)}>
                <span>{article.quantity}</span>
            </li>
        })

        const regexTest = new RegExp("\\[" + this.props.language + "\\].*?\"([\\S\\s]*?)\"")

        const matchName = regexTest.exec(cartItem.product.name)
        const matchDescription = regexTest.exec(cartItem.product.description)

        const translatedName = (matchName && matchName.length > 1) ? matchName[1] : cartItem.product.name
        const translatedDescription = ReactHtmlParser((matchDescription && matchDescription.length > 1) ? matchDescription[1] : cartItem.product.description);

        const priceBrutto =  cartItem.article.price * `1.${this.props.vat}`;

        if (this.state.isEditing) {
            return (
                <div className="cart-item">
                    <div className="cart-item-image">
                        <img className="cart-item-image__img" src={imagePath} alt={translatedName} />
                    </div>

                    <div className="cart-item-content">
                        <h2 className="cart-item-content-title" onClick={() => this.props.history.push(`/article/${cartItem.product.id}`)}>
                            <Link to='#' className="cart-item-content-title__link">
                                {translatedName}
                            </Link>
                        </h2>

                        <div className="cart-item-content-row">
                            <div className="cart-item-content-row__col-one">
                                <p className="cart-item-info">
                                    <span className="cart-item-info__key--dark">{i18next.t('cart_item.select_package')}</span>
                                </p>
                            </div>

                            <div className="cart-item-content-row__col-two">
                                <ul className="product-choices-list">
                                    {quantityList}
                                </ul>

                            </div>

                        </div>

                        <div className="cart-item-content-row">
                            <div className="cart-item-content-row__col-one">
                                <p className="cart-item-info">
                                    <span className="cart-item-info__key--dark">{i18next.t('cart_item.select_amount')}</span>
                                </p>
                            </div>


                            <div className="cart-item-content-row__col-two">
                                <InputNumber className="cart-item__amount" id="#" value={this.state.amount} onChange={this.changeAmount} min={1} />
                            </div>

                        </div>

                        <div className="cart-item-content-row">
                            <div className="cart-item-content-row__col-one">
                            </div>

                            <div className="cart-item-content-row__col-two">
                                <button
                                    onClick={this.saveCart}
                                    type='submit'
                                    className="link-button link-button--alert link-button--margin-right">
                                    <span property="name" className="link-button__name">{i18next.t('cart_item.save')}</span>
                                </button>
                                <button
                                    onClick={this.toggleEdit}
                                    className="link-button link-button--no-border link-button--underline">
                                    {i18next.t('cart_item.cancel')}
                                </button>

                            </div>

                        </div>

                    </div>

                    <div className="cart-item-price">
                        <p className="cart-item-price__text">
                            {this.props.privateCustomerType ? priceBrutto : cartItem.article.price} {notAlternativePrice ? null : this.props.currency}
                        </p>
                    </div>

                </div>
            )
        }

        return (
            <div className="cart-item">
                <div className="cart-item-image" style={{fontSize: '12px'}}>
                    <img className="cart-item-image__img" src={imagePath} alt={translatedName} />
                </div>

                <div className="cart-item-content">
                    <h2 className="cart-item-content-title"
                        onClick={() => this.props.history.push(`/article/${cartItem.product.id}`)}>
                        <Link to='#' className="cart-item-content-title__link">
                            {translatedName}
                        </Link>
                    </h2>

                    <p className="cart-item-info">
                        <span className="cart-item-info__key">{i18next.t('cart_item.about')}</span>
                        <span className="cart-item-info__value">{translatedDescription}</span>
                    </p>
                    <p className="cart-item-info">
                        <span className="cart-item-info__key">Art</span>
                        <span className="cart-item-info__value">{cartItem.article.articleNumber}</span>
                    </p>
                    <p className="cart-item-info">
                        <span className="cart-item-info__key">{i18next.t('cart_item.pkg')}</span>
                        <span className="cart-item-info__value">{cartItem.article.quantity}</span>
                    </p>
                    <p className="cart-item-info">
                        <span className="cart-item-info__key">{i18next.t('cart_item.amount')}</span>
                        <span className="cart-item-info__value">{cartItem.amount}</span>
                    </p>

                    <div className="cart-item-actions">

                        <Popconfirm title={i18next.t('cart_item.delete_msg')} onConfirm={() => this.confirm(cartItem.article.id)} okText={i18next.t('notification.yes')} cancelText={i18next.t('notification.no')}>
                            <LinkButtonDelete path='#' title={i18next.t('cart_item.remove')} />
                        </Popconfirm>

                        <LinkButtonEdit path='#' title={i18next.t('cart_item.edit')} onClick={this.toggleEdit} />

                    </div>

                </div>

                <div className="cart-item-price">
                    <p className="cart-item-price__text">
                        {this.props.privateCustomerType ? priceBrutto : cartItem.article.price} {notAlternativePrice ? null : this.props.currency}
                    </p>
                </div>

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        language: state.language.language.language,
        privateCustomerType: state.customer.privateCustomerType,
        vat: state.auth.vat
    }
}
export default withRouter( connect(mapStateToProps, {removeFromCart, addToCart, modifyAmountInCart})(CartItem) );
