import React, {Component} from 'react';
import i18next from 'i18next';
import {Link} from 'react-router-dom';
import {slide as Menu} from 'react-burger-menu';
import {connect} from "react-redux";
import {showMobileMenu, changeLanguage, logout, getArticleCategories} from "../actions/actions";
import _ from "lodash";
import {Collapse} from "antd";

/** Component representing the mobile navigation */
class MobileMenu extends Component  {
    constructor(props) {
        super(props);
        this.state = {
            active: true,
            defaultGroup: ''
        }
        this.renderLogout = this.renderLogout.bind(this);
        this.setLanguage = this.setLanguage.bind(this);
        this.renderShops = this.renderShops.bind(this);
        this.languageExists = this.languageExists.bind(this);
    }

    /** Set Swedish language by default */
    componentWillMount(){
        const browserLanguage = navigator.language
        let appLanguage = 'sv'
        let language = browserLanguage
        if(browserLanguage.includes("-")) {
            language = browserLanguage.split("-")[0]
        }

        if(this.languageExists(language)) {
            appLanguage = language
        }
        this.setLanguage(appLanguage);
    }

    componentDidUpdate(prevProps, props) {
        if( (this.props.publicTenant) || (!this.props.publicTenant && this.props.loggedIn) ) {

            if(prevProps.isProcessing === true && this.props.isProcessing === false) {
                this.props.getArticleCategories();
            }
        }
    }

    /** Set the default article group:
     * get the ID of the first group of the first category
     */
    componentWillReceiveProps(nextProps){
        let defaultGroup = '';
        if(nextProps.articleCategories) {
            let firstCategory = _.head(nextProps.articleCategories);
            defaultGroup = _.get(firstCategory, 'articleGroups[0].id');
        }
        this.setState({defaultGroup})
    }

    /** Switch the languages
     * @params {string} language - The current language
     */
    setLanguage(language) {
        i18next.init({
            lng: language,
            resources: require(`../lang/${language}.json`)
        });
        this.props.changeLanguage(i18next);
        this.setState({
            active: !this.state.active
        })
    }
    languageExists(language) {
        try {
            require(`../lang/${language}.json`)
            return true
        } catch (err) {
            return false
        }
    }

    renderLogout() {
        /** Render login/logout link for Public shop */
        if(this.props.publicTenant) {
            if(this.props.loggedIn) {
                return (
                    <Link
                        to="/"
                        onClick={() => { this.props.logout(); this.props.showMobileMenu() }} >
                        {i18next.t('header.logout')}
                    </Link>
                )
            } else {
                return (
                    <Link
                        to="/login"
                        onClick={() => { this.props.history.push('/login'); this.props.showMobileMenu() }} >
                        {i18next.t('header.login')}
                    </Link>
                )
            }
        }
        /** Render login/logout link for Loomis shop */
        else if(!this.props.publicTenant) {
            if(this.props.loggedIn) {
                return (
                    <Link
                        to="/"
                        onClick={() => { this.props.logout(); this.props.showMobileMenu() }} >
                        {i18next.t('header.logout')}
                    </Link>
                )
            } else {
                return (
                    <Link
                        to="/"
                        onClick={() => { this.props.history.push('/'); this.props.showMobileMenu() }} >
                        {i18next.t('header.login')}
                    </Link>
                )
            }
        }
    }

    renderShops() {
        /** Render navigation menu links for loomis shop */
            return (
                <div>
                    <Link
                        onClick={this.props.showMobileMenu}
                        to="/"
                        className='mobile-menu__link'>
                        {i18next.t('breadcrumbs.home')}
                    </Link>
                    <Link
                        onClick={this.props.showMobileMenu}
                        to={`/shop/${this.state.defaultGroup}`}
                        className='mobile-menu__link'>
                        {i18next.t(`header.shop_name-${this.props.tenantId}`)}
                    </Link>
                </div>
            )
        }

    /** Render article categories and groups */
    renderArticleCategories() {
        const regexTest = new RegExp("\\[" + this.props.language + "\\].*?\"([\\S\\s]*?)\"")
        const { Panel } = Collapse;
        let articleCategories = this.props.articleCategories;
        if (articleCategories !== {}) {

            const customPanelStyle = {
                background: '#f5f5f5',
                border: 0,
                overflow: 'hidden',
            };

            return (
                <Collapse accordion bordered={false} defaultActiveKey={['1']}>
                    {
                        /** Return article categories */
                        _.map(articleCategories, category => {
                            /** Translate the name for article categories */
                            const matchName = regexTest.exec(category.name);
                            const translatedName = (matchName && matchName.length > 1) ? matchName[1] : category.name;
                            return (
                                <Panel header={translatedName} key={category.id} style={customPanelStyle}>
                                    {
                                        _.map(category.articleGroups, group => {
                                            const matchName = regexTest.exec(group.name);

                                            /* Translate the name for groups */
                                            const translatedName = (matchName && matchName.length > 1) ? matchName[1] : group.name;

                                            /* Return groups for every category */
                                            return (
                                                <div>
                                                    <Link
                                                        onClick={this.props.showMobileMenu}
                                                        to={`/shop/${group.id}`}
                                                        className='mobile-menu__link'>
                                                        {translatedName}
                                                    </Link>
                                                </div>
                                            )
                                        })
                                    }
                                </Panel>
                            )
                        })
                    }
                </Collapse>
            )
        }
        return null
    }

    render() {
        return (
            <Menu
                right
                width={'80%'}
                className={ "mobile-menu" }
                isOpen={this.props.mobileMenu}
                disableOverlayClick
            >

                <div className='mobile-menu__top'>
                    <span onClick={this.props.showMobileMenu} className='mobile-menu__close'>
                        <svg className="sidecart-close__svg">
                            <use xlinkHref="#close"/>
                        </svg>
                    </span>

                    {
                        this.props.tenantId !== 5 && this.props.tenantId !== 9 ?
                            (
                                <div className="language-list mobile-menu__top-item">
                                    <Link
                                        onClick={() => this.setLanguage('sv')}
                                        className={this.state.active === 'sv' ? 'active' : ''}
                                        to="#" >SV</Link>
                                    <Link
                                        onClick={() => this.setLanguage('en')}
                                        className={this.state.active === 'en' ? 'active' : ''}
                                        to="#" >EN</Link>
                                    <Link
                                        onClick={() => this.setLanguage('no')}
                                        className={this.state.active === 'no' ? 'active' : ''}
                                        to="#" >NO</Link>
                                    <Link
                                        onClick={() => this.setLanguage('es')}
                                        className={this.state.active === 'es' ? 'active' : ''}
                                        to="#" >ES</Link>
                                    <Link
                                        onClick={() => this.setLanguage('fi')}
                                        className={this.state.active === 'fi' ? 'active' : ''}
                                        to="#" >FI</Link>
                                </div>

                            ) : null
                    }
                </div>
                { this.props.businessPrivateToggle  &&
                    this.props.changeCustomerType
                }
                {this.renderShops()}

                <div style={{borderRadius: '8px', overflow: 'hidden', marginTop: '16px'}}>
                    {this.renderArticleCategories()}
                </div>

                <div className='mobile-menu__bottom'>
                    { this.renderLogout() }
                </div>

            </Menu>
        )}
}
function mapStateToProps(state) {
    return {
        loggedIn: state.auth.loggedIn,
        mobileMenu: state.layout.mobileMenu,
        publicTenant: state.auth.publicTenant,
        tenantId: state.auth.tenantId,
        articleCategories: state.customer.articleCategories,
        language: state.language.language.language,
        isProcessing: state.auth.isProcessing,
        businessPrivateToggle: state.auth.businessPrivateToggle
    }
}
export default connect(mapStateToProps, {showMobileMenu, changeLanguage, logout, getArticleCategories}) (MobileMenu);
