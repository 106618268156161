import {CHANGE_LANGUAGE} from "../actions/actions";

const initialState = {
    language: ''
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CHANGE_LANGUAGE:
            return { ...state, language: action.payload.language }
        default :
            return state;
    }
}