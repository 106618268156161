import React from 'react';
import { Modal } from 'antd';
import {Field, reduxForm} from "redux-form";
import {textField} from "./form/formFields";
import {connect} from "react-redux";
import i18next from 'i18next';
import {withRouter} from 'react-router-dom';
import { showForgottenPassModal, hideForgottenPassModal, getUsername } from "../actions/actions";


let ForgottenPassModal = (props) => {

    function sendUsername(values) {
        props.reset();
        return props.getUsername(values.forgottenUser)
    }

        return (
            <Modal
                title={i18next.t('notification.forgot_pass_title')}
                visible={props.showModal}
                footer={false}
                onCancel={ () => props.hideForgottenPassModal() }
            >
                <p>{i18next.t('notification.forgot_pass_message')}</p>
                <form onSubmit={props.handleSubmit(sendUsername)}>
                    <Field
                        name='forgottenUser'
                        placeholder={i18next.t('loginpage.username')}
                        label={i18next.t('loginpage.username')}
                        component={textField}
                        type='text'
                    />
                    <button type="submit" className="field-content__button" style={{marginTop: '10px', backgroundColor: props.tenantId == 8 ? '#0077AD' : '#C8002A'}}>{i18next.t('notification.forgot_pass_button')}</button>
                </form>
            </Modal>
        )
}

function mapStateToProps(state) {
    return ({
        showModal: state.auth.showModal,
        tenantId: state.auth.tenantId
    })
}

ForgottenPassModal = withRouter(connect (mapStateToProps, {showForgottenPassModal, hideForgottenPassModal, getUsername})(ForgottenPassModal));
export default reduxForm({
    form: 'forgottenPass'
})(ForgottenPassModal);