import React from 'react';
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import i18next from 'i18next';
import {textField} from "../../components/form/formFields";
import {Field, reduxForm} from "redux-form";
import {login, showForgottenPassModal} from "../../actions/actions";
import ForgottenPassModal from '../../components/ForgottenPassModal';
import {Alert} from "antd";

const validate = (values) => {
    const errors = {};
    if(!values.username) {
        errors.username = i18next.t('errors.required')
    }
    if(!values.password) {
        errors.password = i18next.t('errors.required')
    }
    return errors
};

let Login = (props) => {

    function login(credentials) {
        return props.login(credentials.username, credentials.password)
    }

    function goToCheckout() {
        props.history.push('/checkout');
        window.scrollTo(0, 0);
    }

    const {handleSubmit, submitting, error} = props;
    if(props.loggedIn) {
        return (
            <div className="order-actions">
                <h2 className="order-actions__title">{i18next.t('cart.go_to_checkout')}</h2>
                <div className="order-actions-section">
                    <div className="order-actions-section__form">
                        <div className="field-content">
                            <button type="submit" className="field-content__button" onClick={goToCheckout}>
                                {i18next.t('cart.go_to_checkout')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
                <div className="order-actions">
                    <h2 className="order-actions__title">{i18next.t('cart.login')}</h2>
                    <div className="order-actions-wrapper">
                    <div className="order-actions-section">
                        <form onSubmit={handleSubmit(login)}>
                            <div className="order-actions-section">
                                <h3 className="order-actions-section__title">{i18next.t('cart.exist_customer')}</h3>
                                <div className="order-actions-section__form">
                                    { error && <Alert
                                        message="Error"
                                        description={error}
                                        type="error"
                                        style={{marginBottom: '10px'}}
                                    /> }
                                    <Field
                                        name='username'
                                        placeholder={i18next.t('cart.username')}
                                        label={i18next.t('cart.username')}
                                        component={textField}
                                        type='text'
                                    />
                                    <Field
                                        name='password'
                                        placeholder={i18next.t('cart.password')}
                                        label={i18next.t('cart.password')}
                                        component={textField}
                                        type='password'
                                    />
                                    <div className="field-content">
                                        <button type="submit" className="field-content__button"  disabled={submitting}>
                                            {i18next.t('cart.login')}
                                        </button>
                                        <Link
                                            onClick={() => props.showForgottenPassModal()}
                                            to='#'
                                            className='link'>{i18next.t('cart.forgot_password')}
                                        </Link>
                                        <ForgottenPassModal />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="order-actions-section">
                        <h3 className="order-actions-section__title">{i18next.t('cart.new_customer')}</h3>
                        <p className="order-actions-section__text">
                            {i18next.t('cart.create_account_label')}
                        </p>
                        <button className="field-content__button" onClick={() => props.history.push('/account/new')}>
                            {i18next.t('cart.create_account')}
                        </button>
                        {
                            props.tenantId !== 5 ? (<button className="field-content__button" onClick={() => props.history.push('/checkout')} style={{marginTop: '20px'}}>
                                {i18next.t('cart.continue_as_guest')}
                            </button>) : null
                        }

                    </div>
                    </div>
                </div>
        )
    }
};
function mapStateToProps(state) {
    return {
        loggedIn: state.auth.loggedIn,
        tenantId: state.auth.tenantId
    }
}
Login = withRouter( connect(mapStateToProps, {login, showForgottenPassModal})(Login) );
export default reduxForm({
    form: 'login',
    validate
})(Login)
