import {
    ADD_PREMIUM_DELIVERY,
    ADD_TO_CART,
    CLEAR_CART,
    HIDE_SIDE,
    MODIFY_AMOUNT,
    REMOVE_FROM_CART,
    REMOVE_PREMIUM_DELIVERY,
    SHOW_SIDE,
    SET_DELIVERY_COST,
    SET_ADDRESS_TYPE,
    LOGOUT,
    REQUIRED_INVOICE_ADDRESS,
    SET_ADMINISTRATIVE_FEE,
    REMOVE_ADMINISTRATIVE_FEE, SET_NUMBER_OF_FORMS
} from "../actions/actions";
import _ from 'lodash';

const initialState = {
    itemsInCart: 0,
    items: {},
    requiredInvoiceAddress: true,
    showSide: false,
    total: 0,
    shippingCost: 0,
    delivery: 0,
    premiumDelivery: 250,
    premiumSelected: false,
    addressType: 'delivery',
    administrativeFee: null,
    eInvoice: true,
    eInvoiceSelected: false
}

export default function(state = initialState, action) {
    let cart = {}
    switch(action.type) {
        case CLEAR_CART:
            return initialState
        case SHOW_SIDE:
            return {...state, showSide: true}
        case HIDE_SIDE:
            return {...state, showSide: false}
        case SET_ADDRESS_TYPE:
            return{
                ...state,
                addressType: action.addressType
            }
        case ADD_TO_CART:
            let addQuantity = 1;
            let addAmount = 0;
            if(state.items[action.article.id]) {
                addQuantity = 0;
                addAmount = state.items[action.article.id].amount;
            }
                cart = {
                ...state,
                showSide: action.showSide,
                itemsInCart: state.itemsInCart + addQuantity,
                items: {
                    ...state.items,
                    [action.article.id]: {
                        product: action.product,
                        amount: action.amount + addAmount,
                        article: action.article,
                        price: (action.article.price * (action.amount + addAmount))
                    }
                },
                total: 0
            }
            return {
                ...cart,
                total: calculateTotal(cart)
            }
        case REMOVE_FROM_CART:
            cart = {
                ...state,
                itemsInCart: state.itemsInCart - 1,
                items: _.omit(state.items, action.articleId)
            }
            return  {...cart,
                total: calculateTotal(cart),
            };
        case MODIFY_AMOUNT:
            cart = {
                ...state,
                items: {
                    ...state.items,
                    [action.articleId]: {
                        ...state.items[action.articleId],
                        amount: action.amount,
                        price: (state.items[action.articleId].article.price * action.amount )
                    }
                }
            }
            return {
                ...cart,
                total: calculateTotal(cart)
            }
        case SET_DELIVERY_COST:
            // const deliveryNormal = action.deliveryCost.normal ? action.deliveryCost.normal : 0
            let deliveryNormal;
            let deliveryName;
            let deliveryDescription;
            if (action.deliveryCost.normal) {
                deliveryNormal = action.deliveryCost.normal;
                deliveryName = action.deliveryCost.normalName;
                deliveryDescription = action.deliveryCost.normalDescription;
            } else {
                deliveryNormal = 0;
                deliveryName = null;
                deliveryDescription = null;
            }
            cart = {
                ...state,
                delivery: deliveryNormal,
                premiumDelivery: action.deliveryCost.premium,
                shippingCost: state.premiumSelected ? action.deliveryCost.premium : deliveryNormal,
                deliveryName,
                deliveryDescription
            }
            return {
                ...cart,
                total:calculateTotal(cart)
            };
        case ADD_PREMIUM_DELIVERY:
            cart = {...state, premiumSelected: true, shippingCost: state.premiumDelivery}
            return {
                ...cart,
                total:calculateTotal(cart)
            }
        case REMOVE_PREMIUM_DELIVERY:
            cart = {...state, premiumSelected: false, shippingCost: state.delivery}
            return {
                ...cart,
                total:calculateTotal(cart)
            }
        case LOGOUT:
            return initialState
        case REQUIRED_INVOICE_ADDRESS:
            return {
                ...state,
                requiredInvoiceAddress: action.required
            }
        case SET_ADMINISTRATIVE_FEE:
            cart = {
                ...state,
                administrativeFee: action.article,
                eInvoice: false,
                eInvoiceSelected: true
            };
            return {
                ...cart,
                total:calculateTotal(cart)
            };
        case REMOVE_ADMINISTRATIVE_FEE:
            cart = {
                ...state,
                administrativeFee: null,
                eInvoice: true,
                eInvoiceSelected: true
            };
            return {
                ...cart,
                total:calculateTotal(cart)
            };
        default:
            return state
    }
}

function calculateTotal(cart) {
    let administrativeFeePrice = 0;
    if(cart.administrativeFee){
        administrativeFeePrice = cart.administrativeFee.price;
    }
    return administrativeFeePrice + cart.shippingCost + _.sumBy(_.values(cart.items), function(cartItem) {
        if(isNaN(parseFloat(cartItem.article.price)) === false) {
            return cartItem.price
        }
    })
}
