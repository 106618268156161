import React from 'react';
import { Link } from 'react-router-dom';
import i18next from 'i18next';

export const LinkButton = (props) => {
    return <Link to={props.path || '#'} className="link-button" onClick={props.onClick}>
        <span className="link-button__name">{props.title}</span>
    </Link>
};

export const LinkButtonRight = (props) => {
        return <Link to={props.path || '#'} className={`link-button ${props.extraClass ? props.extraClass : ''}`} onClick={props.onClick}>
            <span className="link-button__name">{props.title}</span>
            <svg className="link-button__svg link-button__svg--last" viewBox="0 0 20 20">
                <use xlinkHref="#arrow-small-right"/>
            </svg>
        </Link>
};

export const LinkButtonLeft = (props) => {
    return <Link to={props.path || '#'} className="link-button" onClick={props.onClick}>
        <svg className="link-button__svg link-button__svg--first" viewBox="0 0 20 20">
            <use xlinkHref="#arrow-small-left"/>
        </svg>
        <span className="link-button__name">{props.title}</span>
    </Link>
};

export const LinkButtonDelete = (props) => {
    return <Link to={props.path || '#'} className="link-button" onClick={props.onClick}>
        <svg className="link-button__svg link-button__svg--first" viewBox="0 0 20 20" >
            <use xlinkHref="#x"/>
        </svg>
        <span className="link-button__name">{props.title}</span>
    </Link>
};

export const LinkButtonEdit = (props) => {
    return <Link to={props.path || '#'} className={`link-button ${props.extraClasses ? props.extraClasses : ''}`} onClick={props.onClick} style={props.style}>
        <svg className="link-button__svg link-button__svg--first" viewBox="0 0 20 20">
            <use xlinkHref="#pen"/>
        </svg>
        <span className="link-button__name">{props.title || i18next.t('buttons.edit')}</span>
    </Link>
};

export const LinkButtonSave = (props) => {
    return (
        <button type='submit' className={`button link-button link-button--neg information-address__button ${props.extraClasses}`}>
            <span property="name" className="link-button__name link-button__name--neg">{props.title}</span>
        </button>
    )
}

