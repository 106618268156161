import React, { Component } from 'react';
import {connect} from "react-redux";
import {Modal, Button} from "antd";
import { setCustomerType } from '../actions/actions';
import i18next from 'i18next';


class ChangeCustomerType extends React.Component {
    constructor(props) {
        super(props)
    }
    
    render() {
        return (
            <div style={{display: 'inline-block'}}>
                <Button onClick={() => this.props.setCustomerType(false)} type={!this.props.privateCustomerType ? "primary" : null}>
                    {i18next.t('customer.business')}
                </Button>
                <Button onClick={() => this.props.setCustomerType(true)} type={this.props.privateCustomerType ? "primary" : null} style={{margin: '0 10px'}}>
                    {i18next.t('customer.private')}
                </Button>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        showModal: state.layout.showCustomerTypeModal,
        privateCustomerType: state.customer.privateCustomerType,
    }
};

export default connect(mapStateToProps, {setCustomerType})(ChangeCustomerType);