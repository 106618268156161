import React, {Component} from 'react';
import {checkBox, textField} from "../../components/form/formFields";
import {Field, formValueSelector} from "redux-form";
import i18next from 'i18next';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Divider, Alert, Row, Col} from "antd";
import {getAdministrativeFee, REMOVE_ADMINISTRATIVE_FEE, removeAdministrativeFee} from "../../actions/actions";
import store from "../../store/store";
import InvoiceAddressForm from './InvoiceAddressForm';

class Invoice extends Component {
    componentDidMount() {
        if(this.props.emailInvoiceAddress) {
            store.dispatch({
                type: REMOVE_ADMINISTRATIVE_FEE
            })
        }

        if(this.props.tenantId === 5) {
            this.props.removeAdministrativeFee();
        }
    }

    eInvoiceChanged (value) {
        if(value === "YES") {
            this.props.removeAdministrativeFee();
        } else {
            this.props.getAdministrativeFee();
        }
    }
    renderOrganisationNumber() {
        if (this.props.guest) {
            return (
                <React.Fragment>
                    <Divider/>
                    <div style={{marginBottom: '10px'}}>
                        <Alert message={i18next.t('checkout_page.org_number_label')} type="warning" showIcon />
                    </div>
                    <Field
                        name='organisationNumber'
                        label={i18next.t('checkout_page.org_number_label')}
                        placeholder={i18next.t('checkout_page.org_number_label')}
                        component={textField}
                        maxLength="50"
                        type="text"
                        extraClasses='field-content--100' />
                </React.Fragment>
            )
        }
    }

    renderEinvoiceFields = () => {
        if (this.props.tenantId === 5 && this.props.invoiceTypeSelect === 'E-INVOICE') {
            return (
                <>
                    <div className="field-wrapper field-wrapper--margin-top">
                        <h3 className="field-wrapper__title">{i18next.t('account.information.electronic_invoice_address')}</h3>
                        <Field
                            name="electronicInvoiceSystemAddress"
                            label={i18next.t('account.information.electronic_invoice_address')}
                            placeholder={i18next.t('account.information.electronic_invoice_address')}
                            component={textField}
                            maxLength="50"
                            extraClasses='field-content--100'
                            type="text"
                        />
                    </div>
                    <div className="field-wrapper field-wrapper--margin-top">
                        <h3 className="field-wrapper__title">{i18next.t('account.information.electronic_operator')}</h3>
                        <Field
                            name="electronicOperator"
                            label={i18next.t('account.information.electronic_operator')}
                            placeholder={i18next.t('account.information.electronic_operator')}
                            component={textField}
                            maxLength="50"
                            extraClasses='field-content--100'
                            type="text"
                        />
                    </div>
                </>
            )
        }
    }

    render() {
        let {emailInvoiceAddress} = this.props;

        if (!this.props.requiredInvoiceAddress) {
            return null
        }
        return (
            <div className="checkout-step checkout-step--payment">
                <div className="checkout-step-header">
                    <h2 className="checkout-step-header__title">4. {i18next.t('checkout_page.payment')}</h2>
                </div>
                <div className="checkout-step-content">
                    <div className="checkout-step-content-wrapper">
                        {this.props.administrativeFee &&
                        <p>{i18next.t('checkout_page.e_invoices.description', {fee: this.props.administrativeFee.price})}</p>
                        }
                        <Row>
                            <Col span={12} style={{
                                display: this.props.tenantId === 5 ? 'none':'block'
                            }}>
                                <h3 className="checkout-step-content__subtitle">{i18next.t('checkout_page.payment_description')}</h3>
                                <label className="field-content__text">
                                    <Field className="checkout-step-content__input" type="radio" component="input"
                                           name="payment" value="INVOICE"/> {i18next.t('checkout_page.invoice')}
                                </label>
                            </Col>
                            {(!emailInvoiceAddress || this.props.tenantId === 5) && (this.props.corporateIdentityNumber || this.props.organisationNumber) && this.props.tenantId !== 6 &&
                            <Col span={12} style={{
                                display: this.props.tenantId === 5 ? 'none':'block'
                            }}>

                                <h3 className="checkout-step-content__subtitle">{i18next.t('checkout_page.e_invoices.label')}</h3>
                                <Row>
                                    <Col span={12}>
                                        <label className="field-content__text">
                                            <Field className="checkout-step-content__input" type="radio"
                                                   name="eInvoiceSelect" component="input"
                                                   onChange={(event, value) => this.eInvoiceChanged(value)}
                                                   value="YES"/> {i18next.t('checkout_page.e_invoices.yes')}
                                        </label>
                                    </Col>
                                    <Col span={12}>
                                        {
                                            this.props.tenantId !== 5
                                                ? (
                                                    <label className="field-content__text">
                                                        <Field className="checkout-step-content__input" type="radio"
                                                               name="eInvoiceSelect" component="input"
                                                               onChange={(event, value) => this.eInvoiceChanged(value)}
                                                               value="NO"/> {i18next.t('checkout_page.e_invoices.no')}
                                                    </label>
                                                ): null
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            }
                        </Row>
                        <br/>
                        <div className="checkout-step-content-wrapper__col-two">
                            <div className="field-content-wrapper">
                                <div className="field-content">
                                    <Field
                                        name='copyDelivery'
                                        label={i18next.t('checkout_page.copy_delivery')}
                                        component={checkBox}
                                    />
                                </div>
                                <div className="field-wrapper">
                                    {this.props.copyDelivery &&
                                        <>
                                            <Field
                                            name='invoiceRecipient'
                                            label={i18next.t('checkout_page.recipient')}
                                            placeholder={i18next.t('checkout_page.recipient')}
                                            component={textField}
                                            maxLength="50"
                                            type="text"
                                            readOnly
                                        />
                                        <Field
                                            name='invoiceStreetName'
                                            label={i18next.t('checkout_page.street_name')}
                                            placeholder={i18next.t('checkout_page.street_name')}
                                            component={textField}
                                            maxLength="50"
                                            type="text"
                                            extraClasses='field-content--50'
                                            readOnly
                                        />
                                        <Field
                                            name='invoiceStreetNumber'
                                            label={i18next.t('checkout_page.street_number')}
                                            placeholder={i18next.t('checkout_page.street_number')}
                                            component={textField}
                                            maxLength="50"
                                            type="text"
                                            extraClasses='field-content--25'
                                            readOnly
                                        />

                                        <Field
                                            name='invoiceStreetLetter'
                                            label={i18next.t('checkout_page.street_number_letter')}
                                            placeholder={i18next.t('checkout_page.street_number_letter')}
                                            component={textField}
                                            maxLength="50"
                                            type="text"
                                            extraClasses='field-content--25'
                                            readOnly
                                        />
                                        <Field
                                            name='invoiceZipCode'
                                            label={i18next.t('checkout_page.postcode')}
                                            placeholder={i18next.t('checkout_page.postcode')}
                                            component={textField}
                                            maxLength="50"
                                            type="text"
                                            extraClasses='field-content--25'
                                            readOnly
                                        />
                                        <Field
                                            name='invoiceCity'
                                            label={i18next.t('checkout_page.city')}
                                            placeholder={i18next.t('checkout_page.city')}
                                            component={textField}
                                            maxLength="50"
                                            type="text"
                                            extraClasses='field-content--50'
                                            readOnly
                                        />
                                        <Field
                                            name='invoiceCountry'
                                            label={i18next.t('checkout_page.country')}
                                            placeholder={i18next.t('checkout_page.country')}
                                            component={textField}
                                            maxLength="50"
                                            type="text"
                                            extraClasses='field-content--25'
                                            readOnly
                                        />
                                        <Field
                                            name='invoiceReference'
                                            label={i18next.t('checkout_page.invoice_reference')}
                                            placeholder={i18next.t('checkout_page.invoice_reference')}
                                            component={textField}
                                            maxLength="50"
                                            extraClasses='field-content--100'
                                            type="text"
                                            readOnly={this.props.tenantId !== 5}
                                        />
                                        {
                                            this.props.tenantId !== 5 ? (<Field
                                                name='emailInvoiceAddress'
                                                label={i18next.t('checkout_page.email_invoice_address')}
                                                placeholder={i18next.t('checkout_page.email_invoice_address')}
                                                component={textField}
                                                maxLength="50"
                                                type="email"
                                                extraClasses='field-content--100'
                                                readOnly
                                            />):null
                                        }
                                        {this.props.tenantId == 5 ? null : (
                                             <Field
                                                name='gln'
                                                label={i18next.t('checkout_page.global_loc_number')}
                                                placeholder={i18next.t('checkout_page.global_loc_number')}
                                                component={textField}
                                                maxLength="50"
                                                type="text"
                                                extraClasses='field-content--100'
                                                readOnly
                                            />
                                        )}
                                    </>
                                    }
                                    {!this.props.copyDelivery &&
                                        <InvoiceAddressForm />
                                    }
                                    {
                                        this.props.tenantId === 5 ? (
                                            <div className="field-wrapper field-wrapper--margin-top">
                                            <Row>
                                                <Col span={12}>
                                                    <label className="field-content__text" style={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Field className="checkout-step-content__input" type="radio"
                                                               name="invoiceTypeSelect" component="input"
                                                               value="E-INVOICE" style={{top: 0, marginRight: 10}}/> <span>{i18next.t('checkout_page.e-invoice')}</span>
                                                    </label>
                                                </Col>
                                                <Col span={12}>
                                                    <label className="field-content__text" style={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Field className="checkout-step-content__input" type="radio"
                                                               name="invoiceTypeSelect" component="input"
                                                               value="PDF" style={{top: 0, marginRight: 10}}/> {i18next.t('checkout_page.pdf-invoice')}
                                                    </label>
                                                </Col>
                                            </Row>
                                            </div>
                                        ):null
                                    }
                                    {
                                        this.props.tenantId === 5 && this.props.invoiceTypeSelect === 'PDF' ?(
                                            <div className="field-wrapper field-wrapper--margin-top">
                                                <Field
                                                    name="emailInvoiceAddress"
                                                    label={i18next.t('account.information.email_invoice_address')}
                                                    placeholder={i18next.t('account.information.email_invoice_address')}
                                                    component={textField}
                                                    maxLength="50"
                                                    extraClasses='field-content--100'
                                                    type="email"
                                                />
                                            </div>
                                        ):null
                                    }
                                    {this.renderEinvoiceFields()}
                                    {this.renderOrganisationNumber()}
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        )
    }
};

const selector = formValueSelector('order');

function mapStateToProps(state) {
    const emailInvoiceAddress = selector(state, 'emailInvoiceAddress');
    const organisationNumber = selector(state, 'organisationNumber');
    const copyDelivery = selector(state, 'copyDelivery');
    const {invoiceAddress} = state.customer;
    return {
        requiredInvoiceAddress: state.cart.requiredInvoiceAddress,
        guest: state.auth.guest,
        emailInvoiceAddress: emailInvoiceAddress,
        administrativeFee: state.cart.administrativeFee,
        eInvoice: state.cart.eInvoice,
        corporateIdentityNumber: state.customer.corporateIdentityNumber,
        organisationNumber: organisationNumber,
        copyDelivery: selector(state, 'copyDelivery'),
        invoiceTypeSelect: selector(state, 'invoiceTypeSelect'),
        tenantId: state.auth.tenantId,
        electronicInvoiceSystemAddress: (invoiceAddress && invoiceAddress.electronicInvoiceSystemAddress) ? invoiceAddress.electronicInvoiceSystemAddress : '',
        electronicOperator: (invoiceAddress && invoiceAddress.electronicOperator) ? invoiceAddress.electronicOperator : ''
    }
}

export default withRouter(connect(mapStateToProps, {getAdministrativeFee, removeAdministrativeFee})(Invoice));
