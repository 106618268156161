import {SHOW_POSTAL_ADDRESS_MODAL, SHOW_DELIVERY_ADDRESS_MODAL, SHOW_INVOICE_ADDRESS_MODAL, SHOW_MOBILE_MENU, SHOW_CUSTOMER_TYPE_MODAL, SHOW_CUSTOMER_SETTINGS_MODAL} from "../actions/actions";

const initialState = {
    mobileMenu: false,
    showPostalAddressModal: false,
    showDeliveryAddressModal: false,
    showInvoiceAddressModal: false,
    showCustomerTypeModal: false,
    showCustomerSettingsModal: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SHOW_CUSTOMER_TYPE_MODAL:
            return {...state, 
                    showCustomerTypeModal: action.payload.visible
            }
        case SHOW_CUSTOMER_SETTINGS_MODAL: 
            return {...state,
                    showCustomerSettingsModal: action.payload.visible
            }
        case SHOW_MOBILE_MENU:
            return {...state, mobileMenu: !state.mobileMenu}
        case SHOW_POSTAL_ADDRESS_MODAL:
            return {
                ...state,
                showPostalAddressModal: !state.showPostalAddressModal
            }
        case SHOW_DELIVERY_ADDRESS_MODAL:
            return {
                ...state,
                showDeliveryAddressModal: !state.showDeliveryAddressModal
            }
        case SHOW_INVOICE_ADDRESS_MODAL:
            return {
                ...state,
                showInvoiceAddressModal: !state.showDeliveryAddressModal
            }
        default :
            return state;
    }
}