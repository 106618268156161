import React, {Component} from 'react';
import BreadCrumbs from '../components/navigation/BreadCrumbs';
import {LinkButtonLeft} from '../components/links/LinkButton';
import Article from './Article';
import Product from './Product';
import SectionHeader from '../components/SectionHeader';
import Slick from 'react-slick';
import {connect} from "react-redux";
import {getProduct} from "../actions/actions";
import _ from "lodash";
import i18next from 'i18next';
import {SERVER_URL, VIDEO_PATH} from "../config/config";
import ReactPlayer from "react-player";

class ArticlePage extends Component {
    componentDidMount() {
        this.props.getProduct(this.props.match.params.id);
    }

    render() {
        window.scrollTo(0,0)

        if(!this.props.product) {
            return null
        }
        if(this.props.product.nonExistant) {
            this.props.history.push('/404')
            return null
        }

        const regexTest = new RegExp("\\[" + this.props.language + "\\].*?\"([\\S\\s]*?)\"")
        const matchName = regexTest.exec(this.props.product.name)
        const translatedName = (matchName && matchName.length > 1) ? matchName[1] : this.props.product.name

        const renderRelatedArticles = () => {
            if( _.isEmpty(this.props.product.related) ) {
                return null
            } else {
                const relatedProducts = _.pickBy(this.props.products, product => {
                    return this.props.product.related.some (relatedProduct => {
                        return product.id === relatedProduct.id
                    })
                })
                return (
                    <div className="main-content__secondary main-content__secondary--bg">
                        <div className="c">
                            <SectionHeader caption='' title={i18next.t('article_page.section_caption')} />
                                <div>
                                    <Slick {...sliderSettings}>
                                    {
                                        _.map(relatedProducts, product => {
                                            return (
                                                <div key={product.id}>
                                                    <Product key={product.id} item={product} currency={this.props.currency}/>
                                                </div>
                                            )
                                        })
                                    }
                                    </Slick>
                                </div>
                        </div>
                </div>
                )
            }
        };

        const renderVideos = () => {
            let url;
            if(this.props.product.videos.length > 1) {
                return (
                    <div className="main-content__secondary main-content__secondary--bg">
                        <div className="c">
                            <SectionHeader caption='' title={i18next.t('article_page.video')}/>
                            <div>
                                <Slick {...sliderSettings}>
                                    {
                                        this.props.product.videos.map(video => {
                                            if (video.source === 'FILE') {
                                                url = `${SERVER_URL}${VIDEO_PATH}${this.props.product.id}_${video.id}.${video.extension}`;
                                            } else {
                                                url = video.file
                                            }
                                            if (ReactPlayer.canPlay(url)) {
                                                return (
                                                    <div style={{paddingRight: '20px'}}>
                                                        <ReactPlayer
                                                            key={video.id}
                                                            width="100%"
                                                            url={url}
                                                            controls/>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div style={{paddingRight: '20px'}}>
                                                        <iframe width="100%" height="349"
                                                                src={url}
                                                                frameBorder="0" allowFullScreen/>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </Slick>
                            </div>
                        </div>
                    </div>
                )
            } else {
                if (this.props.product.videos.length === 1) {
                    let video = this.props.product.videos[0];
                    if (video.source === 'FILE') {
                        url = `${SERVER_URL}${VIDEO_PATH}${this.props.product.id}_${video.id}.${video.extension}`;
                    } else {
                        url = video.file
                    }
                    return (
                        <div className="main-content__secondary main-content__secondary--bg">
                            <div className="c" style={{margin: '0 auto'}}>
                                <SectionHeader caption='' title={i18next.t('article_page.video')}/>
                                <div className="video" style={{paddingLeft: 'auto', marginRight: 'auto', width: '544px'}}>
                                    <iframe width="544" height="349" align="center"
                                            src={url}
                                            frameBorder="0" allowFullScreen/>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
            return null;
        };

        return (
            <main className="main nopad typeset">

                <BreadCrumbs pagename={translatedName}  />
                <div className="categories-nav">
                    <div className="category-nav">
                        <LinkButtonLeft path='#' title={i18next.t('account.link_back')} onClick={() => {
                            if (this.props.history.location.state && this.props.history.location.state.from) {
                                this.props.history.push({
                                    pathname: this.props.history.location.state.from,
                                })
                            } else {
                                this.props.history.push(`/shop/${this.props.defaultArticleGroup}`)
                            }
                        }}  />
                    </div>
                </div>

                <div className="main-content">
                    <div className="main-content__primary main-content__primary--full">
                        <div className="c">
                            <Article item={this.props.product} />
                        </div>
                    </div>
                    {renderVideos()}
                    {renderRelatedArticles()}
                </div>
            </main>
            );
    }
};
function mapStateToProps(state, ownProps) {
    return {
        product: state.products[ownProps.match.params.id],
        products: state.products,
        language: state.language.language.language,
        defaultArticleGroup: state.customer.defaultArticleGroup,
        currency: state.auth.currency,
        isProccessing: state.auth.isProccessing
    }}

export default connect(mapStateToProps, {getProduct})(ArticlePage);

/** Slider settings. Check out all settings
 *  @see {@link https://react-slick.neostack.com/docs/api}
 * */
const sliderSettings = {
    className: 'products-slider',
    dots: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        { breakpoint: 800,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                infinite: false
            }
        }
    ]
}

/** Custom slider arrows */
function NextArrow(props) {
    return (
        <button className="next-thumb" onClick={props.onClick} style={{right: '-20px'}}>
            <svg className="prev-thumb__svg">
                <use xlinkHref="#arrow-right-circle"/>
            </svg>
        </button>
    );
}
function PrevArrow(props) {
    return (
        <button className="prev-thumb" onClick={props.onClick} style={{left: '-20px'}}>
            <svg className="prev-thumb__svg">
                <use xlinkHref="#arrow-left-circle"/>
            </svg>
        </button>
    );
}
