import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {IMAGE_PATH, SERVER_URL} from "../config/config";
import i18next from 'i18next';
import {connect} from "react-redux";
import ReactHtmlParser from 'react-html-parser';

const CartItem = (props) =>  {

    const { data } = props;
    let price = data.price * data.amount;
    let priceBrutto = price * `1.${props.vat}`;
    const notAlternativePrice = isNaN(parseFloat(price))
    const alternativePrice = data.alternativePrice === "alter price"
    
    if(props.completed && data.payer === 'TENANT') {
        price = 0
    }

    if(data.name === 'Frakt Premium') {
        return (
            <div className="cart-item">
                <div className="cart-item-image">
                    <img className="cart-item-image__img" src={SERVER_URL + IMAGE_PATH + data.image} alt='' />
                </div>

                <div className="cart-item-content">
                    <h2 className="cart-item-content-title">
                        <Link to="/" disabled className="cart-item-content-title__link">
                            {data.name}
                        </Link>
                    </h2>
                </div>

                <div className="cart-item-price">
                    <p className="cart-item-price__text">{props.privateCustomerType ? (alternativePrice ? i18next.t(data.alternativePrice) : priceBrutto) : (alternativePrice ? i18next.t(data.alternativePrice) : price)} {alternativePrice ? '' : props.currency}</p>
                </div>

            </div>
        )
    } else {
        const regexTest = new RegExp("\\[" + props.language + "\\].*?\"([\\S\\s]*?)\"")

        const matchName = regexTest.exec(data.name)
        const matchDescription = regexTest.exec(data.description)

        const translatedName = (matchName && matchName.length > 1) ? matchName[1] : data.name
        const translatedDescription = ReactHtmlParser((matchDescription && matchDescription.length > 1) ? matchDescription[1] : data.description)

        return (
            <div className="cart-item">
                <div className="cart-item-image">
                    <img className="cart-item-image__img" src={SERVER_URL + IMAGE_PATH + data.image} alt=''/>
                </div>

                <div className="cart-item-content">
                    <h2 className="cart-item-content-title">
                        <Link to={`/article/${data.containerId}`} className="cart-item-content-title__link">
                            {translatedName}
                        </Link>
                    </h2>

                    <p className="cart-item-info">
                        <span className="cart-item-info__key">{i18next.t('cart_item.about')}</span>
                        <span className="cart-item-info__value">{translatedDescription}</span>
                    </p>
                    <p className="cart-item-info">
                        <span className="cart-item-info__key">Art</span>
                        <span className="cart-item-info__value">{data.articleNumber}</span>
                    </p>
                    <p className="cart-item-info">
                        <span className="cart-item-info__key">{i18next.t('cart_item.pkg')}</span>
                        <span className="cart-item-info__value">{data.quantity}</span>
                    </p>
                    <p className="cart-item-info">
                        <span className="cart-item-info__key">{i18next.t('cart_item.amount')}</span>
                        <span className="cart-item-info__value">{data.amount}</span>
                    </p>


                </div>

                <div className="cart-item-price">
                <p className="cart-item-price__text">{props.privateCustomerType ? (alternativePrice ? i18next.t(data.alternativePrice) : priceBrutto) : (alternativePrice ? i18next.t(data.alternativePrice) : price)} {alternativePrice ? '' : props.currency}</p>
                </div>

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        language: state.language.language.language,
        currency: state.auth.currency,
        privateCustomerType: state.customer.privateCustomerType,
        vat: state.auth.vat
    }
}

export default withRouter(connect(mapStateToProps)(CartItem));
