import React, {Component} from 'react';
import { Link, withRouter } from 'react-router-dom';
import {SERVER_URL, IMAGE_PATH} from '../config/config';
import {addToCart} from "../actions/actions";
import {connect} from "react-redux";
import ImagePlaceholder from '../img/placeholder.png';
import ReactHtmlParser from 'react-html-parser';


class Product extends Component {
    constructor(props)  {
        super(props);
        const { item } = this.props;

        let price = '';
        let articleNumber = '';
        if(item && item.articles[0]) {
            price = item.articles[0].price;
            articleNumber = item.articles[0].articleNumber
        }
        this.state = {
            price,
            articleNumber,
            imageLoaded: false,
            replaceSrc: false
        };
        this.changeArticle = this.changeArticle.bind(this)
        this.addToCart = this.addToCart.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        const { item } = nextProps;

        let price = '';
        let articleNumber = '';
        if(item.articles[0] ) {
            price = item.articles[0].price;
            articleNumber = item.articles[0].articleNumber
        }
        this.setState({price, articleNumber});
    }
    changeArticle(evt) {
        const selectedArticle = this.props.item.articles.find (article => {
            return article.articleNumber === evt.target.value
        })
        this.setState({
            price: selectedArticle.price,
            articleNumber: selectedArticle.articleNumber
        })
    }
    addToCart() {
        const {item} = this.props;
        const article = item.articles.find( article => {
            return article.articleNumber === this.state.articleNumber
        });
        this.props.addToCart(item, article, 1);
    }

    componentDidMount() {
        this.setState({replaceSrc: true});
    }

    render() {
        const { item } = this.props;
        const {replaceSrc} = this.state;
        if(!item) {
            return null
        }
        const source = () => {
            if(item.popupImageFileName) {
                return `${SERVER_URL}${IMAGE_PATH}${item.popupImageFileName}`
            } else {
                return `${ImagePlaceholder}`
            }
        };

        const srcProp = {
          ... (replaceSrc ? {src: source()} : {})
        };

        const articleOptions = item.articles.map((article, i) => {
            return <option key={i} value={article.articleNumber}>{article.quantity}</option>
        })

        const regexTest = new RegExp("\\[" + this.props.language + "\\].*?\"([\\S\\s]*?)\"")

        const matchName = regexTest.exec(item.name)
        const matchDescription = regexTest.exec(item.description)

        const translatedName = (matchName && matchName.length > 1) ? matchName[1] : item.name
        const translatedDescription = ReactHtmlParser((matchDescription && matchDescription.length > 1) ? matchDescription[1] : item.description)

        const priceBrutto = this.state.price * `1.${this.props.vat}`;
        const notAlternativePrice = isNaN(parseFloat(this.state.price))

        return (
            <article className="product-card">
                <div className="product-card-actions">
                    <div className="product-card-actions__col-one">
                        <select className="product-card-actions__select" onChange={this.changeArticle}>
                            {articleOptions}
                        </select>
                        <p className="product-card-actions__current-price">
                        {this.props.privateCustomerType ? priceBrutto : this.state.price} {!notAlternativePrice ? this.props.currency : null}
                        </p>
                    </div>
                    <div className="product-card-actions__col-two" style={{background: '#C8002A'}} onClick={this.addToCart}>
                        <svg className="product-card-actions__icon">
                            <use xlinkHref="#cart"/>
                        </svg>
                    </div>
                </div>

                <Link to={{pathname: `/article/${item.id}`, state: { from: this.props.location.pathname }}} className="product-card__link" >
                    <div className="product-card-image" style={{fontSize: '12px'}}>
                        <img
                            data-src={source()}
                            {...srcProp}
                            onLoad={() => this.setState({imgLoaded: true})}
                            style={{
                                display: this.state.imgLoaded ? 'block' : 'none',
                                objectFit: "contain"
                            }}
                            width={300}
                            height={300}
                            alt={translatedName}
                            className="product-card-image__img"
                        />
                        <img
                            src={ImagePlaceholder}
                            style={{
                                display: !this.state.imgLoaded ? 'block' : 'none',
                            }}
                            alt={translatedName}
                            className="product-card-image__img"
                        />
                    </div>
                    <div className="product-card-content">
                        <h2 className="product-card-content__title" >{translatedName}</h2>
                        <p className="product-card-content__text" >{translatedDescription}</p>
                    </div>
                    <div className="product-card-footer">
                        <p className="product-card-content__price" style={{color: '#C8002A'}}>
                            {this.props.privateCustomerType ? priceBrutto : this.state.price} {!notAlternativePrice ? this.props.currency : null}
                        </p>
                    </div>
                </Link>

            </article>
        )
    };
}

function mapStateToProps(state) {
    return {
        language: state.language.language.language,
        privateCustomerType: state.customer.privateCustomerType,
        vat: state.auth.vat
    }
}
export default withRouter(connect(mapStateToProps, {addToCart})(Product) );
