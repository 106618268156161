import React from 'react';
import i18next from 'i18next';
import {connect} from "react-redux";
import {Modal} from "antd";
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';
import ChangeCustomerSettings from './ChangeCustomerSettings';
import { getTemporaryCustomer, showCustomerSettingsModal } from '../../actions/actions';


class CustomerSettingsModal extends React.Component  {
    constructor(props) {
        super(props)
    }

    componentDidUpdate(prevProps) {
        this.props.getTemporaryCustomer();
        if(prevProps.email !== this.props.email || prevProps.email === "") {
            if (this.props.temporaryPassword || this.props.email === "") {
                this.props.showCustomerSettingsModal(true);
            } else {
                this.props.showCustomerSettingsModal(false);
            }
        }
    }

    render() {  
        return (
            <Modal
                title={i18next.t('account.settings.change_settings')}
                className="type-modal settings-modal"
                centered={true}
                visible={this.props.showModal}
                footer={[
                  <ChangeCustomerSettings/>
                ]}
            />
        )
    }
};

function mapStateToProps(state) {
    return {
        email: state.customer.email,
        temporaryPassword: state.customer.temporaryPassword,
        showModal: state.layout.showCustomerSettingsModal
    }
};

export default connect(mapStateToProps, {getTemporaryCustomer, showCustomerSettingsModal})(CustomerSettingsModal);
