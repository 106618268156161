import React from 'react';
import i18next from 'i18next';

const Promotions = (props) => (
    <div className="promotions">
        <div className="c">
            <header className="promtions-header">
                <h2 className="promotions__title">{i18next.t(`homepage-${props.tenantId}.promotions_title`)}</h2>
            </header>
            <div className="promotions-content">
                <div className="promotions-content__col-one">
                    <p className="promotions__text">
                        {i18next.t(`homepage-${props.tenantId}.promotions_text`)}
                    </p>
                </div>
                <div className="promotions-content__col-two">
                    <div className="promotion">
                        <div className="promotion-icon">
                            <svg className="promotion-icon__svg">
                                <use xlinkHref="#deliveries"/>
                            </svg>
                        </div>
                        <div className="promotion-content">
                            <h2 className="promotion-content__title">
                                {i18next.t(`homepage-${props.tenantId}.promotions_content_title_1`)}
                            </h2>
                            <p className="promotion-content__text">
                                {i18next.t(`homepage-${props.tenantId}.promotions_content_text_1`)}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="promotions-content__col-three">
                    <div className="promotion">
                        <div className="promotion-icon">
                            <svg className="promotion-icon__svg">
                                <use xlinkHref="#servicebox"/>
                            </svg>
                        </div>
                        <div className="promotion-content">
                            <h2 className="promotion-content__title">
                                {i18next.t(`homepage-${props.tenantId}.promotions_content_title_2`)}
                            </h2>
                            <p className="promotion-content__text">
                                {i18next.t(`homepage-${props.tenantId}.promotions_content_text_2`)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
export default Promotions;