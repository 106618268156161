import React from 'react';
import {connect} from "react-redux";
import i18next from 'i18next';
import {withRouter} from "react-router-dom";
import _ from 'lodash';

const roundPrice = (price) => {
    const hasDecimal = price % 1;
    if (hasDecimal) {
        return price.toFixed(2);
    }
    return price;
}

const OrderSummary = (props) => {
    const totalBruttoPrice = props.total * `1.${props.vat}`;
    const shippingVat = (props.shippingCost * `0.${props.vat}`);

        return (
            props.total ?
                <div className="order-summary">
                <header className="order-summary-header">
                    <h2 className="order-summary-header__title">
                      {props.privateCustomerType ? i18next.t('cart.your_cart') : i18next.t('cart.your_cart_ex_vat') }
                    </h2>
                    <p className="order-summary-header__price">
                        {props.privateCustomerType ? roundPrice(totalBruttoPrice) : roundPrice(props.total)} {props.currency}
                    </p>
                </header>
                <p className="order-summary-item">
                    <span className="order-summary-item__key">{i18next.t('plurals.keyWithCount', {count: props.itemsInCart})}</span>
                    <span className="order-summary-item__value">{props.privateCustomerType ? roundPrice(totalBruttoPrice - props.shippingCost - shippingVat) : roundPrice(props.total - props.shippingCost) } {props.currency}</span>
                </p>
                <p className="order-summary-item">
                    <span className="order-summary-item__key">{i18next.t('cart.delivery')}</span>
                    <span className="order-summary-item__value">{props.privateCustomerType  ? roundPrice(props.shippingCost + shippingVat) : roundPrice(props.shippingCost)} {props.currency}</span>
                </p>
                <p className="order-summary-item">
                    <span className="order-summary-item__key">{i18next.t('cart.fees.administrative')}</span>
                    <span className="order-summary-item__value">{roundPrice(props.administrativeFeePrice)} {props.currency}</span>
                </p>
                <p className="order-summary-item">
                    <span className="order-summary-item__key">{`${i18next.t('cart.total')} Netto`}</span>
                    <span className="order-summary-item__value">{roundPrice(props.total)} {props.currency}</span>
                </p>
                <p className="order-summary-item">
                    <span className="order-summary-item__key">{i18next.t('cart.vat')}</span>
                    <span className="order-summary-item__value">{roundPrice(props.total * `0.${props.vat}`)} {props.currency}</span>
                </p>
                 <p className="order-summary-item">
                    <span className="order-summary-item__key">{`${i18next.t('cart.total')} Brutto`}</span>
                    <span className="order-summary-item__value">{roundPrice(totalBruttoPrice)} {props.currency}</span>
                </p>
            </div>
            :
            <div></div>
        )
};
function mapStateToProps(state) {
    let administrativeFeePrice = 0;
    if(state.cart.administrativeFee){
        administrativeFeePrice = state.cart.administrativeFee.price;
    }
    return {
        itemsInCart: state.cart.itemsInCart,
        total: state.cart.total,
        shippingCost: state.cart.shippingCost,
        vat: state.auth.vat,
        privateCustomerType: state.customer.privateCustomerType,
        administrativeFeePrice
    }
}
export default withRouter(connect(mapStateToProps)(OrderSummary));
