import React from 'react';
import { Accordion, AccordionItem, AccordionItemBody } from 'react-accordion-container';
import {Link} from 'react-router-dom';
import {LinkButton} from '../../components/links/LinkButton';
import { CSSTransition, CSSTransitionGroup, transit } from "react-css-transition";
import {connect} from "react-redux";
import {IMAGE_PATH, SERVER_URL} from "../../config/config";
import i18next from 'i18next';
import _ from 'lodash'
import {addToCart, loadMoreOrders, cancelOrder, getOrders} from "../../actions/actions";
import { Popconfirm, Icon } from 'antd';
import store from "../../store/store";
import ReactHtmlParser from 'react-html-parser';

const Orders = (props) => {
    const renderButton = () => {
        if(props.orders.length < props.limit) {
            return null
        } else {
            return (
                <Link to='#' onClick={props.loadMoreOrders} className="link-button link-button__loadmore" >
                    <span className="link-button__name">{i18next.t('account.orders.load_more')}</span>
                    <svg className="link-button__svg link-button__svg--last" viewBox="0 0 20 20">
                        <use xlinkHref="#arrow-small-right"/>
                    </svg>
                </Link>
            )
        }
    }

    function renderItems() {
        return props.orders.map((order, index) => {
            const expanded = ( props.location.pathname.indexOf(order.orderNumber) > -1 );
            return (
                <AccordionItem key={order.orderNumber} expanded={expanded} >
                    {({isActive, onClick}) => {
                        return (
                            <div className={`panel panel--order-items ${index % 2 === 0 ? 'panel--bg' : ''  }`} key={index}>

                                <PanelHeader data={order} products={props.products} addToCart={props.addToCart} onClick={() => {
                                    onClick()
                                }} {...props} />
                                <AccordionItemBody expanded={true}>
                                    <FadeInOutGroup>
                                        <div className='panel-content'>
                                            <p className="panel-content__title bold">Orderöversikt</p>

                                            {order.orderItems.map(product => {
                                                if (product.payer === 'TENANT') {
                                                    product.price = 0;
                                                    order.totalPrice = 0;
                                                }
                                                return <CartItem data={product} price={product.price} totalPrice={order.totalPrice} key={product.articleNumber} currency={props.currency} {...props} />
                                            })}

                                        </div>
                                    </FadeInOutGroup>

                                </AccordionItemBody>

                            </div>
                        )}}
                </AccordionItem>
            )
        })

    }

    return (
        <div className="main-content">
            <h2 className="preamble">{i18next.t('account.orders.my_orders')}</h2>

            <div className="order-items">

                <TableHeader />

                <Accordion>
                    { renderItems().slice(0, props.limit) }
                    {renderButton()}
                </Accordion>
            </div>
        </div>
    )

}

const FadeInOut = (props) => (
    <CSSTransition
        {...props}
        defaultStyle={{ opacity: 0 }}
        enterStyle={{ opacity: transit(1.0, 500, "ease-in-out") }}
        leaveStyle={{ opacity: transit(0, 500, "ease-in-out") }}
        activeStyle={{ opacity: 1.0 }}
    />
);

const FadeInOutGroup = (props) => (
    <CSSTransitionGroup {...props} transitionAppear>
        {
            React.Children.map(
                props.children,
                (child) => <FadeInOut>{child}</FadeInOut>,
            )
        }
    </CSSTransitionGroup>
);

const CartItem = (props) => {
    const { data } = props;
    let link = '#'
    if(data.containerId) {
        link = `/article/${data.containerId}`
    }

    const regexTest = new RegExp("\\[" + props.language + "\\].*?\"([\\S\\s]*?)\"")

    const matchName = regexTest.exec(data.name);
    const matchDescription = regexTest.exec(data.description);

    const translatedName = (matchName && matchName.length > 1) ? matchName[1] : data.name;
    const translatedDescription = ReactHtmlParser((matchDescription && matchDescription.length > 1) ? matchDescription[1] : data.description);

    return (
        <div className="cart-item">
            <div className="cart-item-image">
                <img className="cart-item-image__img" src={SERVER_URL + IMAGE_PATH + data.image} alt="" />
            </div>

            <div className="cart-item-content">
                <h2 className="cart-item-content-title">
                    <Link to={link} className="cart-item-content-title__link" >
                        {translatedName}
                    </Link>
                </h2>

                <p className="cart-item-info">
                    <span className="cart-item-info__key">Om</span>
                    <span className="cart-item-info__value">{translatedDescription}</span>
                </p>
                <p className="cart-item-info">
                    <span className="cart-item-info__key">Art</span>
                    <span className="cart-item-info__value">{data.articleNumber}</span>
                </p>
                <p className="cart-item-info">
                    <span className="cart-item-info__key">Fp</span>
                    <span className="cart-item-info__value">{data.quantity}</span>
                </p>
                <p className="cart-item-info">
                    <span className="cart-item-info__key">Antal</span>
                    <span className="cart-item-info__value">{data.amount}</span>
                </p>

            </div>

            <div className="cart-item-price">
                <p className="cart-item-price__text">{props.privateCustomerType ? props.price * `1.${props.vat}` : props.price} {props.currency}</p>
            </div>
        </div>


    )
};

const PanelHeader = (props) => {
    const { data } = props;

    function repeatOrder() {
        data.orderItems.forEach( (orderedProduct) => {
            _.some(props.products, product => {
                if(product.id === orderedProduct.containerId) {
                    product.articles.some( article => {
                        if(article.quantity === orderedProduct.quantity) {
                            props.addToCart(product, article, orderedProduct.amount, false)
                        }
                    })
                }
            })
        })
        props.history.push('/cart')
    }

    function cancelOrder(orderNumber) {
        props.cancelOrder(orderNumber);
    }

    function renderCancelButton() {
        if(data.invoiced || data.printed || data.canceled ) {
            return (
                <Icon type="close-square" theme="twoTone" style={{fontSize: '20px', color: '#a7a7a7', cursor: 'not-allowed'}} />
            )
        } else {
            return (
                <Popconfirm
                    title={i18next.t('notification.delete_order_confirm')}
                    onConfirm={() => cancelOrder(data.orderNumber)}
                    okText={i18next.t('notification.yes')}
                    cancelText={i18next.t('notification.no')} >
                    <Icon type="close-square" theme="twoTone" style={{fontSize: '20px', color: '#C8002A', cursor: 'pointer'}} />
                </Popconfirm>
            )
        }
    }

    let status = "";
    let statusColor = "green";

    if(data.canceled) {
        status = i18next.t('account.orders.canceled');
        statusColor = "#C8002A";
    } else {
        if (data.printed) {
            status = i18next.t('account.orders.printed')
        }
        if(data.invoiced) {
            if(status.length > 0) {
                status += ` / ${i18next.t('account.orders.invoiced')}`
            } else {
                status = i18next.t('account.orders.invoiced')
            }
        }
    }

    return (
        <div className="panel-header">
            <div className="panel-header__col-one">
                <p className="panel-header__info" role="button" onClick={() => {props.history.push(`/account/orders/${data.orderNumber}`); props.onClick()}}>
                    <span className="panel-header__title mob">{i18next.t('account.orders.ord_number')}</span>
                    <span className="panel-header__link"  >
                        {data.orderNumber}
                    </span>

                    <svg className='link-button__svg link-button__svg--last' viewBox="0 0 20 20">
                        <use xlinkHref="#arrow-small-right"/>
                    </svg>
                </p>
            </div>

            <div className="panel-header__col-two">
                <p className="panel-header__info">
                    <span className="panel-header__title mob">{i18next.t('account.orders.ord_date')}</span>
                    {new Date(data.orderDate).toDateString()}
                </p>
            </div>

            <div className="panel-header__col-three">
                <p className="panel-header__info">
                    <span className="panel-header__title mob">{i18next.t('account.orders.ord_price')}</span>
                    {data.totalPrice} {props.currency}
                </p>
            </div>

            <div className="panel-header__col-four">
                <p className="panel-header__info">
                    <span className="panel-header__title mob">{i18next.t('account.orders.status')}</span>
                    <span style={{color: `${statusColor}`}}><b>{status}</b></span>
                </p>
            </div>

            <div className="panel-header__col-five">
                <p className="panel-header__info">
                    <span className="panel-header__title mob">{i18next.t('account.orders.tracking_number')}</span>
                    <span>
                        {data.parcelNumber}
                    </span>
                </p>
            </div>
            <div className="panel-header__col-six">
                <p className="panel-header__info">
                    <span className="panel-header__title mob">{i18next.t('account.orders.repeat_order')}</span>
                    <LinkButton
                        path='#'
                        onClick={repeatOrder}
                        title={i18next.t('account.orders.repeat_order')}
                    />
                </p>
            </div>

            <div className="panel-header__col-seven">
                <p className="panel-header__info">
                    {renderCancelButton()}
                </p>
            </div>

        </div>
    )
};

const TableHeader = () => {
    return (
        <div className="panel panel--order-items">
            <div className="panel-header">
                <div className="panel-header__col-one">
                    <p className="panel-header__info">
                        <span className="panel-header__title">
                            <span className="panel-header__link">{i18next.t('account.orders.ord_number')}</span>
                        </span>
                    </p>
                </div>

                <div className="panel-header__col-two">
                    <p className="panel-header__info">
                        <span className="panel-header__title">
                            <span className="panel-header__link">{i18next.t('account.orders.ord_date')}</span>
                        </span>
                    </p>
                </div>

                <div className="panel-header__col-three">
                    <p className="panel-header__info">
                        <span className="panel-header__title">
                            <span className="panel-header__link">{i18next.t('account.orders.ord_price')}</span>
                        </span>
                    </p>
                </div>

                <div className="panel-header__col-four">
                    <p className="panel-header__info">
                        <span className="panel-header__title">
                            <span className="panel-header__link">{i18next.t('account.orders.status')}</span>
                        </span>
                    </p>
                </div>

                <div className="panel-header__col-five">
                    <p className="panel-header__info">
                        <span className="panel-header__title">
                            <span className="panel-header__link">{i18next.t('account.orders.tracking_number')}</span>
                        </span>
                    </p>
                </div>
                <div className="panel-header__col-six">
                    <p className="panel-header__info">
                        <span className="panel-header__title">
                            <span className="panel-header__link">{i18next.t('account.orders.repeat_order')}</span>
                        </span>
                    </p>
                </div>
                <div className="panel-header__col-seven">
                    <p className="panel-header__info">
                        <span className="panel-header__title">
                            <span className="panel-header__link">{i18next.t('account.orders.cancel_order')}</span>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {
        orders: state.customer.orders,
        products: state.products,
        limit: state.customer.limit,
        language: state.language.language.language,
        currency: state.auth.currency,
        privateCustomerType: state.customer.privateCustomerType,
        vat: state.auth.vat
    }
}

export default connect(mapStateToProps, {addToCart, loadMoreOrders, cancelOrder, getOrders})(Orders)