import React, { Component } from 'react';
import BreadCrumbs from '../../components/navigation/BreadCrumbs';
import {LinkButtonLeft} from '../../components/links/LinkButton';
import OrderSummary from './OrderSummary';
import Login from './Login';
import CartItem from './CartItem';
import Product from '../Product';
import ProductList from '../ProductList';
import Slick from 'react-slick';
import _ from "lodash";
import {connect} from "react-redux";
import {getProducts} from "../../actions/actions";
import ApiConnector from "../../api/apiConnection";
import i18next from 'i18next';


class CartPage extends Component {
    constructor(props)  {
        super(props);
        this.state = {
            perPage: 3,
            total: 0,
            defaultArticleGroup: ''
        };
    }

    componentDidUpdate(prevProps, props) {
        if(prevProps.isProcessing === true && this.props.isProcessing === false) {
            const that = this
            ApiConnector.getTotal()
                .then(response => {
                    that.setState({
                        total: response.data
                    })
                })
            this.props.getProducts(this.state.perPage);
        }
    }

    renderCartItems() {
        const cartItems = _.map(this.props.cart, cartItem => {
            return <CartItem key={cartItem} cartItem={cartItem} {...this.props} />
        })
        if(cartItems.length > 0) {
            return (
                <div className="cart">
                    {cartItems}
                </div>
            )
        } else {
            return <p>{i18next.t('cart.empty_cart')}</p>
        }
    }

    render() {
        return(
            <div className="wrapper">

                <BreadCrumbs pagename={i18next.t('cart.page_name')} />

                <div className="categories-nav">
                    <div className="category-nav">
                        <LinkButtonLeft path='#' title={i18next.t('cart.link_back')} onClick={() => this.props.history.push(`/shop/${this.props.defaultArticleGroup}`) }  />
                    </div>
                </div>

                <main className="main typeset">
                    <div className="c">
                        <div className="page-header">
                            <div className="page-header__col-one">
                                <h1 className="page-header__title">{i18next.t('cart.page_name')}</h1>
                            </div>
                        </div>

                        <div className="main-content">
                            <div className="main-content__primary">
                                {this.renderCartItems()}
                                <Login {...this.props} />
                            </div>

                            <aside className="main-content__sidebar">
                                <OrderSummary key={new Date().getTime()} currency={this.props.currency} {...this.props} />
                            </aside>
                        </div>

                        <div className="section-header">
                            <h2 className="section-header__sub-title section-header__sub-title--left">
                                {i18next.t('cart.accessories_title')}
                            </h2>
                        </div>

                        <div className="products products--list">
                            {
                                _.map(this.props.products, product => {
                                    return (
                                        <ProductList key={product.id}
                                                     item={product}
                                                     currency={this.props.currency}
                                        />
                                    )})
                            }
                        </div>

                        <div className="section-header section-header__recommended">
                            <h2 className="section-header__sub-title section-header__sub-title--left">
                                {i18next.t('cart.recommended')}
                            </h2>
                        </div>

                        <div>
                            <Slick {...sliderSettings}>
                                    {
                                        _.map(this.props.products, product => {
                                            return (
                                                <div key={product.id}>
                                                    <Product item={product} currency={this.props.currency} />
                                                </div>
                                            )
                                        })
                                    }

                            </Slick>
                        </div>

                    </div>
                </main>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        products: state.products,
        cart: state.cart.items,
        total: state.cart.total,
        defaultArticleGroup: state.customer.defaultArticleGroup,
        currency: state.auth.currency,
        isProcessing: state.auth.isProcessing
    }
}
export default connect(mapStateToProps, {getProducts}) (CartPage);

const sliderSettings = {
    className: 'products-slider',
    dots: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        { breakpoint: 800,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                infinite: false
            }
        }
    ]
}

/** Custom slider arrows */
function NextArrow(props) {
    return (
        <button className="next-thumb" onClick={props.onClick} style={{right: '-20px'}}>
            <svg className="prev-thumb__svg">
                <use xlinkHref="#arrow-right-circle"/>
            </svg>
        </button>
    );
}
function PrevArrow(props) {
    return (
        <button className="prev-thumb" onClick={props.onClick} style={{left: '-20px'}}>
            <svg className="prev-thumb__svg">
                <use xlinkHref="#arrow-left-circle"/>
            </svg>
        </button>
    );
}
