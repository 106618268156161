import React, { Component } from 'react';
import i18next from 'i18next';
import HomeBanner from '../components/HomeBanner';
import SectionHeader from '../components/SectionHeader';
import Promotions from '../components/Promotions';
import Product from './Product';
import Campaigns from '../components/Campaigns';
import {connect} from "react-redux";
import {getRecommendedProducts, getCampaigns} from "../actions/actions";
import _ from "lodash";

class HomePage extends Component {

    componentWillMount() {
        this.props.getRecommendedProducts();

        if (!this.props.publicTenant) {
            this.props.getCampaigns(3, 0);
        }
    }

    componentDidUpdate(prevProps, props) {
        if(prevProps.isProcessing === true && this.props.isProcessing === false) {
            this.props.getRecommendedProducts();
        }
    }

    render() {
        let recommended = _.pickBy(this.props.products, product => {
            return product.recommended
        });
        if(Object.keys(recommended).length > 4) {
            const selectedRecommended = {}
            for(let id in recommended) {
                if(recommended.hasOwnProperty(id)) {
                    selectedRecommended[id] = recommended[id]
                }
                if(Object.keys(selectedRecommended).length >= 4) {
                    break
                }
            }
            recommended = selectedRecommended
        }
        return (
            <React.Fragment>
                <HomeBanner tenantId={this.props.tenantId} />
                <main className="main typeset">
                    <SectionHeader caption={i18next.t(`homepage-${this.props.tenantId}.section_title`)} title={i18next.t(`homepage-${this.props.tenantId}.section_caption`)} />
                    <div className="products products--gallery">
                        <div className="c">
                            <div className='products--recommended'>
                                {
                                    _.map(recommended, product => {
                                        return (
                                            <Product key={product.id}
                                                     item={product}
                                                     currency={this.props.currency}
                                            />
                                        )})
                                }
                            </div>
                        </div>
                    </div>
                    {
                        this.props.tenantId !== 5
                            ? (
                                <>
                                    <Promotions tenantId={this.props.tenantId}/>
                                    <div className="c">
                                        <h2 className="section-header__title" style={{padding: '0 0 3.2184rem 0'}}>{i18next.t(`homepage-${this.props.tenantId}.campaigns`)}</h2>
                                        <div className='spot-wrapper'>
                                            <Campaigns data={this.props.campaigns} onClick={() => window.scrollTo(0, 0)} />
                                        </div>
                                    </div>
                                </>
                            ) : null
                    }
                </main>
            </React.Fragment>
        );
    }
};
function mapStateToProps(state) {
    return {
        products: state.products,
        campaigns: state.customer.campaigns,
        publicTenant: state.auth.publicTenant,
        tenantId: state.auth.tenantId,
        currency: state.auth.currency,
        isProcessing: state.auth.isProcessing
    }
}
export default connect(mapStateToProps, {getRecommendedProducts, getCampaigns})(HomePage);

