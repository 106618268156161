import React from 'react';
import {checkBox} from "../../components/form/formFields";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import i18next from 'i18next';
import {acceptNewsletter} from '../../actions/actions';

let ChangeNewsletter = (props) => {

    function acceptNewsletter(values) {
        return props.acceptNewsletter(values.allowNewsletter)
    }
    return (
    <React.Fragment>
        <form onSubmit={props.handleSubmit(acceptNewsletter)}>
            <Field
                name='allowNewsletter'
                label={i18next.t('account.settings.newsletter_item_1')}
                component={checkBox}
                className='confirm-content__input'
                defaultChecked={props.allowNewsletter}
            />
            <button className="information-address__button link-button link-button--neg" type='submit'>
                <span property="name" className="link-button__name link-button__name--neg">{i18next.t('account.settings.save')}</span>
            </button>
        </form>
    </React.Fragment>
    )
};

function mapStateToProps(state) {
    return {
        allowNewsletter: state.customer.allowNewsletter
    }
}

ChangeNewsletter = reduxForm({
    form: 'newsletter',
    enableReinitialize: true
})(ChangeNewsletter);
export default connect(mapStateToProps, {acceptNewsletter}) (ChangeNewsletter );