import React, { useState, useEffect } from 'react';
import {connect} from "react-redux";
import BottomPromotions from './BottomPromotions';
import { Link, withRouter } from 'react-router-dom';
import Cookies from './Cookies/Cookies';
import LinkFooter from './links/LinkFooter';
import i18next from 'i18next';

const Footer = (props) => {
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (openModal) {
            document.body.classList.add("overflow")
        } else {
            document.body.classList.remove("overflow")
        }
    }, [openModal])

    function renderAccount() {
        if(props.loggedIn) {
            return (
                <div className="footer-links-bottom">
                    <div className="footer-links-bottom__col-one">
                        <h2 className="preamble white">{i18next.t('footer.links.information')}</h2>
                        <ul className="footer-list">
                            <li className="footer-list__item">
                                <LinkFooter path="/account" title={i18next.t('footer.links.my_account')} />
                            </li>
                        </ul>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
    (function loadScript() {
        const tag = document.createElement('script');
        tag.defer = true;
        tag.src = 'https://www.ehandelscertifiering.se/lv6/bootstrap.php?url=estore.loomis.se&size=80px&lang=sv&autolang=off&grayscale=&opacity=&shadow=&nr=716';
        if (props.publicTenant) {
            document.body.appendChild(tag);
        }
    })();

    function renderShop() {
        return (
            <li className="footer-list__item">
                <LinkFooter path={`/shop${props.tenantId == 5 ? '/133' : ''}`} title={i18next.t(`header.shop_name-${props.tenantId}`)} />
            </li>
        )
    }

    function renderFAQ() {
        if(!props.publicTenant || props.tenantId === 5) {
            return (
                <li className="footer-list__item">
                    <LinkFooter path="/faq" title="FAQ" />
                </li>
            )
        } else {
            return null
        }
    }



    function renderTermsPage() {
        if(props.publicTenant) {
            return (
                <li className="footer-list__item">
                    <LinkFooter path="/terms" title={i18next.t(`terms_page-${props.tenantId}.page_name`)} />
                </li>

            )
        } else {
            return null
        }
    }

    function renderContactPage() {
        if(props.publicTenant) {
            return (
                <li className="footer-list__item">
                    <LinkFooter path="/contact" title={i18next.t(`contact_page-${props.tenantId}.page_name`)} />
                </li>

            )
        } else {
            return null
        }
    }

    return (
        <React.Fragment>
            <BottomPromotions {...props} />
            <footer className="footer">
                <div className="footer__inner">
                    <div className="footer__col-one">
                        <div className="footer-links">
                            <div className="footer-links-top">
                                <div className="footer-links-top__col-one">
                                    <h2 className="preamble white">{i18next.t('footer.links.shop')}</h2>
                                    <ul className="footer-list">
                                        <li className="footer-list__item">
                                            <LinkFooter path="/" title={i18next.t('footer.links.home')} />
                                        </li>
                                        {renderShop()}
                                        {renderContactPage()}
                                    </ul>
                                </div>
                                <div className="footer-links-top__col-two">
                                    <h2 className="preamble white">{i18next.t('footer.links.secure')}</h2>
                                    <ul className="footer-list">
                                        <li className="footer-list__item">
                                            <LinkFooter path="/about" title={i18next.t('footer.links.about_us')} />
                                        </li>
                                        {renderTermsPage()}
                                        {renderFAQ()}
                                        <li className="footer-list__item">
                                            <Link to="$" onClick={(e) => {
                                                e.preventDefault();
                                                setOpenModal(true);
                                            }} className="footer-list__link"  style={{marginBottom: 5, display: 'block'}}>{i18next.t(`manage-cookies2`)}</Link>
                                        </li>
                                        <li className="footer-list__item">
                                            <Link className="footer-list__link" to="/cookieinformation" onClick={() => window.scrollTo(0, 0)} >{i18next.t(`footer.cookies-${props.tenantId}`)}</Link>
                                        </li>
                                    </ul>
                                </div>


                            </div>

                            {renderAccount()}

                        </div>

                        <div className="footer-contact">
                            <ul className="footer-contact-list">
                                <li className="footer-contact-list__item">
                                    <Link to="#" className="footer-contact-list__link">
                                        <i className="footer-contact-list-icon icon-phone">
                                            <svg className="footer-contact-list-icon__svg" viewBox="0 0 20 20">
                                                <use xlinkHref="#phone"/>
                                            </svg>
                                        </i>
                                        <span className="footer-contact-list__text">
                                            {!props.publicTenant ? `010-163 63 00` : props.tenantId === 5 ? '+358 20 430 1151' : `08-522 247 70`}
                                        </span>
                                    </Link>
                                </li>
                                <li className="footer-contact-list__item" >
                                    <Link to="#" className="footer-contact-list__link">
                                        <i className="footer-contact-list-icon icon-mail">
                                            <svg className="footer-contact-list-icon__svg" viewBox="0 0 20 20">
                                                <use xlinkHref="#mail"/>
                                            </svg>
                                        </i>
                                        <span className="footer-contact-list__text">{props.tenantId === 5 ? 'fi.asiakaspalvelu@loomis.com' : 'les.kundtjanst@loomis.com'}</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="footer-copyright">
                            {i18next.t(`footer.copyright-${props.tenantId}`)} <a href="https://www.visionmate.se/" target="_blank">Visionmate</a>
                        </div>
                    </div>
                    <div className="footer__col-two">
                        <div className="footer-about" style={{paddingBottom: 45}}>
                            <h2 className="preamble white">Loomis eStore</h2>
                            <p className="footer-about__text">
                                {i18next.t(`footer.loomis_description-${props.tenantId}`)}
                            </p>
                            <p className="footer-about__links">

                            </p>
                        </div>
                    </div>
                </div>
            </footer>

           <Cookies openModal={openModal} setOpenModal={setOpenModal} />

        </React.Fragment>
    )};

function mapStateToProps(state) {
    return {
        loggedIn: state.auth.loggedIn,
        publicTenant: state.auth.publicTenant,
        tenantId: state.auth.tenantId,
        language: state.language
    }
}

export default withRouter(connect(mapStateToProps, null) (Footer) );
