import React from 'react';
import {connect} from "react-redux"
import BreadCrumbs from './navigation/BreadCrumbs';
import { Collapse } from 'antd';
import i18next from 'i18next';

const FAQPage = (props) => {
    return(
        <div className="wrapper">
            <BreadCrumbs pagename='FAQ' />

            <main className="main typeset">

                <div className="main-content main-content--padding-top">

                    <div className="c">
                        <Collapse  defaultActiveKey={['1']}>
                            <Collapse.Panel header={i18next.t(`faq_page-${props.tenantId}.q1`)} key="1">
                                <p>
                                    {i18next.t(`faq_page-${props.tenantId}.a1`)}
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={i18next.t(`faq_page-${props.tenantId}.q2`)} key="2">
                                <p>
                                    {i18next.t(`faq_page${props.tenantId}.a2`)}
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={i18next.t(`faq_page-${props.tenantId}.q3`)} key="3">
                                <p>
                                    {i18next.t(`faq_page-${props.tenantId}.a3`)}
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={i18next.t(`faq_page-${props.tenantId}.q4`)} key="4">
                                <p>
                                    {i18next.t(`faq_page-${props.tenantId}.a4`)}
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={i18next.t(`faq_page-${props.tenantId}.q5`)} key="5">
                                <p>
                                    {i18next.t(`faq_page-${props.tenantId}.a5`)}
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={i18next.t(`faq_page-${props.tenantId}.q6`)} key="6">
                                <p>
                                    {i18next.t(`faq_page-${props.tenantId}.a6`)}
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={i18next.t(`faq_page-${props.tenantId}.q7`)} key="7">
                                <p>
                                    {i18next.t(`faq_page-${props.tenantId}.a7`)}
                                </p>
                            </Collapse.Panel>
                            {props.tenantId !== 5 && 
                                <>
                                    <Collapse.Panel header={i18next.t(`faq_page-${props.tenantId}.q8`)} key="8">
                                        <p>
                                            {i18next.t(`faq_page-${props.tenantId}.a8`)}
                                        </p>
                                    </Collapse.Panel>
                                    <Collapse.Panel header={i18next.t(`faq_page-${props.tenantId}.q9`)} key="9">
                                        <p>
                                            {i18next.t(`faq_page-${props.tenantId}.a9`)}
                                        </p>
                                    </Collapse.Panel>
                                </>
                            }
                        </Collapse>
                    </div>

                </div>
            </main>

        </div>
    )
}


function mapStateToProps(state) {
    return {
        tenantId: state.auth.tenantId
    }
}
export default connect(mapStateToProps, null)(FAQPage);