import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Transition, animated } from 'react-spring';
import {connect} from "react-redux";
import {loadMoreOrders} from "../../actions/actions";
import i18next from 'i18next';

class PreviousOrders extends Component {

    render() {

        const renderButton = () => {
            if(this.props.orders.length === this.props.allOrders.length) {
                return null
            } else {
                return (
                    <Link to='#' onClick={this.props.onClick} className="link-button link-button__loadmore" >
                        <span className="link-button__name">{i18next.t('account.overview.load_more')}</span>
                        <svg className="link-button__svg link-button__svg--last" viewBox="0 0 20 20">
                            <use xlinkHref="#arrow-small-right"/>
                        </svg>
                    </Link>
                )
            }
        }

        const innerWidth = window.innerWidth;

        return (
            <React.Fragment>
            <h5>{i18next.t('account.overview.orders')}</h5>
            <div className="order-items">
                <div className="panel panel--prev-order-items">
                    <div className="panel-header">
                        <div className="panel-header__col-one">
                            <p className="panel-header__info">
                                <span className="panel-header__title">
                                    <span className="panel-header__link">{i18next.t('account.overview.ord_number')}</span>
                                </span>
                            </p>
                        </div>

                        <div className="panel-header__col-two">
                            <p className="panel-header__info">
                                <span className="panel-header__title">
                                    <span className="panel-header__link">{i18next.t('account.overview.ord_date')}</span>
                                </span>
                            </p>
                        </div>

                        <div className="panel-header__col-two">
                            <p className="panel-header__info">
                                <span className="panel-header__title">
                                    <span className="panel-header__link">{i18next.t('account.overview.vat')}</span>
                                </span>
                            </p>
                        </div>

                        <div className="panel-header__col-three">
                            <p className="panel-header__info">
                                <span className="panel-header__title">
                                    <span className="panel-header__link">{i18next.t('account.overview.ord_price')}</span>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <Transition
                    native
                    keys={this.props.orders.map(order => order.orderNumber)}
                    from={{ opacity: 0, height: 0 }}
                    enter={{ opacity: 1, height: innerWidth > 768 ? 52 : 200 }}
                    leave={{ opacity: 0, height: 0 }}
                >
                {this.props.orders.map( (order, index) => styles => {
                    order.orderItems.map(orderItem => {
                        if (orderItem.payer === 'TENANT') {
                            orderItem.price = 0;
                            order.totalPrice = 0;
                        }
                    });
                        return (
                            <animated.div style={styles} className={`panel panel--prev-order-items  ${index % 2 === 0 ? 'panel--bg' : ''}`} key={order.orderNumber}>
                                <div className="panel-header">
                                    <div className="panel-header__col-one">
                                        <p className="panel-header__info">
                                            <span className="panel-header__title mob">{i18next.t('account.overview.ord_number')}</span>
                                            <Link to={`/account/orders/${order.orderNumber}`} className="panel-header__link">{order.orderNumber}</Link>
                                        </p>
                                    </div>

                                    <div className="panel-header__col-two">
                                        <p className="panel-header__info">
                                            <span className="panel-header__title mob">{i18next.t('account.overview.ord_date')}</span>
                                            {new Date(order.orderDate).toDateString()}
                                        </p>
                                    </div>
                                    <div className="panel-header__col-two">
                                        <p className="panel-header__info">
                                            <span className="panel-header__title mob">{i18next.t('account.overview.vat')}</span>
                                            {Number(order.totalPrice * `0.${this.props.vat}`).toFixed(2)}
                                        </p>
                                    </div>
                                    <div className="panel-header__col-three">
                                        <p className="panel-header__info">
                                            <span className="panel-header__title mob">{i18next.t('account.overview.ord_price')}</span>
                                            {order.totalPrice} {this.props.currency}
                                        </p>
                                    </div>
                                </div>
                            </animated.div>
                        )
                    })
                }
                </Transition>

                {renderButton()}

            </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {

    return {
        allOrders: state.customer.orders,
    }
}

export default connect(mapStateToProps, {loadMoreOrders}) (PreviousOrders);