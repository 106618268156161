import React, { Component } from 'react';
import ChangeEmail from "./ChangeEmail";
import ChangePassword from "./ChangePassword";
import ChangeNewsletter from "./ChangeNewsletter";
// import ChangeCustomerType from "../ChangeCustomerType";
import i18next from 'i18next';

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newsletter: true
        }
        this.toggleNewsletter = this.toggleNewsletter.bind(this);

    }
    toggleNewsletter() {
        this.setState({ newsletter: !this.state.newsletter})
    }
    render() {
        return (
            <div className="main-content">

                <div className="placeholder">

                    <div className="main-content__primary">

                        <h2 className="preamble">{i18next.t('account.settings.settings')}</h2>
                        <div className="field-wrapper settings-form">
                            <ChangeEmail {...this.props} />
                        </div>

                        <div className="field-wrapper settings-form">
                            <ChangePassword {...this.props} />
                        </div>

                        <div className="field-wrapper settings-form">

                            <h3 className="field-wrapper__title">{i18next.t('account.settings.newsletter')}</h3>

                            <p className="caption bold">{i18next.t('account.settings.newsletter_subtitle')}</p>

                            <div className="field-content">
                                <ChangeNewsletter {...this.props} />
                            </div>

                        </div>

                    </div>

                    <aside className="main-content__sidebar"></aside>

                </div>

            </div>
        )
    }
}
export default Settings;