import React from 'react';
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {textField} from "./form/formFields";
import {postNewPassword} from '../actions/actions';
import i18next from "i18next";
import {Alert} from "antd";

const validate = (values) => {
    const errors = {}
    if(!values.newPassword) {
        errors.newPassword = i18next.t('errors.required')
    }
    if(!values.confirmPassword) {
        errors.confirmPassword = i18next.t('errors.required')
    }
    else if(values.newPassword !== values.confirmPassword) {
        errors.confirmPassword = i18next.t('errors.not_match_pass')
    }
    return errors
}

let ResetPasswordPage = (props) => {

    const { handleSubmit, error } = props;

    function submitNewPassword(values) {
        const data = {
            newPassword: values.newPassword,
            t: props.match.params.t
        };
        return props.postNewPassword(data, props.history)

    }
    if(!props.match.params.t) {
        props.history.push('/')
    }
    return (
        <div className="wrapper">
            <main className="main typeset">
                <div className="c">
                    <div className="page-header">
                        <div className="page-header__col-one">
                            <h1 className="page-header__title">{i18next.t('reset_page.title')}</h1>
                        </div>
                    </div>
                    <div className="main-content">
                        <div className="placeholder">
                            <div className="main-content__primary main-content__primary--full">
                                <div className='reset-password-container'>
                                    <form onSubmit={handleSubmit((values) => submitNewPassword(values))}>
                                        { error && <Alert
                                            message="Error"
                                            description={error}
                                            type="error"
                                            style={{marginBottom: '10px'}}
                                        /> }
                                        <Field
                                            name='newPassword'
                                            placeholder={i18next.t('reset_page.new_pass')}
                                            label={i18next.t('reset_page.new_pass')}
                                            component={textField}
                                            type='password'
                                        />
                                        <Field
                                            name='confirmPassword'
                                            placeholder={i18next.t('reset_page.confirm_pass')}
                                            label={i18next.t('reset_page.confirm_pass')}
                                            component={textField}
                                            type='password'
                                        />
                                        <button type="submit" className="field-content__button">{i18next.t('reset_page.update_pass')}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
};

ResetPasswordPage = connect(null, {postNewPassword}) (ResetPasswordPage);

export default reduxForm({
    form: 'resetPassword',
    validate
}) (ResetPasswordPage);