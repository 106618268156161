import React, { Component } from 'react';
import Header from './Header';
import Footer from '../components/Footer';
import {Switch, Route, BrowserRouter, Redirect} from 'react-router-dom';
import HomePage from './HomePage';
import ShopPage from './ProductsPage';
import ArticlePage from './ArticlePage';
import CartPage from './cartPage/CartPage';
import CheckoutPage from './checkoutPage/checkoutPage';
import CreateAccountPage from './createAccountPage/CreateAccountPage';
import AccountPage from './accountPage/AccountPage';
import LoginPage from './LoginPage';
import TermsPage from '../components/TermsPage';
import ContactPage from '../components/ContactPage';
import ResetPasswordPage from '../components/ResetPasswordPage';
import store from './../store/store'
import {connect} from "react-redux";
import {loginRequest, refreshToken, checkLogin, getShippingCost, requiredInvoiceAddress, getArticleCategories, getCampaigns, getTenantData, showCustomerTypeModal, getTemporaryCustomer} from "../actions/actions";
import Drawer from './Drawer';
import NotFoundPage from '../components/NotFoundPage';
import CookieInfoPage from '../components/CookieInfoPage';
import AboutPage from '../components/AboutPage';
import ThankYouPage from './checkoutPage/ThankYouPage';
import SearchResults from './SearchResults';
import FAQPage from '../components/FAQPage';
import SelectStorePage from '../components/SelectStorePage';
import CustomerTypeModal from './CustomerTypeModal';
import CustomerSettingsModal from './accountPage/CustomerSettingsModal';
import { GA_CODE, GA_CODE_DOMAIN, GA_CODE_DOMAIN_UAT, GA_CODE_UAT } from '../config/config';
import ReactGA from "react-ga";


if (window.location.hostname === GA_CODE_DOMAIN && localStorage.getItem('ga-accepted')) {
    ReactGA.initialize(GA_CODE);
}

if ((window.location.hostname === GA_CODE_DOMAIN_UAT) && localStorage.getItem('ga-accepted')) {
    ReactGA.initialize(GA_CODE_UAT);
}

class App extends Component {
    constructor(props) {
        super(props);
        this.loginCheck = this.loginCheck.bind(this);
        this.getShippingCost = this.getShippingCost.bind(this);
        this.prevCart = {};
    }

    loginCheck() {
        //setInterval(this.props.checkLogin, 10*1000);
    }


    componentDidMount() {
        this.props.checkLogin()
    }

    componentDidUpdate(prevProps, props) {
        if( (this.props.publicTenant) || (!this.props.publicTenant && this.props.loggedIn) ) {
            if(prevProps.isProcessing === true && this.props.isProcessing === false) {
               this.props.getCampaigns();
               this.props.getArticleCategories();
               this.getShippingCost();
            }
        }

        if(prevProps.isProcessing === true && this.props.isProcessing === false) {
            this.props.getTenantData();
        }


        if (this.props.cart.itemsInCart) {
            if (prevProps.cart.items !== this.props.cart.items) {
               this.getShippingCost();
            }
        }
    }

    componentWillUnmount() {
        store.subscribe(this.getShippingCost);
        clearInterval(this.loginCheck())
    }

    getShippingCost() {
        let newCart = store.getState().cart;
        if(newCart.itemsInCart !== this.prevCart.itemsInCart || newCart.total !== this.prevCart.total) {
            if(newCart.itemsInCart !== this.prevCart.itemsInCart) {
                this.props.requiredInvoiceAddress()
            }
            this.prevCart = newCart
            this.props.getShippingCost(newCart)
        }
    }

    renderStart() {
        //Public store
        if(this.props.publicTenant) {
            if (this.props.loggedIn) {
                return (
                    <div className={this.props.tenantId == 8 ? "wrapper synlab" : "wrapper"}>
                        <Drawer />
                        <Header />
                        <Switch>
                            <Route path='/404' exact component={NotFoundPage} />
                            <Route path='/account' component={AccountPage} />
                            <Route path='/checkout' exact component={CheckoutPage} />
                            <Route path='/cart' exact component={CartPage} />
                            <Route path='/about' exact component={AboutPage} />
                            <Route path='/results' exact component={SearchResults} />
                            <Route path='/cookieinformation' exact component={CookieInfoPage} />
                            <Route path='/terms' exact component={TermsPage} />
                            <Route path='/contact' exact component={ContactPage} />
                            <Route path='/article/:id' exact component={ArticlePage} />
                            <Route path='/shop' component={ShopPage} />
                            <Route path='/resetPassword/:t' exact component={ResetPasswordPage} />
                            <Route path='/checkout/summary/:order' component={ThankYouPage} />
                            {
                                this.props.tenantId === 5  ? (
                                    <Route path='/faq' exact component={FAQPage} />
                                ) : null
                            }
                            <Route path='/' exact component={HomePage} />
                            <Route component={NotFoundPage} />
                        </Switch>
                        <Footer />
                        <CustomerSettingsModal/>
                    </div>
                )
            } else {
                return (
                    <div className={this.props.tenantId == 8 ? "wrapper synlab" : "wrapper"}>
                        <Drawer />
                        <Header />
                        <Switch>
                            <Route path='/404' exact component={NotFoundPage} />
                            <Route path='/account/new' exact component={CreateAccountPage} />
                            <Route path='/account' component={LoginPage} />
                            {
                                this.props.tenantId !== 5 ? <Route path='/checkout' exact component={CheckoutPage} /> : null
                            }
                            <Route path='/login' exact render={() => (!this.props.loggedIn ? <LoginPage/> : <Redirect to="/"/>)} />
                            <Route path='/cart' exact component={CartPage} />
                            <Route path='/about' exact component={AboutPage} />
                            <Route path='/results' exact component={SearchResults} />
                            <Route path='/cookieinformation' exact component={CookieInfoPage} />
                            <Route path='/terms' exact component={TermsPage} />
                            <Route path='/contact' exact component={ContactPage} />
                            <Route path='/article/:id' exact component={ArticlePage} />
                            <Route path='/shop' component={ShopPage} />
                            <Route path='/resetPassword/:t' exact component={ResetPasswordPage} />
                            <Route path='/checkout/summary/:order' component={ThankYouPage} />
                            <Route path='/' exact component={HomePage} />
                            {
                                this.props.tenantId === 5  ? (
                                    <Route path='/faq' exact component={FAQPage} />
                                ) : null
                            }
                            <Route component={NotFoundPage} />
                        </Switch>
                        <Footer />
                        {this.props.businessPrivateToggle &&
                            <CustomerTypeModal/>
                        }
                    </div>
                )
            }
        }
        //Loomis store
        else {
            if (this.props.loggedIn) {
                if(this.props.showStoreList) {
                    return (
                        <div className="wrapper">
                            <Header />
                            <Switch>
                                <Route path='/' component={SelectStorePage} />
                            </Switch>
                            <Footer />
                        </div>
                    )
                } else {
                    return  (
                        <div className="wrapper">
                            <Drawer />
                            <Header />
                            <Switch>
                                <Route path='/404' exact component={NotFoundPage} />
                                <Route path='/checkout/summary/:order' exact component={ThankYouPage} />
                                <Route path='/account' component={AccountPage} />
                                <Route path='/checkout' exact component={CheckoutPage} />
                                <Route path='/cart' exact component={CartPage} />
                                <Route path='/about' exact component={AboutPage} />
                                <Route path='/faq' exact component={FAQPage} />
                                <Route path='/results' exact component={SearchResults} />
                                <Route path='/cookieinformation' exact component={CookieInfoPage} />
                                <Route path='/article/:id' exact component={ArticlePage} />
                                <Route path='/shop' component={ShopPage} />
                                <Route path='/resetPassword/:t' exact component={ResetPasswordPage} />
                                <Route path='/' exact component={HomePage} />
                                <Route component={NotFoundPage} />
                            </Switch>
                            <Footer />
                            <CustomerSettingsModal/>
                        </div>
                    )
                }
            } else {
                return (
                    <div className="wrapper">
                        <Header />
                        <Switch>
                            <Route path='/cookieinformation' exact component={CookieInfoPage} />
                            <Route path='/account/new' component={CreateAccountPage} />
                            <Route path='/resetPassword/:t' exact component={ResetPasswordPage} />
                            <Route path='/' component={LoginPage} />
                        </Switch>
                        <Footer />
                        {this.props.businessPrivateToggle &&
                            <CustomerTypeModal/>
                        }
                    </div>
                )
            }
        }
    }

    render() {
        // if (localStorage.getItem('user') && !this.props.loggedIn) {
        //     this.props.checkLogin();
        // }

        return (
            <BrowserRouter>
                {this.renderStart()}
            </BrowserRouter>
        )
    }
}
function mapStateToProps(state) {
    const {loggedIn, showStoreList, tenant, publicTenant, tenantId, currency, businessPrivateToggle, isProcessing} = state.auth;

    return {
        loggedIn,
        showStoreList,
        tenant,
        publicTenant,
        tenantId,
        currency,
        businessPrivateToggle,
        isProcessing,
        cart: state.cart
    }
}

export default connect(mapStateToProps, {loginRequest, refreshToken, checkLogin, getShippingCost, requiredInvoiceAddress, getArticleCategories, getCampaigns, getTenantData, showCustomerTypeModal})(App);
