import {
    LOGIN,
    LOGOUT,
    LOGIN_REQUEST,
    REFRESH_TOKEN,
    SHOW_FORGOTTEN_PASS_MODAL,
    HIDE_FORGOTTEN_PASS_MODAL,
    STORE_SELECTED,
    CHECK_STORES,
    GET_TENANT_DATA,
    LOGIN_CHECK,
    LOGIN_ERROR
} from "../actions/actions";
import { initial } from "lodash";


const initialState = {
    guest: true,
    loggedIn: false,
    isProcessing: false,
    showModal: false,
    showStoreList: false,
    tenant: "",
    publicTenant: false,
    tenantId: 0,
    currency: "",
    vat: "",
    businessPrivateToggle: false,
    adminOrderFormType: false
}

if(process.env.REACT_APP_TENANTID) {
    initialState.tenantId = parseInt(process.env.REACT_APP_TENANTID.trim())
    // if(initialState.tenantId === 4) {
    //     initialState.currency = 'eur'
    // }
    if(process.env.REACT_APP_PUBLIC) {
        initialState.publicTenant = (process.env.REACT_APP_PUBLIC.trim() === "true")
    }
} else if( window.location.hostname.includes('estore.loomis.se') ){
    initialState.publicTenant = true
    initialState.tenantId = 4
    initialState.currency = 'kr'
} else if( window.location.hostname.includes('material.loomis.se') || window.location.hostname.includes('loomis.wapabutiken.se') || window.location.hostname.includes('loomis-estore')) {
    initialState.publicTenant = false
    initialState.tenantId = 3
    initialState.currency = 'kr'
} else if( window.location.hostname.includes('estore.loomis.fi') ){
    initialState.publicTenant = true
    initialState.tenantId = 5
    initialState.currency = 'eur'
} else if( window.location.hostname.includes('estore.loomis.co.uk')) {
    initialState.publicTenant = true
    initialState.tenantId = 6
    initialState.currency = 'gbp'
} else if ( window.location.hostname.includes('estore.loomis.es') ) {
    initialState.publicTenant = true
    initialState.tenantId = 7
} else if ( window.location.hostname.includes('estore.synlab.se') ) {
    initialState.publicTenant = true
    initialState.tenantId = 8
    initialState.currency = 'eur'
} else if (window.location.hostname.includes('d3gbvanxgxz5uz.cloudfront.net') || window.location.hostname.includes('estore.loomis.dk')) {
    initialState.publicTenant = true
    initialState.tenantId = 9
    initialState.currency = 'kr'
}
export default function (state = initialState, action) {
    switch(action.type) {
        case GET_TENANT_DATA:
            return {...state,
                currency: action.payload.currency,
                vat: action.payload.vat,
                businessPrivateToggle: action.payload.businessPrivateToggle,
                publicTenant: action.payload.publicTenant,
                adminOrderFormType: action.payload.adminOrderFormType
            };
        case LOGIN_REQUEST:
            return {...state,
                loggingIn: true,
                guest: false
            };
        case LOGIN:
            return {...state,
                user: action.user,
                loggedIn: true,
                guest: false,
                isProcessing: false
            }
        case LOGIN_CHECK:
            return {...state,
                isProcessing: true
            }
        case CHECK_STORES:
            return {...state,
                showStoreList: action.hasStores || action.hasStores === 'undefined'
            }
        case LOGOUT:
            return {...state,
                user: {},
                loggedIn: false,
                guest: true,
                isProcessing: false
            }
        case LOGIN_ERROR:
            return {
                ...state,
                isProcessing: false
            }
        case REFRESH_TOKEN:
            return {...state,
                user: action.user,
                loggedIn: true,
                guest: false
            };
        case SHOW_FORGOTTEN_PASS_MODAL:
            return { ...state,
                showModal: true
            }
        case HIDE_FORGOTTEN_PASS_MODAL:
            return { ...state,
                showModal: false
            }
        case STORE_SELECTED:
            return {...state,
                showStoreList: false}
        default:
            return state
    }
}
