import {
    ADD_ARTICLES,
    ADD_IMAGES,
    ADD_PRODUCT,
    ADD_RELATED,
    GET_PRODUCTS,
    LOGOUT
} from "../actions/actions";
import _ from 'lodash';

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PRODUCTS:
            return _.mapKeys(action.products, 'id');
        case ADD_PRODUCT:
            return {...state, [action.product.id]: action.product}
        case ADD_ARTICLES:
            return {
                ...state,
                [action.containerId]: {
                    ...state[action.containerId],
                    articles: action.articles.sort( (a,b) => {
                        return parseInt(a.quantity, 10) - parseInt(b.quantity, 10)
                    })
                }
            }
        case ADD_IMAGES:
            return {
                ...state,
                [action.containerId]: {
                    ...state[action.containerId],
                    images: action.images
                }
            }
        case ADD_RELATED:
            return {
                ...state,
                [action.containerId]: {
                    ...state[action.containerId],
                    related: action.related
                }
            }
         // Commented because by logout products not displayed
        // case LOGOUT:
        //     return initialState
        default:
            return state
    }
}