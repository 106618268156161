import React from 'react';

const SectionHeader = (props) => (
    <div className="section-header">
        <div className="c">
            <h3 className="section-header__caption">{props.caption}</h3>
            <h2 className="section-header__title">{props.title}</h2>
        </div>
    </div>
);
export default SectionHeader;