import React from 'react';
import {Field} from "redux-form";
import {radioButton} from "../../components/form/formFields";
import {connect} from "react-redux";
import {addPremiumDelivery, removePremiumDelivery} from "../../actions/actions";
import i18next from 'i18next';
import {Divider} from 'antd';
import {withRouter} from "react-router-dom";

const Shipping = (props) => {
    const regexTest = new RegExp("\\[" + props.language + "\\].*?\"([\\S\\s]*?)\"")

    const matchName = regexTest.exec(props.deliveryName);
    const matchDescription = regexTest.exec(props.deliveryDescription);

    const translatedName = (matchName && matchName.length > 1) ? matchName[1] : props.deliveryName;
    const translatedDescription = (matchDescription && matchDescription.length > 1) ? matchDescription[1] : props.deliveryDescription;

    function renderPremium() {
        if(props.premiumDelivery === false) {
            return null
        } else {
            return (
                <div className="checkout-step-content-wrapper">
                    <Field
                        name='delivery'
                        label='Premium delivery'
                        id='radio201'
                        component={radioButton}
                        checked={props.premiumSelected}
                        description={`${i18next.t('checkout_page.premium_shipping')} - ${props.premiumDelivery} ${props.currency} `}
                        onClick={props.addPremiumDelivery}
                        value="premium"
                    />
                </div>
            )
        }
    }
    return (
        <div className="checkout-step checkout-step--shipping">
            <div className="checkout-step-header">
                <h2 className="checkout-step-header__title">3. {i18next.t('checkout_page.shipping_method')}</h2>
            </div>
            <div className="checkout-step-content">
                <h3 className="checkout-step-content__subtitle">{i18next.t('checkout_page.shipping_description')}</h3>

                <div className="checkout-step-content-wrapper">
                    <Field
                        name='delivery'
                        label='Normal delivery'
                        id='radio200'
                        component={radioButton}
                        checked={!props.premiumSelected}
                        description={`${i18next.t('checkout_page.normal_shipping')} - ${props.delivery} ${props.currency}`}
                        value="normal"
                        onClick={props.removePremiumDelivery}
                    />
                    <Divider />
                    <p className="field-content__text">{translatedName}</p>
                    <p className="field-content__text">{translatedDescription}</p>
                </div>
                {props.tenantId !== 5 && renderPremium()}
            </div>
        </div>
    )
};
function mapStateToProps(state) {
    return {
        delivery: state.cart.delivery,
        premiumDelivery: state.cart.premiumDelivery,
        deliveryName: state.cart.deliveryName,
        deliveryDescription: state.cart.deliveryDescription,
        premiumSelected: state.cart.premiumSelected,
        language: state.language.language.language,
        tenantId: state.auth.tenantId
    }
}

export default withRouter(connect(mapStateToProps, {addPremiumDelivery, removePremiumDelivery})(Shipping));
