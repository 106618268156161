import React from 'react';
import { Link } from 'react-router-dom';
import i18next from 'i18next';

const Filter = (props) => {
    const renderPerPage = () => {
        const returnValue = []
        const allowedPerPage = [20, 40, 80]
        allowedPerPage.forEach(perPage => {
            if(props.perPage === perPage) {
                returnValue.push(<span className="product-view__size" key={perPage}>{perPage}</span>)
            } else {
                returnValue.push(<span className="product-view__size" onClick={() => props.changePerPage(perPage)} key={perPage}>
                    <Link to="#" className="product-view__link">{perPage}</Link>
                </span>)
            }
        })

        return returnValue
    }
    return (
    <div className="filter">
        <div className="filter__col-one">

            <i className="filter__select filter-icon icon-list" onClick={props.handleList} style={{width: "auto"}}>
                <svg className="filter-icon__svg" viewBox="0 0 20 20">
                    <use xlinkHref="#icon-list"/>
                </svg>
                <span className="filter-icon__text">{i18next.t('filter.list')}</span>
            </i>

            <i className="filter-icon icon-gallery" onClick={props.handleGallery}>
                <svg className="filter-icon__svg" viewBox="0 0 20 20">
                    <use xlinkHref="#icon-gallery"/>
                </svg>
                <span className="filter-icon__text">{i18next.t('filter.gallery')}</span>
            </i>
        </div>

        <div className="filter__col-two">
            <div className="product-view">
                {renderPerPage()}
            </div>

            <div className="filter-paging">
                <span className="filter-paging__text">
                    <b>{props.currentPage}</b> / <b>{props.pages}</b>
                </span>
            </div>
        </div>
    </div>
    )
};
export default Filter;