import React from 'react';
import _ from 'lodash';
import Slick from 'react-slick';

/**
 * @constructor
 * @return {Object}
 */
function importAll(r) {
    let images = {};
    r.keys().map( item => { images[item.replace('./', '')] = r(item); });
    return images;
}

/** Import all images from the folder. */
const loomisImages = importAll(require.context('../img/loomis-slider/', false, /\.(png|jpe?g)$/));
const publicImages = importAll(require.context('../img/public-slider/', false, /\.(png|jpe?g)$/));
const synlabImages = importAll(require.context('../img/synlab-slider/', false, /\.(png|jpe?g)$/));
const loomisFiImages = importAll(require.context('../img/loomis-fi-slider/', false, /\.(png|jpe?g)$/));

/** Component representing a home page slider.
 * To add/delete images in the slider just add or delete them in the appropriate folder
 * Loomis shop '../img/loomis-slider/'
 * Public shop '../img/public-slider/'
 * */
const HomeSlider = (props) => {
    let images = {};
    /** Slider settings. Check out all settings
 *  @see {@link https://react-slick.neostack.com/docs/api}
 * */
const sliderSettings = {
        className: 'hero-slider',
        dots: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        infinite: true,
        speed: 1000,
        slidesToShow: props.tenantId == 8 ? 1 : 3,
        slidesToScroll: props.tenantId == 8 ? 1 : 3,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 8000,
        responsive: [
            { breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    nextArrow: false,
                    prevArrow: false
                }
            }
        ]
    }

    if(!props.publicTenant) {
        images = loomisImages
    }else if (props.tenantId == 8){
        images = synlabImages
    }else if (props.tenantId == 5) {
        images = loomisFiImages
    }else {
        images = publicImages
    }
    return (
        <Slick {...sliderSettings}>
            {
                _.map( images, img => {
                    return (
                        <div style={{backgroundImage: `url(${img})`}} className="hero-image__slide" key={img}>
                            <div className="hero-image-content">&nbsp;</div>
                        </div>
                    )
                })
            }
        </Slick>
    )
}
export default HomeSlider;

/** Custom slider arrows */
function NextArrow(props) {
    return (
        <button className="next-thumb" onClick={props.onClick}>
            <svg className="prev-thumb__svg">
                <use xlinkHref="#arrow-right-circle"/>
            </svg>
        </button>
    );
}
function PrevArrow(props) {
    return (
        <button className="prev-thumb" onClick={props.onClick}>
            <svg className="prev-thumb__svg">
                <use xlinkHref="#arrow-left-circle"/>
            </svg>
        </button>
    );
}