import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import _ from 'lodash'
import {modifyAmountInCart, showSideCart, removeFromCart, getCampaigns} from "../actions/actions";
import {IMAGE_PATH, SERVER_URL} from "../config/config";
import { slide as DrawerWrapper } from 'react-burger-menu';
import i18next from 'i18next';
import ImagePlaceholder from '../img/placeholder.png';
import Campaigns from '../components/Campaigns';
import ReactHtmlParser from 'react-html-parser';


const Drawer = (props) => {
    function goToCheckout() {
        props.history.push('/checkout');
        props.showSideCart(false);
        window.scrollTo(0, 0);
    }

    function goToCart() {
        props.history.push('/cart');
        props.showSideCart(false);
    }

    function renderCheckoutButton() {
        if (!props.publicTenant) {
            return (
                <button
                    className="sidecart-content-action__button"
                    onClick={() => goToCheckout()}
                    style={{background: '#C8002A'}}
                >
                    {i18next.t('drawer.to_checkout')}
                </button>
            )
        } else {
            return (
                <button
                    className="sidecart-content-action__button"
                    onClick={() => goToCart()}
                    style={{background: '#C8002A'}}
                >
                    {i18next.t('drawer.to_cart')}
                </button>
            )
        }
    }

    function viewArticle(id) {
        props.showSideCart(false)
        props.history.push(`/article/${id}`)
    }

    function reduceAmountInCart(articleId, amount) {
        props.modifyAmountInCart(articleId, amount);
        if(amount < 1 ) {
            props.removeFromCart(articleId);
        }
    }

    function renderCartItems() {
        const regexTest = new RegExp("\\[" + props.language + "\\].*?\"([\\S\\s]*?)\"")
        const cartItems = _.map(props.cart, cartItem => {
            const matchName = regexTest.exec(cartItem.product.name)
            const matchDescription = regexTest.exec(cartItem.product.description)
            const notAlternativePrice = isNaN(parseFloat(cartItem.article.price))
            const translatedName = (matchName && matchName.length > 1) ? matchName[1] : cartItem.product.name
            const translatedDescription = ReactHtmlParser((matchDescription && matchDescription.length > 1) ? matchDescription[1] : cartItem.product.description);

            const source = () => {
                if(cartItem.product.popupImageFileName) {
                    return `${SERVER_URL}${IMAGE_PATH}${cartItem.product.popupImageFileName}`
                }
                else {
                    return `${ImagePlaceholder}`
                }
            };

            return (
                <div key={cartItem.article.id} className="product-table-item">

                    <div className="product-table-item__col-one">
                        <div className="product-table-item__inner">
                            <img className="product-table-item__img" src={source()} alt="" style={{width: '100%'}}/>
                        </div>
                    </div>

                    <div className="product-table-item__col-two">
                        <h2 className="product-table-item-title" onClick={() => viewArticle(cartItem.product.id)}>
                            <Link to="#" className="product-table-item-title__link">{translatedName}</Link>
                        </h2>
                        <p className="product-table-item-info">
                            <span className="product-table-item-info__value">{translatedDescription}</span>
                        </p>
                    </div>

                    <div className="product-table-item__col-three">
                        <div className="product-table-item__inner">
                            <span className="product-table-item-add-remove">
                                <svg className="product-table-item-add-remove__svg"
                                     onClick={() => reduceAmountInCart(cartItem.article.id, cartItem.amount - 1)}>
                                    <use xlinkHref="#minus-circle"/>
                                </svg>
                                <span className="product-table-item-add-remove__name">
                                     {cartItem.amount}
                                </span>
                                <svg className="product-table-item-add-remove__svg"
                                     onClick={() => props.modifyAmountInCart(cartItem.article.id, cartItem.amount + 1)}>
                                    <use xlinkHref="#plus-circle"/>
                                </svg>
                            </span>
                        </div>
                    </div>

                    <div className="product-table-item__col-four">
                        <div className="product-table-item__inner">
                            <p className="product-table-item__price">
                               {props.privateCustomerType ? cartItem.price * `1.${props.vat}` : cartItem.article.price} {notAlternativePrice ? null : props.currency}
                            </p>
                        </div>
                    </div>
                </div>
            )


        })
        return cartItems
    }
    return (
        <DrawerWrapper key={new Date().getTime()} right width={window.innerWidth > 767 ? '50%' : '85%' } isOpen={props.show} onStateChange={(state) => props.showSideCart(state.isOpen)} >
                <div className='custom-sidecart' >
                    <div className="sidecart__col-one"></div>
                    <div className="sidecart__col-two">
                        <div className="sidecart-header">
                            <h2>
                                <span className="sidecart-close" onClick={() => props.showSideCart(false)}>
                                    <svg className="sidecart-close__svg">
                                        <use xlinkHref="#close"/>
                                    </svg>
                                </span>
                                <span>{i18next.t('drawer.shopping_cart')}</span>
                            </h2>
                            <p className="sidecart-header__caption">
                                ({i18next.t('plurals.keyWithCount', {count: props.itemsInCart})})
                            </p>
                        </div>

                        <div className="sidecart-content">

                            <div className="product-table">

                                <div className="product-table-items">
                                    {renderCartItems()}
                                </div>

                                <div className="product-table-footer">
                                    {isNaN(parseFloat(props.total)) === false &&
                                        <p className="product-table-footer__text">
                                            {props.privateCustomerType ? i18next.t('drawer.your_shopping_cart') : i18next.t('drawer.your_cart_ex_vat') } <span className="product-table-footer__price">
                                                {props.privateCustomerType ? props.total * `1.${props.vat}` : props.total} {props.currency}
                                            </span>
                                        </p>
                                    }
                                </div>
                            </div>

                            <div className="sidecart-content-action">
                                {renderCheckoutButton()}
                            </div>
                            {
                                props.tenantId !== 5 ? (
                                    <div className="sidecart-campaigns" style={{marginTop: '3rem', textAlign: 'center'}}>
                                        <h3>{i18next.t(`homepage-${props.tenantId}.campaigns`)}</h3>
                                        <div className="sidecart-campaigns__wrapper">
                                            <Campaigns data={props.campaigns} onClick={() => props.showSideCart(false)} />
                                        </div>
                                    </div>
                                ) : null
                            }


                        </div>
                    </div>
                </div>
        </DrawerWrapper>
    );
};

function mapStateToProps(state) {
    return {
        show: state.cart.showSide,
        cart: state.cart.items,
        itemsInCart: state.cart.itemsInCart,
        total: state.cart.total,
        language: state.language.language.language,
        loggedIn: state.auth.loggedIn,
        campaigns: state.customer.campaigns,
        publicTenant: state.auth.publicTenant,
        tenantId: state.auth.tenantId,
        currency: state.auth.currency,
        vat: state.auth.vat,
        privateCustomerType: state.customer.privateCustomerType
    }
}

export default withRouter( connect(mapStateToProps, {showSideCart, modifyAmountInCart, removeFromCart, getCampaigns})(Drawer) );
