import {applyMiddleware, createStore} from "redux";
import rootReducer from "../reducers/reducers";
import thunk from "redux-thunk";
import {loadState, saveState} from '../reducers/localStorageState';
import throttle from 'lodash/throttle';

//Connection to Redux dev tool
const reduxDevTool = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

//Save Store to Local Storage
const persistedState = loadState();

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
const store = createStoreWithMiddleware(rootReducer, persistedState, reduxDevTool);
export default store;

//Track cart state changes in Local Storage
store.subscribe(throttle(() => {
    saveState({
        cart: store.getState().cart
    });
}, 1000));