import {
    GET_CUSTOMER_DATA,
    GET_EMAIL,
    GET_ORDER_ITEMS,
    GET_ORDERS,
    LOGOUT,
    GET_CAMPAIGNS,
    LOAD_MORE_ORDERS,
    ACCEPT_NEWSLETTER,
    GET_ARTICLE_CATEGORIES,
    GUEST_DELIVERY_ADDRESS,
    GUEST_INVOICE_ADDRESS,
    SET_CUSTOMER_TYPE,
    GET_TEMPORARY_CUSTOMER,
    BEFORE_REDIRECT_TO_SUMMARY
} from "../actions/actions";
import _ from 'lodash';

const initialState = {
    deliveryAddress: null,
    invoiceAddress: null,
    postalAddress: null,
    orders: [],
    limit: 3,
    email: '',
    allowNewsletter: false,
    payer: 'CUSTOMER',
    campaigns: {},
    articleCategories: [],
    articleGroups: [],
    defaultArticleGroup: '',
    corporateIdentityNumber : "",
    privateCustomerType: false,
    temporaryPassword: false,
    redirectedToSummary: false
}

export default function (state = initialState, action) {
    switch(action.type) {
        case GET_EMAIL:
            return {...state,
                email: action.email,
                temporaryPassword: action.temporaryPassword
            }
        case BEFORE_REDIRECT_TO_SUMMARY:
            return {...state,
                redirectedToSummary: action.payload
            }
        case SET_CUSTOMER_TYPE:
            return {...state,
                privateCustomerType: action.payload.privateCustomerType
            }

        case GET_TEMPORARY_CUSTOMER :
            return {
                ...state,
                temporaryPassword: action.temporaryPassword
            }
        case GET_CUSTOMER_DATA:
            if(!action.customer) {
                return state
            }
            let {invoiceAddress, deliveryAddress, postalAddress, articleGroups, payer, allowNewsletter, corporateIdentityNumber} = action.customer;
            if(!invoiceAddress) {
                invoiceAddress = {}
            }
            invoiceAddress.invoiceReference = action.customer.invoiceReference;
            invoiceAddress.emailInvoiceAddress = action.customer.emailInvoiceAddress;
            invoiceAddress.gln = action.customer.gln
            invoiceAddress.electronicInvoiceSystemAddress = action.customer.electronicInvoiceSystemAddress;
            invoiceAddress.electronicOperator =  action.customer.electronicOperator;
            return {
                ...state,
                deliveryAddress,
                postalAddress,
                invoiceAddress,
                articleGroups,
                payer,
                allowNewsletter,
                corporateIdentityNumber
            };

        case GET_ORDERS:
            const orders = action.orders.map(order => {
                return order
            })
            return {
                ...state,
                orders
            }
        case GET_ORDER_ITEMS:
            const updatedOrders = state.orders.slice()
            updatedOrders.some(order => {
                if(order.orderNumber === action.orderNumber) {
                    order.orderItems = action.orderItems
                    return true
                }
            })
            return {
                ...state,
                orders: updatedOrders

            }
        case GET_CAMPAIGNS:
            return {
                ...state,
                campaigns: _.mapKeys(action.campaigns, 'id')
            }
        case LOAD_MORE_ORDERS:
            return {
                ...state,
                limit: state.limit + 3
            }

        case ACCEPT_NEWSLETTER:
            return {
                ...state,
                newsletter: action.accept
            }
        case GET_ARTICLE_CATEGORIES:
            return {
                ...state,
                articleCategories: action.articleCategories,
                // Get all article groups from all categories and save them into one array
                articleGroups: _.flatten( _.map(action.articleCategories, category => category.articleGroups) ),
                // Set default article group - the first group of the first category
                defaultArticleGroup: _.get(_.head(action.articleCategories), 'articleGroups[0].id')
            }
        case LOGOUT:
            return {
                ...state,
                deliveryAddress: null,
                invoiceAddress: null,
                postalAddress: null,
                orders: [],
                limit: 3,
                email: '',
                allowNewsletter: false,
                payer: 'CUSTOMER'
            }
        case GUEST_DELIVERY_ADDRESS:
            return {
                ...state,
                deliveryAddress: action.addressData
            }
        case GUEST_INVOICE_ADDRESS:
            return {
                ...state,
                invoiceAddress: action.addressData
            }
        default:
            return state
    }
}
