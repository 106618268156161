import React from "react";
import {textField} from "../../components/form/formFields";
import {Field, reduxForm} from "redux-form";
import {Link} from "react-router-dom";
import {Alert} from "antd";
import i18next from 'i18next';
import {connect} from "react-redux";
import {changePassword} from "../../actions/actions";


const validate = (values) => {
    const errors = {}
    if(!values.currentPassword) {
        errors.currentPassword = i18next.t('errors.required')
    }
    if(!values.newPassword) {
        errors.newPassword = i18next.t('errors.required')
    }
    if(!values.confirmPassword) {
        errors.confirmPassword = i18next.t('errors.required')
    } else {
        if (values.confirmPassword !== values.newPassword) {
            errors.newPassword = i18next.t('errors.must_match')
            errors.confirmPassword = i18next.t('errors.must_match')
        }
        if (values.currentPassword === values.newPassword) {
            errors.newPassword = i18next.t('errors.identical')
        }
    }
    return errors
}

let ChangePassword = (props) => {
    function changePassword(values) {
        return props.changePassword(values.currentPassword, values.newPassword)
            .then( () => {
                props.reset();
            })
    }

    const {handleSubmit, error} = props;
    return (
        <React.Fragment>
            <h3 className="field-wrapper__title">{i18next.t("account.settings.change_password")}</h3>
            <form>
                { error && <Alert
                    message="Error"
                    description={error}
                    type="error"
                    style={{marginBottom: '10px'}}
                    showIcon
                /> }

                <Field
                    name='currentPassword'
                    label={i18next.t("account.settings.current_password")}
                    placeholder={i18next.t("account.settings.current_password")}
                    component={textField}
                    maxLength="50"
                    type="password"
                />
                <Field
                    name='newPassword'
                    label={i18next.t("account.settings.new_password")}
                    placeholder={i18next.t("account.settings.new_password")}
                    component={textField}
                    maxLength="50"
                    type="password"
                />
                <Field
                    name='confirmPassword'
                    label={i18next.t("account.settings.repeat_password")}
                    placeholder={i18next.t("account.settings.repeat_password")}
                    component={textField}
                    maxLength="50"
                    type="password"
                />
                <Link to="#" className="information-address__button link-button link-button--neg" onClick={handleSubmit(changePassword)}>
                    <span property="name" className="link-button__name link-button__name--neg">{i18next.t("account.settings.save")}</span>
                </Link>
            </form>
        </React.Fragment>
    )
}

ChangePassword = reduxForm({
    form: 'password',
    validate
})(ChangePassword);
export default connect(null, {changePassword})(ChangePassword)