import React from 'react';
import i18next from 'i18next';
import {connect} from "react-redux";
import {Modal, Button} from "antd";
import { showCustomerTypeModal, setCustomerType } from '../actions/actions';



class CustomerTypeModal extends React.Component  {
    constructor(props) {
        super(props)

        this.handleCustomerType = this.handleCustomerType.bind(this);
        this.cancelModal = this.cancelModal.bind(this)
    }

    componentDidMount() {
            if (localStorage.getItem('privateCustomerType') === null) {
                this.props.showCustomerTypeModal(true);
            } else {
                const privateLocalStorage = localStorage.getItem('privateCustomerType');
                this.props.setCustomerType(privateLocalStorage === "true" ? true : false);
            }
    }

    cancelModal = () => {
        this.props.showCustomerTypeModal(false);
    }

    handleCustomerType = (bool) => {
        this.props.setCustomerType(bool)
        this.cancelModal()
    }


    render() {
        return (
            <Modal
                title={i18next.t('customer.title')}
                className="type-modal"
                centered={true}
                visible={this.props.showModal}
                onCancel={() => this.cancelModal()}
                footer={[
                    <div style={{display: 'flex', justifyContent: 'space-around'}}>
                        <Button onClick={() => this.handleCustomerType(true)}>
                            {i18next.t('customer.private')}
                        </Button>
                        <Button onClick={() => this.handleCustomerType(false)} type="primary" style={{backgroundColor: this.props.tenantId == 8 ? '#0077AD' : '#C8002A', borderColor:'#0077AD' }}>
                            {i18next.t('customer.business')}
                        </Button>
                    </div>
                ]}
            />
        )
    }
};

function mapStateToProps(state) {
    return {
        businessPrivateToggle: state.auth.businessPrivateToggle,
        showModal: state.layout.showCustomerTypeModal,
        tenantId: state.auth.tenantId
    }
};

export default connect(mapStateToProps, {showCustomerTypeModal, setCustomerType})(CustomerTypeModal);