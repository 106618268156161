import React from 'react';
import i18next from 'i18next';



const BottomPromotions = (props) => {
    const ListItems = [
        i18next.t(`footer.bottom_promotions-${props.tenantId}.item_1`),
        i18next.t(`footer.bottom_promotions-${props.tenantId}.item_2`),
        i18next.t(`footer.bottom_promotions-${props.tenantId}.item_3`)
    ];

    return (
        <div className="bottom-promotion">
            <ul className="bottom-promotion-list">
                {ListItems.map((item, index) => {
                    return (
                        <li className="bottom-promotion-list__item" key={index}>
                            <svg className="bottom-promotion-list__svg">
                                <use xlinkHref="#checked"/>
                            </svg>
                            <span>{item}</span>
                        </li>
                    )
                })}
            </ul>
        </div>
    )};

export default BottomPromotions;