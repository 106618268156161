import React, {Component} from 'react';
import {Field, formValueSelector} from "redux-form";
import { LinkButton, LinkButtonEdit, LinkButtonSave } from '../../components/links/LinkButton';
import {checkBox, selectBox, textField, numberField} from "../../components/form/formFields";
import {connect} from "react-redux";
import {reduxForm} from "redux-form";
import {changeAddress} from '../../actions/actions';
import {withRouter} from "react-router-dom";
import store from "../../store/store";
import i18next from 'i18next';
import {Alert} from "antd";

const validate = (values) => {
    const errors ={}
    if(!values.invoiceRecipient) {
        errors.invoiceRecipient = 'Required'
    }
    if(!values.invoiceStreetName) {
        errors.invoiceStreetName = 'Required'
    }
    const state = store.getState()
    if(!values.invoiceZipCode) {
        errors.invoiceZipCode = 'Required'
    } else if(values.invoiceCountry && values.invoiceCountry.toLowerCase() === state.address.installationCountry.toLowerCase() && ( values.invoiceZipCode.length !== 5 || !/^\d+$/.test(values.invoiceZipCode) ) ) {
        errors.invoiceZipCode = 'Invalid'
    }
    if(!values.invoiceCity) {
        errors.invoiceCity = 'Required'
    }
    if(!values.invoiceCountry) {
        errors.invoiceCountry = 'Required'
    }
    return errors
}

class ChangeInvoiceAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false
        }
        this.changeInvoiceAddress = this.changeInvoiceAddress.bind(this)
        this.toggleEdit = this.toggleEdit.bind(this)
        this.renderButtons = this.renderButtons.bind(this)
        this.renderPostCode = this.renderPostCode.bind(this)
    }
    toggleEdit() {
        if(this.state.edit) {
            this.props.reset()
        }
        this.setState({edit: !this.state.edit})
    }
    changeInvoiceAddress(values) {
        this.toggleEdit();
        const invoiceAddress = {
                recipient: values.invoiceRecipient,
                streetName: values.invoiceStreetName,
                streetNumber: values.invoiceStreetNumber,
                streetLetter: values.invoiceStreetLetter,
                zipCode: values.invoiceZipCode,
                city: values.invoiceCity,
                country: values.invoiceCountry,

        };
        invoiceAddress.invoiceReference = values.invoiceReference
        invoiceAddress.gln = values.gln;
        invoiceAddress.electronicInvoiceSystemAddress = values.electronicInvoiceSystemAddress;
        invoiceAddress.electronicOperator = values.electronicOperator;
        if(!invoiceAddress.streetNumber) {
            invoiceAddress.streetNumber = null
        }
        if(values.includePDFInvoice) {
            invoiceAddress.emailInvoiceAddress = values.emailInvoiceAddress
        } else {
            invoiceAddress.emailInvoiceAddress = ""
        }
        this.props.reset();
        return this.props.changeAddress('invoiceAddress', invoiceAddress);

    }
    renderButtons() {
        if(this.state.edit) {
            return (
                <div>
                    <LinkButtonSave
                        title={i18next.t('account.information.save')}
                        extraClasses='save-button'/>
                    <LinkButton
                        path="#"
                        title={i18next.t('account.information.cancel')}
                        onClick={this.toggleEdit} />
                </div>
            )
        } else {
            return  <LinkButtonEdit
                    path='#'
                    title={i18next.t('account.information.edit')}
                    extraClasses='edit-button'
                    onClick={this.toggleEdit}
                />
        }
    }
    renderPostCode() {
        if(this.props.country) {
            return <Field
                name='invoiceZipCode'
                label={i18next.t('account.information.postcode')}
                placeholder={i18next.t('account.information.postcode')}
                component={textField}
                maxLength="50"
                type="text"
                extraClasses='field-content--25'
                readOnly = {!this.state.edit}
                autocomplete="off"
            />
        }
    }
    renderCityAndStreet() {
        if(!this.state.edit || this.props.city || this.props.country ) {
            return <React.Fragment>
                <Field
                    name='invoiceCity'
                    label={i18next.t('account.information.city')}
                    placeholder={i18next.t('account.information.city')}
                    component={textField}
                    maxLength="50"
                    type="text"
                    extraClasses='field-content--50'
                    readOnly = {!this.state.edit}
                />
                <Field
                    name='invoiceStreetName'
                    label={i18next.t('account.information.street_name')}
                    placeholder={i18next.t('account.information.street_name')}
                    component={textField}
                    maxLength="50"
                    type="text"
                    extraClasses='field-content--50'
                    readOnly = {!this.state.edit}
                />
                <Field
                    name='invoiceStreetNumber'
                    label={i18next.t('account.information.street_number')}
                    placeholder={i18next.t('account.information.street_number')}
                    component={numberField}
                    maxLength="50"
                    extraClasses='field-content--25'
                    readOnly = {!this.state.edit }
                />

                <Field
                    name='invoiceStreetLetter'
                    label={i18next.t('account.information.street_number_letter')}
                    placeholder={i18next.t('account.information.street_number_letter')}
                    component={textField}
                    maxLength="50"
                    type="text"
                    extraClasses='field-content--25'
                    readOnly = {!this.state.edit}
                />
            </React.Fragment>
        }
    }
    render() {
        const {handleSubmit, error} = this.props;
        return (
            <React.Fragment>
                <form onSubmit={handleSubmit(this.changeInvoiceAddress)}>
                    <div className="field-wrapper">
                        <h3 className="field-wrapper__title">{i18next.t('account.information.recipient')}</h3>
                        <Field
                            name='invoiceRecipient'
                            label={i18next.t('account.information.recipient')}
                            placeholder={i18next.t('account.information.recipient')}
                            component={textField}
                            maxLength="50"
                            type="text"
                            extraClasses='field-content--100'
                            readOnly = {!this.state.edit}
                        />
                    </div>
                    <div className="field-wrapper field-wrapper--margin-top">
                        <h3 className="field-wrapper__title">{i18next.t('account.information.address')}</h3>
                        <Field
                            name='invoiceCountry'
                            label={i18next.t('account.information.country')}
                            placeholder={i18next.t('account.information.country')}
                            component={selectBox}
                            options={this.props.countries}
                            extraClasses='field-content--25'
                            readOnly = {!this.state.edit || !this.props.publicTenant}
                        />
                        {this.renderPostCode()}
                        {this.renderCityAndStreet()}
                    </div>

                    <div className="field-wrapper field-wrapper--margin-top">
                        <h3 className="field-wrapper__title">{i18next.t('account.information.invoice_reference')}</h3>
                        <Field
                            name='invoiceReference'
                            label={i18next.t('account.information.invoice_reference')}
                            placeholder={i18next.t('account.information.invoice_reference')}
                            component={textField}
                            maxLength="50"
                            extraClasses='field-content--100'
                            type="text"
                            readOnly = {!this.state.edit}
                        />
                    </div>

                    <div className="field-wrapper field-wrapper--margin-top">
                        <h3 className="field-wrapper__title">{i18next.t('checkout_page.global_loc_number')}</h3>
                        <Field
                            name='gln'
                            label={i18next.t('checkout_page.global_loc_number')}
                            placeholder={i18next.t('checkout_page.global_loc_number')}
                            component={textField}
                            maxLength="50"
                            type="text"
                            extraClasses='field-content--100'
                            readOnly = {!this.state.edit}
                        />
                    </div>

                    <div className="field-wrapper field-wrapper--margin-top">

                        <Field
                            name='includePDFInvoice'
                            label={i18next.t('account.information.confirm_pdf')}
                            component={checkBox}
                            disabled={!this.state.edit}
                        />
                        <Field
                            name="emailInvoiceAddress"
                            label={i18next.t('account.information.email_invoice_address')}
                            placeholder={i18next.t('account.information.email_invoice_address')}
                            component={textField}
                            maxLength="50"
                            extraClasses='field-content--100'
                            type="email"
                            readOnly = {!this.state.edit || !this.props.modifyInvoiceEmail}
                        />
                        {this.props.tenantId === 5
                            && (
                                <>
                                    <div className="field-wrapper field-wrapper--margin-top">
                                        <h3 className="field-wrapper__title">{i18next.t('account.information.electronic_invoice_address')}</h3>
                                        <Field
                                            name="electronicInvoiceSystemAddress"
                                            label={i18next.t('account.information.electronic_invoice_address')}
                                            placeholder={i18next.t('account.information.electronic_invoice_address')}
                                            component={textField}
                                            maxLength="50"
                                            extraClasses='field-content--100'
                                            type="text"
                                            readOnly = {!this.state.edit}
                                        />
                                    </div>
                                    <div className="field-wrapper field-wrapper--margin-top">
                                        <h3 className="field-wrapper__title">{i18next.t('account.information.electronic_operator')}</h3>
                                        <Field
                                            name="electronicOperator"
                                            label={i18next.t('account.information.electronic_operator')}
                                            placeholder={i18next.t('account.information.electronic_operator')}
                                            component={textField}
                                            maxLength="50"
                                            extraClasses='field-content--100'
                                            type="text"
                                            readOnly = {!this.state.edit}
                                        />
                                    </div>
                                </>
                            )
                        }

                    </div>
                    { error && <Alert
                        message="Error"
                        description={error}
                        type="error"
                        style={{marginBottom: '10px'}}
                    /> }
                    {this.renderButtons()}
                </form>
            </React.Fragment>
        )
    }
}

const selector = formValueSelector('invoiceAddress')

function mapStateToProps(state) {
    let initialValues = {};
    if(state.customer.invoiceAddress) {
        const {invoiceAddress} = state.customer;
        initialValues = {
            ...initialValues,
            invoiceRecipient: invoiceAddress.recipient ? invoiceAddress.recipient : '',
            invoiceStreetName: invoiceAddress.streetName ? invoiceAddress.streetName : '',
            invoiceStreetNumber: invoiceAddress.streetNumber ? invoiceAddress.streetNumber : '',
            invoiceStreetLetter: invoiceAddress.streetLetter ? invoiceAddress.streetLetter : '',
            invoiceZipCode: invoiceAddress.zipCode ? invoiceAddress.zipCode : '',
            invoiceCity: invoiceAddress.city ? invoiceAddress.city : '',
            invoiceCountry: invoiceAddress.country ? invoiceAddress.country.replace(/Sverige/ig, 'Sweden') : 'Sweden',
            invoiceReference: invoiceAddress.invoiceReference ? invoiceAddress.invoiceReference : '',
            emailInvoiceAddress: invoiceAddress.emailInvoiceAddress ? invoiceAddress.emailInvoiceAddress : '',
            electronicInvoiceSystemAddress: invoiceAddress.electronicInvoiceSystemAddress ? invoiceAddress.electronicInvoiceSystemAddress : '',
            electronicOperator: invoiceAddress.electronicOperator ? invoiceAddress.electronicOperator : '',
            gln: invoiceAddress.gln ? invoiceAddress.gln: ''
        }
        if(initialValues.emailInvoiceAddress) {
            initialValues.includePDFInvoice = true
        }
    } else {
        initialValues = {
            ...initialValues
        }
    }


    return {
        initialValues,
        country: selector(state, 'invoiceCountry'),
        postCode: selector(state, 'invoiceZipCode'),
        streetName: selector(state, 'invoiceStreetName'),
        countries: state.address.countries,
        streets: state.address.streets,
        city: selector(state, 'invoiceCity'),
        modifyInvoiceEmail: selector(state, 'includePDFInvoice'),
        publicTenant: state.auth.publicTenant,
        tenantId: state.auth.tenantId
    }
}

ChangeInvoiceAddress = reduxForm({
    form: 'invoiceAddress',
    validate,
    enableReinitialize: true
})(ChangeInvoiceAddress);
export default withRouter( connect(mapStateToProps, {changeAddress})(ChangeInvoiceAddress) );
