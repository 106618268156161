import React from 'react';
import i18next from 'i18next';
import {connect} from "react-redux";

const NotFoundPage = (props) => {
    return (
        <main className="main main--no-padding typeset">
            <div className="section-block section-block--center">
                <div className="c">
                    <div className="section-block__content">
                        <h2>{i18next.t('not_found_page.title')}</h2>
                        <p className="preamble">
                            {i18next.t('not_found_page.description')} 
                            {props.tenantId !== 5 && <span className='service-link'>kundservice@loomis.se.</span>}
                        </p>
                    </div>
                </div>
            </div>
        </main>
    )
}

function mapStateToProps(state) {
    return {
        tenantId: state.auth.tenantId
    }
}

export default connect(mapStateToProps, null)(NotFoundPage);