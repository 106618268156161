import React, {Component} from 'react';
import i18next from 'i18next';
import BreadCrumbs from '../components/navigation/BreadCrumbs';
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import {getProducts} from "../actions/actions";
import _ from "lodash";
import Product from './Product';
import { Pagination } from 'antd';
import ApiConnector from "../api/apiConnection";

class SearchResults extends Component {
    constructor(props)  {
        super(props);
        this.state = {
            perPage: 20,
            currentPage: 1,
            total: 0
        };
        this.changePage = this.changePage.bind(this);
        this.changePerPage = this.changePerPage.bind(this);
        this.renderSearchResult = this.renderSearchResult.bind(this);
    }

    renderSearchResult() {
        const that = this;
        let filterProducts;
        filterProducts = _.filter(this.props.products, function(product) {
            // If search value equals null or undefined - return all products from this.props.products
            if (!product.name || !product.description) {
                filterProducts = this.props.products;
            }
            // Otherwise return filtered products
            return product.name.toLowerCase().includes(that.props.searchValue.toLowerCase()) || product.description.toLowerCase().includes(that.props.searchValue.toLowerCase()) || (product.articles.length > 0 ? product.articles[0].articleNumber.toLowerCase().includes(that.props.searchValue.toLowerCase()) : undefined) 
        });

        if (filterProducts.length < 1) {
            return (
                <h3>{i18next.t('search_results.nothing_found')}</h3>
            )
        } else {
            return (
                filterProducts.map(product => {
                        return (
                            <Product key={product.id}
                                     item={product}
                            />
                        )
                    }
                ))
        }
    }

    componentDidMount() {
        const that = this;
        ApiConnector.getTotal(null, this.props.searchValue )
            .then(response => {
                that.setState({
                    total: response.data
                })
            })
        this.props.getProducts(this.state.perPage, 0, null, this.props.searchValue );
        
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.searchValue !== this.props.searchValue) {
            this.props.getProducts(this.state.perPage, 0, null, nextProps.searchValue );
            const that = this;
            ApiConnector.getTotal(null, nextProps.searchValue )
                .then(response => {
                    that.setState({
                        total: response.data
                    })
                })
            this.setState({currentPage: 1})
        }
    }
    changePerPage(newPerPage) {
        this.props.getProducts(newPerPage, 0, null, this.props.searchValue );
        this.setState({perPage: newPerPage, currentPage: 1})
    }

    changePage(pageNumber) {
        const pages = Math.ceil(this.state.total/this.state.perPage)
        if(pageNumber < 1 || pageNumber > pages) {
            return null
        }
        this.props.getProducts(this.state.perPage, (pageNumber - 1)*this.state.perPage, null, this.props.searchValue );
        this.setState({currentPage: pageNumber})
    }

    render() {
        if(!this.props.products) {
            return null
        }
        return (
            <div className="wrapper">
                <BreadCrumbs pagename={i18next.t('search_results.page_name')} />
                <main className="main typeset">
                    <div className="page-header">
                        <div className="c">
                            <div className="page-header__col-one">
                                <h1 className="page-header__title">{i18next.t('search_results.page_name')}</h1>
                            </div>
                            <div className="page-header__col-two">
                                <p className="page-header__text">
                                    {i18next.t('search_results.results_for')} <b>{this.props.searchValue}</b>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="main-content">
                        <div className="c">
                            <div className="filter">
                                <div className="filter__col-one"></div>
                                <div className="filter__col-two"></div>
                            </div>

                            <div className="products products--gallery" itemScope itemType="http://schema.org/ItemList">
                                { this.renderSearchResult() }
                            </div>

                            <div className='pagination-wrapper'>
                                <Pagination
                                    total={this.state.total}
                                    pageSize={this.state.perPage}
                                    current={this.state.currentPage}
                                    defaultCurrent={1}
                                    onChange={this.changePage}
                                />
                            </div>

                        </div>
                    </div>
                </main>

            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        products: state.products,
        searchValue: state.searchValue
    }
}
export default withRouter(connect(mapStateToProps, {getProducts})(SearchResults));