import React from 'react';
import {connect} from "react-redux";
import BreadCrumbs from './navigation/BreadCrumbs';
import { Collapse } from 'antd';
import i18next from 'i18next';

const Terms = (props) => {
    return (
        <div className="wrapper">
            <BreadCrumbs pagename={i18next.t(`terms_page-${props.tenantId}.page_name`)} />

            <main className="main typeset">

                <div className="main-content main-content--padding-top">

                    <div className="c">
                        <Collapse  defaultActiveKey={['1']}>
                            <Collapse.Panel header={i18next.t(`terms_page-${props.tenantId}.contract`)} key="1">
                                <p>{i18next.t(`terms_page-${props.tenantId}.contact_desc`)}</p>
                            </Collapse.Panel>
                            <Collapse.Panel header={i18next.t(`terms_page-${props.tenantId}.prices`)} key="2">
                                <p>{i18next.t(`terms_page-${props.tenantId}.prices_desc`)}</p>
                            </Collapse.Panel>
                            <Collapse.Panel header={i18next.t(`terms_page-${props.tenantId}.delivery`)} key="3">
                                <p>{i18next.t(`terms_page-${props.tenantId}.delivery_desc`)}</p>
                            </Collapse.Panel>
                            <Collapse.Panel header={i18next.t(`terms_page-${props.tenantId}.returns`)} key="4">
                                <p>{i18next.t(`terms_page-${props.tenantId}.returns_desc`)}</p>
                            </Collapse.Panel>
                            <Collapse.Panel header={i18next.t(`terms_page-${props.tenantId}.betalning`)} key="5">
                                <p>{i18next.t(`terms_page-${props.tenantId}.betalning_desc`)}</p>
                            </Collapse.Panel>
                            <Collapse.Panel header={i18next.t(`terms_page-${props.tenantId}.complaints`)} key="6">
                                <p>{i18next.t(`terms_page-${props.tenantId}.complaints_desc`)}</p>
                            </Collapse.Panel>
                            <Collapse.Panel header={i18next.t(`terms_page-${props.tenantId}.conditions`)} key="7">
                                <p>{i18next.t(`terms_page-${props.tenantId}.conditions_desc_1`)}<a href='/cookieinformation'>{i18next.t(`terms_page-${props.tenantId}.conditions_desc_link`)}</a>{i18next.t(`terms_page-${props.tenantId}.conditions_desc_2`)}</p>
                            </Collapse.Panel>
                        </Collapse>

                    </div>
                </div>
            </main>
        </div>
    )
};

function mapStateToProps(state) {
    return {
        tenantId: state.auth.tenantId
    }
}
export default connect(mapStateToProps, null) (Terms);