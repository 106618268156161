import React from 'react';
import {Link} from 'react-router-dom';
import i18next from 'i18next';

const CreateAccount = (props) => {
    const {tenantId} = props;
    return(
            <div className="my-account-login__col-two">
                <h2 className="h5">{i18next.t('loginpage.signup_title')}</h2>
                <p className="caption">
                    {i18next.t('loginpage.signup_text')}
                </p>
                    <div className="my-account-login-action">
                        <div className="field-content">
                            <Link to='/account/new' className="field-content__button button__signup">{i18next.t('loginpage.signup_button')}</Link>
                        </div>
                        {
                            tenantId !== 5 ? (
                                <div className="field-content">
                                    <Link to='/checkout' className="field-content__button button__signup" style={{marginTop: '20px'}}>
                                        {i18next.t('loginpage.continue_as_guest')}
                                    </Link>
                                </div>
                            ) : null
                        }

                    </div>
                <h3 className="p bold">{i18next.t('loginpage.signup_description.title')}</h3>
                <ul className="list list--small">
                    <li className="list__item list__item--small">{i18next.t('loginpage.signup_description.list_item_1')}</li>
                    <li className="list__item list__item--small">{i18next.t('loginpage.signup_description.list_item_2')}</li>
                    <li className="list__item list__item--small">{i18next.t('loginpage.signup_description.list_item_3')}</li>
                </ul>
            </div>
    )
};
export default CreateAccount;
