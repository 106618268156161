import React, {Component} from 'react';
import {change, Field, formValueSelector} from "redux-form";
import {reduxForm} from "redux-form";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {selectBox, textField, numberField} from "../../components/form/formFields";
import {
    showDeliveryAddressModal,
    changeAddress,
    addGuestDeliveryAddress,
    SET_STREETS,
    getCountries
} from '../../actions/actions';
import ApiConnector from "../../api/apiConnection";
import store from '../../store/store';
import i18next from 'i18next';
import {Card, Alert, Button} from "antd";

const validate = (values) => {
    const errors = {};
    if(!values.deliveryRecipient) {
        errors.deliveryRecipient = i18next.t('errors.required')
    }
    if(!values.deliveryStreetName) {
        errors.deliveryStreetName = i18next.t('errors.required')
    }
    if(!values.deliveryZipCode) {
        errors.deliveryZipCode = i18next.t('errors.required')
    }
    if(!values.deliveryCity) {
        errors.deliveryCity = i18next.t('errors.required')
    }
    if(!values.deliveryCountry) {
        errors.deliveryCountry = i18next.t('errors.required')
    }
    return errors
};

const asyncValidate = (values, dispatch) => {
    const state = store.getState();
    const addressValidation = (selector(state, 'deliveryCountry') && selector(state, 'deliveryCountry').toLowerCase() === state.address.installationCountry.toLowerCase());
    if(addressValidation) {
        return ApiConnector.validateZipCode(values.deliveryZipCode)
            .then(response => {
                if (response.data === true) {
                    ApiConnector.getCity(values.deliveryZipCode)
                        .then(response => {
                            dispatch(change('deliveryAddress', 'deliveryCity', response.data))
                        })
                    ApiConnector.getStreetNames(values.deliveryZipCode)
                        .then(response => {
                            dispatch({
                                type: SET_STREETS,
                                streets: response.data
                            });
                            if(response.data && response.data.length > 0 && !values.deliveryStreetName) {
                                dispatch(change('deliveryAddress', 'deliveryStreetName', response.data[0]))
                            }
                        })
                }
                else {
                    dispatch(change('deliveryAddress', 'deliveryCity', ''))
                    dispatch({
                        type: SET_STREETS,
                        streets: []
                    })
                    throw {deliveryZipCode: 'Invalid'}
                }
                dispatch(change('deliveryAddress', 'deliveryStreetNumber', ''))
                dispatch(change('deliveryAddress', 'deliveryStreetLetter', ''))
            })
    } else {
        return Promise.resolve()
            .then(() => {
                if(!values.deliveryZipCode) {
                    throw {deliveryZipCode: 'Required'}
                }
            })
    }
};

class DeliveryAddressForm extends Component {
    constructor(props) {
        super(props);
        this.changeDeliveryAddress = this.changeDeliveryAddress.bind(this);
        this.renderPostCode = this.renderPostCode.bind(this);
        this.cancelModal = this.cancelModal.bind(this);
    }

    componentDidUpdate(prevProps, props) {
        if(prevProps.isProcessing === true && this.props.isProcessing === false) {
            this.props.getCountries();
        }
    }

    cancelModal() {
        this.props.reset();
        this.props.showDeliveryAddressModal();
    }

    async changeDeliveryAddress(values) {
        const deliveryAddress = {
            recipient: values.deliveryRecipient,
            streetName: values.deliveryStreetName,
            streetNumber: values.deliveryStreetNumber,
            streetLetter: values.deliveryStreetLetter,
            zipCode: values.deliveryZipCode,
            city: values.deliveryCity,
            country: values.deliveryCountry
        };
        if(!deliveryAddress.streetNumber) {
            deliveryAddress.streetNumber = null
        }
        // store.dispatch({
        //     type: SET_STREETS,
        //     streets: []
        // });
        const changeAddress = async () => {
            // If a guest user - save delivery address to the app state
            if (this.props.guest) {
                return this.props.addGuestDeliveryAddress(deliveryAddress);
            }
            // If a registered user - send delivery address to the backend
            return this.props.changeAddress('deliveryAddress', deliveryAddress);
        };
        await changeAddress();
        this.cancelModal();
    }

    renderPostCode() {
        if(this.props.country) {
            return <Field
                name='deliveryZipCode'
                label={`${i18next.t('account.information.postcode')}*`}
                placeholder={`${i18next.t('account.information.postcode')}*`}
                component={textField}
                maxLength="50"
                type="text"
                extraClasses='field-content--25'
                autocomplete="off"
            />
        }
    }

    renderCityAndStreet() {
        if(this.props.city || (this.props.country && !this.props.addressValidation)) {
            let options = this.props.streets;
            let streetComponent = selectBox;
            if( (this.props.streets.length === 0 && this.props.initialValues) || !this.props.addressValidation) {
                streetComponent = textField;
                options = [this.props.initialValues.deliveryStreetName]
            }
            return <React.Fragment>
                <Field
                    name='deliveryCity'
                    label={`${i18next.t('account.information.city')}*`}
                    placeholder={`${i18next.t('account.information.city')}*`}
                    component={textField}
                    maxLength="50"
                    type="text"
                    extraClasses='field-content--50'
                />
                <Field
                    name='deliveryStreetName'
                    label={`${i18next.t('account.information.street_name')}*`}
                    placeholder={`${i18next.t('account.information.street_name')}*`}
                    component={streetComponent}
                    options={options}
                    maxLength="50"
                    type="text"
                    extraClasses='field-content--50'
                />
                <Field
                    name='deliveryStreetNumber'
                    label={i18next.t('account.information.street_number')}
                    placeholder={i18next.t('account.information.street_number')}
                    component={numberField}
                    maxLength="25"
                    extraClasses='field-content--25'
                />

                <Field
                    name='deliveryStreetLetter'
                    label={i18next.t('account.information.street_number_letter')}
                    placeholder={i18next.t('account.information.street_number_letter')}
                    component={textField}
                    maxLength="50"
                    type="text"
                    extraClasses='field-content--25'
                />
            </React.Fragment>
        }
    }

    render() {
        const {handleSubmit, error} = this.props;
            return (
                <Card title={this.props.tenantId !== 5 ? i18next.t('checkout_page.delivery_address') : ""}>
                    <form onSubmit={handleSubmit(this.changeDeliveryAddress)} id="deliveryAddressModal">
                        <div className="field-wrapper">
                            <h3 className="field-wrapper__title">{i18next.t('account.information.recipient')}*</h3>
                            <Field
                                name='deliveryRecipient'
                                label={`${i18next.t('account.information.recipient')}*`}
                                placeholder={`${i18next.t('account.information.recipient')}*`}
                                component={textField}
                                maxLength="50"
                                type="text"
                                extraClasses='field-content--100'
                            />
                        </div>
                        <div className="field-wrapper">
                            <h3 className="field-wrapper__title">{i18next.t('account.information.address')}*</h3>
                            <Field
                                name='deliveryCountry'
                                label={`${i18next.t('account.information.country')}*`}
                                placeholder={`${i18next.t('account.information.country')}*`}
                                component={selectBox}
                                options={this.props.countries}
                                extraClasses='field-content--25'
                                readOnly = {!this.props.publicTenant}
                            />
                            {this.renderPostCode()}
                            {this.renderCityAndStreet()}
                        </div>
                        { error && <Alert
                            message="Error"
                            description={error}
                            type="error"
                            style={{marginTop: '10px'}}
                            showIcon
                        /> }
                         <Button key="back" onClick={this.cancelModal} style={{marginRight: 10}}>{i18next.t('checkout_page.cancel')}</Button>
                        <Button key="submit" type="primary" onClick={handleSubmit(this.changeDeliveryAddress)} >
                            {i18next.t('checkout_page.save')}
                        </Button>
                    </form>
                </Card>
            )
        }
    }


const selector = formValueSelector('deliveryAddress');
function mapStateToProps(state) {

    let initialValues = {};

    if(state.customer.deliveryAddress) {
        const {deliveryAddress} = state.customer;
        initialValues = {...initialValues,
            deliveryRecipient: deliveryAddress.recipient ? deliveryAddress.recipient : '',
            deliveryStreetName: deliveryAddress.streetName ? deliveryAddress.streetName : '',
            deliveryStreetNumber: deliveryAddress.streetNumber ? deliveryAddress.streetNumber : '',
            deliveryStreetLetter: deliveryAddress.streetLetter ? deliveryAddress.streetLetter : '',
            deliveryZipCode: deliveryAddress.zipCode ? deliveryAddress.zipCode : '',
            deliveryCity: deliveryAddress.city ? deliveryAddress.city : '',
            deliveryCountry: deliveryAddress.country ? deliveryAddress.country : ''
        }
    } else {
        initialValues = {
            ...initialValues,
            deliveryCountry: state.address.installationCountry
        }
    }
    const addressValidation = (selector(state, 'deliveryCountry') && selector(state, 'deliveryCountry').toLowerCase() === state.address.installationCountry.toLowerCase())
    return {
        initialValues,
        country: selector(state, 'deliveryCountry'),
        postCode: selector(state, 'deliveryZipCode'),
        streetName: selector(state, 'deliveryStreetName'),
        countries: state.address.countries,
        streets: state.address.streets,
        city: selector(state, 'deliveryCity'),
        addressValidation,
        showModal: state.layout.showDeliveryAddressModal,
        guest: state.auth.guest,
        publicTenant: state.auth.publicTenant,
        isProcessing: state.auth.isProcessing,
        tenantId: state.auth.tenantId
    }
}

DeliveryAddressForm = reduxForm({
    form: 'deliveryAddress',
    validate,
    asyncValidate,
    enableReinitialize: true,
    asyncChangeFields: ['deliveryZipCode']
})(DeliveryAddressForm);
export default withRouter( connect(mapStateToProps, {showDeliveryAddressModal, getCountries, changeAddress, addGuestDeliveryAddress})(DeliveryAddressForm) );
