import React, {useEffect, useState, useRef} from 'react';
import styles from './CookiesModal.module.scss'
import { ReactComponent as CloseIcon }  from  '../../../img/x.svg';
import { ReactComponent as CheckIcon }  from  '../../../img/check.svg';
import { ReactComponent as ArrowDown }  from  '../../../img/arrow-down.svg';
import i18next from 'i18next';
import { Switch } from 'antd';

const showMoreDefault = {
    necessary: false
}

const CookiesModal = props => {
    const {open, openModal, setAcceptedCookies} = props;
    const ref = useRef(null);
    const [page, setPage] = useState('description');
    const [showSave, setShowSave] = useState(false);
    const [accepted, setAccepted] = useState({
        description: null,
        necessary: true
    });
    const [showMore, setShowMore] = useState(showMoreDefault);

    const pages = {
        description: {
            title: `${i18next.t('cookie-modal.description-title')}`, 
            text: `${i18next.t('cookie-modal.description-text')}`,
            button: i18next.t('cookie-modal.description-button'),
            more: []
        },
        necessary:  {
            title: ``,
            text: i18next.t('cookie-modal.necessary-text'),
            button: i18next.t('cookie-modal.necessary-button'),
            more: [
                {
                    service: 'Loomis eStore',
                    purpose: 'Remember the current items in the cart',
                    link: '/cookieinformation',
                    expiry: 'never',
                    name: 'state',
                    vendor: 'estore.loomis.se'
                },
                {
                    service: 'Loomis eStore',
                    purpose: 'Indicates if the customer is private or business',
                    link: '/cookieinformation',
                    expiry: 'never',
                    name: 'privateCustomerType',
                    vendor: 'estore.loomis.se'
                }
            ]
        },
    }

    const renderIcon = (k) => {
        if (accepted[k] === null) {
            return;
        }
        if (accepted[k]) {
            return <CheckIcon style={{ color: 'green', marginLeft: 5 }} />;
        }

        return <CloseIcon style={{ color: 'red', marginLeft: 5 }} />;
    }

    return (
        <>

        <div className={`${styles.wrapper} ${open ? styles.open : ''}`}>
            <div className={`${styles.backdrop} ${open ? styles.open : ''}`}  />
            <div ref={ref} className={styles.modalScroll} onClick={(e) => {
                if (e.target === ref.current) {
                    openModal(false);
                }
            }}>
            <div className={styles.modal}>
                <div className={styles.closeWrapper}>
                    <button onClick={() => {
                        setPage("description")
                        openModal(false)}
                    }>
                        <CloseIcon />
                    </button>
                </div>
                <div className={styles.content}>
                    <div className={styles.buttons}>
                        {
                            Object.keys(pages).map(k => <button
                                onClick={() => {
                                    setPage(k);
                                    setShowMore({
                                        ...showMoreDefault,
                                    })
                                }}
                                className={`${k === page && styles.active}`}
                            >
                                {pages[k].button}
                                    {renderIcon(k)}

                            </button>
                            )
                        }
                    </div>
                    <div className={styles.text}>
                        <div className={styles.title}>
                            <span>{pages[page].button}</span>
                            <span>
                                {
                                    page !== 'description' && <Switch
                                        disabled={page === 'necessary'}
                                        checked={accepted[page]}
                                        onChange={(checked) => {
                                            setShowSave(true)
                                            setAccepted({
                                                ...accepted,
                                                [page]: checked
                                            });
                                        }}
                                    />
                                }

                            </span>
                        </div>
                        <>
                        <span style={{fontWeight: 'bold'}}>{pages[page].title}</span>
                        {pages[page].text}
                        </>
                        {
                            page === 'description' && <a href='/cookieinformation'>privacy notice</a>
                        }
                        {
                            page !== 'description' && <div className={styles.moreButtonWrapper}>
                                <button
                                    className={styles.moreButton}
                                    onClick={() => {
                                        setShowMore({
                                            ...showMore,
                                            [page]: !showMore[page]
                                        })
                                    }}
                                >
                                    Show more <ArrowDown style={{marginLeft: 5}} />
                                </button>
                            </div>
                        }
                        <div style={{display: showMore[page] ? 'block': 'none'}}>
                            {
                                pages[page].more.map(item => {
                                    return (
                                        <div style={{marginBottom: 20}}>
                                            {Object.keys(item).map(k => {
                                                return <div className={styles.moreRow}>
                                                    <span className={styles.moreTitle}>{i18next.t('cookie-modal.'+k)}</span>
                                                    <span>{
                                                        k === 'link' ? <a href={item[k]}>{i18next.t('cookie-modal.privacy')}</a>:
                                                        item[k]
                                                    }</span>
                                                </div>
                                            })}

                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.footer}>
                    {/* {
                        showSave ?  <button className={styles.outlined} onClick={onSave}>{i18next.t('cookie-modal.save')}</button> :  <button className={styles.outlined} onClick={onDecline}>{i18next.t('cookie-modal.decline')}</button>
                    }
                    <button onClick={onAccept}>{i18next.t('cookie-modal.accept')}</button> */}
                </div>
            </div>
            </div>
        </div>
        </>
    );
};

export default CookiesModal;
