import React from 'react';
import { Link } from 'react-router-dom';
import i18next from 'i18next';

const BreadCrumbs = (props) => (
    <div className="breadcrumbs">
        <div className="c">
            <span className="breadcrumb">
				<Link to="/" className="breadcrumb__link">
					<span className="breadcrumb__name">{i18next.t('breadcrumbs.home')}</span>
				</Link>
			</span>
            <i className="breadcrumb-divider">/</i>
            <span className="breadcrumb">
				<span className="breadcrumb__name">{props.pagename}</span>
			</span>
        </div>
    </div>
);
export default BreadCrumbs;