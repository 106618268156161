import React, {useState, useEffect} from 'react';
import CookiesModal from './CookiesModal/CookiesModal';
import CookiesNotice from './CookiesNotice/CookiesNotice';

const Cookies = (props) => {
    const {openModal, setOpenModal} = props;
    const [accepted, setAccepted] = useState(true);

    useEffect(() => {
        document.cookie = "accepts-cookies=1; max-age=15780000";
    }, []);

    return (
        <>
            <CookiesModal openModal={setOpenModal} open={openModal} setAcceptedCookies={setAccepted} />
            {!localStorage.getItem('closeCookieModal') &&
                <CookiesNotice openModal={setOpenModal} accepted={accepted} setAccepted={setAccepted} />
            }
        </>
    );
};

export default Cookies;
