import React, { Component } from 'react';
import i18next from 'i18next';
import { NavLink, Link, withRouter } from 'react-router-dom';
import SearchForm from './SearchForm';
import Logo from '../img/logo.jpg';
import LogoSynLab from '../img/synlab-logo.png';
import LogoLoomis from '../img/Loomis.png';
import {connect} from "react-redux";
import MobileMenu from './MobileMenu';
import ChangeCustomerType from "./ChangeCustomerType";
import {logout, changeLanguage, showMobileMenu, getArticleCategories, setCustomerType} from "../actions/actions";
import ReactGA from 'react-ga';
import {GA_CODE_DOMAIN} from "../config/config";

/** Component representing the main navigation header */
export class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: 'sv'
        };
        this.renderLogout = this.renderLogout.bind(this);
        this.setLanguage = this.setLanguage.bind(this);
        this.languageExists = this.languageExists.bind(this);
    }

    componentDidMount() {
        if (window.location.hostname === GA_CODE_DOMAIN) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        if (this.props.tenantId === 5) {
            this.setLanguage('fi')
        }
        if (this.props.tenantId === 9) {
            this.setLanguage('dk')
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location && prevProps.location) {
            if ((this.props.location.pathname !== prevProps.location.pathname) && window.location.hostname === GA_CODE_DOMAIN) {
                ReactGA.pageview(window.location.pathname + window.location.search);
            }
        }

    }

    /** Set Swedish language by default */
    componentWillMount(){
        const browserLanguage = navigator.language
        let appLanguage = 'sv'
        let language = browserLanguage
        if(browserLanguage.includes("-")) {
            language = browserLanguage.split("-")[0]
        }

        if(this.languageExists(language)) {
            appLanguage = language
        }
        this.setLanguage(appLanguage);
        if(!this.props.isProcessing && this.props.publicTenant) {
            this.props.getArticleCategories();
        }
    }
    languageExists(language) {
        try {
            require(`../lang/${language}.json`)
            return true
        } catch (err) {
            return false
        }
    }

    /** Switch the languages
     * @params {string} language - The current language
     */
    setLanguage(language) {
        i18next.init({
            lng: language,
            resources: require(`../lang/${language}.json`)
        });
        this.props.changeLanguage(i18next);
        this.setState({
            active: language
        })
    }

    renderCustomerType() {
        if(this.props.businessPrivateToggle) {
            return <ChangeCustomerType {...this.props}/>
        } else return;
    }

    renderLogout() {
        /** Render login/logout link for Public shop */
        if(this.props.publicTenant) {
            if(this.props.loggedIn) {
                return (
                    <li className="help-nav-list__item disableOnMobile" onClick={this.props.logout}>
                        <Link to="#" className="help-nav-list__link">
                            <span className="help-nav-list__text" >{i18next.t('header.logout')}</span>
                        </Link>
                    </li>
                )
            } else {
                return (
                    <li className="help-nav-list__item disableOnMobile" onClick={() => this.props.history.push('/login')}>
                        <Link to="/login" className="help-nav-list__link">
                            <span className="help-nav-list__text" >{i18next.t('header.login')}</span>
                        </Link>
                    </li>
                )
            }
        }
        /** Render login/logout link for Loomis shop */
        else if(!this.props.publicTenant) {
            if(this.props.loggedIn) {
                return (
                    <li className="help-nav-list__item disableOnMobile" onClick={this.props.logout}>
                        <Link to="#" className="help-nav-list__link">
                            <span className="help-nav-list__text" >{i18next.t('header.logout')}</span>
                        </Link>
                    </li>
                )
            } else {
                return (
                    <li className="help-nav-list__item disableOnMobile" onClick={() => this.props.history.push('/')}>
                        <Link to="/" className="help-nav-list__link">
                            <span className="help-nav-list__text" >{i18next.t('header.login')}</span>
                        </Link>
                    </li>
                )
            }
        }
    }
    /** Render account link if user is loggedIn */
    renderAccount() {
        if(this.props.loggedIn) {
            return (
                <li className="help-nav-list__item">
                    <Link to="/account" className="help-nav-list__link">
                        <i className="help-nav-list-icon">
                            <svg className="help-nav-list-icon__svg" viewBox="0 0 20 20">
                                <use xlinkHref="#user"/>
                            </svg>
                        </i>
                        <span className="help-nav-list__text">
							{i18next.t('header.account')}
						</span>
                    </Link>
                </li>
            )
        } else {
            return null
        }
    }

    renderShops() {
            return (
                <nav className="main-nav">
                    <ul className="main-nav-list">
                        <li className="main-nav-list__item">
                            <NavLink to="/" exact className="main-nav-list__link">
                                {i18next.t('breadcrumbs.home')}
                            </NavLink>
                        </li>
                        <li className="main-nav-list__item" style={{position: 'relative'}}>
                            <NavLink to={`/shop/${this.props.defaultArticleGroup}`} className="main-nav-list__link" >
                                {i18next.t(`header.shop_name-${this.props.tenantId}`)}
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            )
        }

    render() {
        return (
            <header className="header">
                <div className="header__top">
                    <div className="c">
                        <div className="logo">
                            <Link to="/" className="logo__link">
                                <img  src={this.props.tenantId == 8 ? LogoSynLab : (this.props.tenantId === 5 ? LogoLoomis : Logo)} alt='logo' className="logo__img" style={{width: '150px'}} />
                            </Link>
                        </div>

                        <div className="header-nav">
                            <nav className="help-nav">
                                <ul className="help-nav-list">

                                    <li className="help-nav-list__item disableOnMobile">
                                        {
                                            this.props.tenantId !== 5 && this.props.tenantId !== 9
                                                ? (
                                                    <div className="help-nav-list__item-col">
                                                        <div className="language-list">
                                                            <Link to="#" className={this.state.active === 'sv' ? 'active' : ''} onClick={() => this.setLanguage('sv')}>SV</Link>
                                                            <Link to="#" className={this.state.active === 'en' ? 'active' : ''} onClick={() => this.setLanguage('en')}>EN</Link>
                                                            <Link to="#" className={this.state.active === 'no' ? 'active' : ''} onClick={() => this.setLanguage('no')}>NO</Link>
                                                            <Link to="#" className={this.state.active === 'es' ? 'active' : ''} onClick={() => this.setLanguage('es')}>ES</Link>
                                                            <Link to="#" className={this.state.active === 'fi' ? 'active' : ''} onClick={() => this.setLanguage('fi')}>FI</Link>
                                                        </div>
                                                    </div>
                                                ) : null
                                        }
                                        {/** Render loomis.se link for loomis shop only */}
                                        { !this.props.publicTenant ?
                                            <div className="help-nav-list__item-col">
                                                <a href="https://www.loomis.se/" target='_blank' className="help-nav-list__link loomis">
                                                    <i className="help-nav-list-icon">
                                                        <svg className="help-nav-list-icon__svg" viewBox="0 0 20 20">
                                                            <use xlinkHref="#globe-light"/>
                                                        </svg>
                                                    </i>
                                                    <span className="help-nav-list__text">loomis.se</span>
                                                </a>
                                            </div>
                                            : null
                                        }
                                    </li>
                                    <div className="header__change-customer-type">
                                        {this.renderCustomerType()}
                                    </div>

                                    {this.renderAccount()}

                                    <li className="help-nav-list__item" onClick={() => this.props.history.push('/cart')}>
                                        <Link to="/cart" className="help-nav-list__link">
                                            <i className="help-nav-list-icon">
                                                <svg className="help-nav-list-icon__svg" viewBox="0 0 20 20">
                                                    <use xlinkHref="#cart"/>
                                                </svg>
                                            </i>
                                            <span className="help-nav-list__text" >
                                            {i18next.t('header.cart')}
                                        </span>
                                            <span className="cart-badge" style={{background: '#C8002A'}}>{this.props.itemsInCart}</span>
                                        </Link>
                                    </li>

                                    {this.renderLogout()}

                                    <li className="help-nav-list__item menu-button" onClick={ this.props.showMobileMenu }>
                                        <i className="help-nav-list-icon">
                                            <button className="hamburger hamburger--squeeze" type="button" aria-label="Menu" id="mobile-menu-button">
                                                <span className="hamburger-box">
                                                    <span className="hamburger-inner"></span>
                                                </span>
                                            </button>
                                        </i>
                                        <span className="help-nav-list__text">{i18next.t('header.menu')}</span>
                                    </li>
                                </ul>
                            </nav>
                            {this.renderShops()}
                        </div>
                    </div>
                </div>

                <div className="header__bottom">
                    <div className="c search-form-wrapper">
                        <SearchForm {...this.props} />
                    </div>
                </div>

                {/** Show mobile menu from max-width 768px */}
                <MobileMenu changeCustomerType={this.renderCustomerType()} />

            </header>

    )}
}


function mapStateToProps(state) {
    return {
        itemsInCart: state.cart.itemsInCart,
        loggedIn: state.auth.loggedIn,
        tenantId: state.auth.tenantId,
        businessPrivateToggle: state.auth.businessPrivateToggle,
        language: state.language,
        publicTenant: state.auth.publicTenant,
        articleCategories: state.customer.articleCategories,
        defaultArticleGroup: state.customer.defaultArticleGroup,
        isProcessing: state.auth.isProcessing,
        privateCustomerType: state.customer.privateCustomerType
    }
}

export default withRouter( connect(mapStateToProps, {logout, changeLanguage, showMobileMenu, getArticleCategories, setCustomerType})(Header) );

