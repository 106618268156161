import React from 'react';
import {Field} from "redux-form";
import {textField} from "../../components/form/formFields";
import {connect} from "react-redux";
import i18next from 'i18next';
import {withRouter} from "react-router-dom";

const Contact = () => {
    return (
        <div className="checkout-step" id='checkout-step__contact'>
            <div className="checkout-step-header">
                <h2 className="checkout-step-header__title">2. {i18next.t('checkout_page.contact_person')}</h2>
            </div>
            <div className="checkout-step-content">
                <h3 className="checkout-step-content__subtitle">{i18next.t('checkout_page.contact_description')}*</h3>
                <div className="checkout-step-content-wrapper">
                    <div className="checkout-step-content-wrapper__col-one">&nbsp;</div>
                    <div className="checkout-step-content-wrapper__col-two">
                        <Field
                            name='deliveryContactPerson'
                            label={`${i18next.t('checkout_page.contact_person')}*`}
                            placeholder={`${i18next.t('checkout_page.contact_person')}*`}
                            component={textField}
                            maxLength="50"
                            type="text"
                        />
                        <Field
                            name='deliveryContactPhoneNumber'
                            label={`${i18next.t('checkout_page.contact_person_phone')}*`}
                            placeholder={`${i18next.t('checkout_page.contact_person_phone')}*`}
                            component={textField}
                            maxLength="50"
                            extraClasses='field-content--50'
                            type="text"
                        />
                        <Field
                            name='deliveryContactEmail'
                            label={`${i18next.t('checkout_page.contact_person_email')}*`}
                            placeholder={`${i18next.t('checkout_page.contact_person_email')}*`}
                            component={textField}
                            maxLength="50"
                            extraClasses='field-content--50'
                            type="email"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
function mapStateToProps(state) {
    return {
        language: state.language.language.language
    }
}
export default withRouter(connect(mapStateToProps, null)(Contact));
