import React, { Component } from 'react';
import {Route, withRouter} from 'react-router-dom';
import BreadCrumbs from '../../components/navigation/BreadCrumbs';
import {LinkButtonLeft} from '../../components/links/LinkButton';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Dashboard from './Dashboard';
import Orders from './Orders';
import Information from './Information';
import Settings from './Settings';
import {getOrders, getProducts} from "../../actions/actions";
import {connect} from "react-redux";
import i18next from 'i18next';
import _ from "lodash";

class AccountPage extends Component {
    constructor(props) {
        super(props);

        let tabIndex = 0
        if(this.props.location.pathname.indexOf('dashboard') > -1) {
            tabIndex = 0
        } else if(this.props.location.pathname.indexOf('orders') > -1) {
            tabIndex = 1
        } else if(this.props.location.pathname.indexOf('information') > -1) {
            tabIndex = 2
        } else if(this.props.location.pathname.indexOf('settings') > -1) {
            tabIndex = 3
        }
        this.state = ({
            tabIndex
        })
        this.changeTab = this.changeTab.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        let tabIndex = 0
        if(nextProps.location.pathname.indexOf('dashboard') > -1) {
            tabIndex = 0
        } else if(nextProps.location.pathname.indexOf('orders') > -1) {
            tabIndex = 1
        } else if(nextProps.location.pathname.indexOf('information') > -1) {
            tabIndex = 2
        } else if(nextProps.location.pathname.indexOf('settings') > -1) {
            tabIndex = 3
        }

        this.setState({
            tabIndex
        })
    }

    componentDidMount() {
        this.props.getOrders();
        this.props.getProducts();
    }

    componentDidUpdate(prevProps, props) {
        if(!prevProps.auth.user && this.props.auth.user) {
            this.props.getOrders();
            this.props.getProducts();
        }
    }
    changeTab(tabIndex) {
        switch(tabIndex) {
            case 0:
                this.props.history.push('/account/dashboard')
                break;
            case 1:
                this.props.history.push('/account/orders')
                break;
            case 2:
                this.props.history.push('/account/information')
                break;
            case 3:
                this.props.history.push('/account/settings')
                break;
            default:
                this.props.history.push('/account/dashboard')
        }
    }

    render() {
        return (
            <div className="wrapper">
                <BreadCrumbs pagename={i18next.t('account.page_name')} />

                <div className="categories-nav">
                    <div className="category-nav">
                        <LinkButtonLeft
                            path="#"
                            title={i18next.t('account.link_back')}
                            onClick={() => this.props.history.push(`/shop/${this.props.defaultArticleGroup}`) }
                        />
                    </div>
                </div>

                <main className="main typeset">
                    <div className="c">
                        <div className="page-header">
                            <div className="page-header__col-one">
                                <h1 className="page-header__title">{i18next.t('account.page_name')}</h1>
                            </div>
                        </div>

                        <Tabs selectedIndex={this.state.tabIndex} onSelect={this.changeTab}>
                            <TabList>
                                <Tab>
                                    <i className='nav-tabs-icon'>
                                        <svg className="nav-tabs-list-icon__svg" viewBox="0 0 20 20">
                                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#nav-home"></use>
                                        </svg>
                                    </i>
                                    <span className="nav-tabs-list-text">{i18next.t('account.overview.overview')}</span>
                                </Tab>
                                <Tab>
                                    <i className='nav-tabs-icon'>
                                        <svg className="nav-tabs-list-icon__svg" viewBox="0 0 20 20">
                                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#nav-orders"></use>
                                        </svg>
                                    </i>
                                    <span className="nav-tabs-list-text">{i18next.t('account.orders.my_orders')}</span>
                                </Tab>
                                <Tab>
                                    <i className='nav-tabs-icon'>
                                        <svg className="nav-tabs-list-icon__svg" viewBox="0 0 20 20">
                                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#nav-information"></use>
                                        </svg>
                                    </i>
                                    <span className="nav-tabs-list-text">{i18next.t('account.information.my_information')}</span>
                                </Tab>
                                <Tab>
                                    <i className='nav-tabs-icon'>
                                        <svg className="nav-tabs-list-icon__svg" viewBox="0 0 20 20">
                                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#nav-settings"></use>
                                        </svg>
                                    </i>
                                    <span className="nav-tabs-list-text">{i18next.t('account.settings.settings')}</span>
                                </Tab>
                            </TabList>

                            <TabPanel>
                                <Route path={this.props.match.path} component={Dashboard} />
                            </TabPanel>

                            <TabPanel>
                                <Route path={this.props.match.path} component={Orders} />
                            </TabPanel>

                            <TabPanel>
                                <Route path={this.props.match.path} component={Information} />
                            </TabPanel>
                            <TabPanel>
                                <Route path={this.props.match.path} component={() => <Settings publicTenant={this.props.publicTenant}/>} />
                            </TabPanel>
                        </Tabs>


                    </div>
                </main>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        defaultArticleGroup: state.customer.defaultArticleGroup,
        publicTenant: state.auth.publicTenant,
        isProcessing: state.auth.isProcessing,
        auth: state.auth
    }
}

export default withRouter(connect(mapStateToProps, {getOrders, getProducts})(AccountPage));
