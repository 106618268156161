import { GET_SEARCH_VALUE } from "../actions/actions";

const initialState = '';

export default function(state = initialState, action ) {
    switch(action.type) {
        case GET_SEARCH_VALUE:
            return action.value;
        default:
            return state
    }
}



