import React, {useState} from 'react';
import i18next from 'i18next';
import { Field } from 'redux-form';
import { checkBox, radioButton, selectBox, textField } from '../../components/form/formFields';
import { Col, Divider, Row } from 'antd';

const PrintOnForms = props => {
    const {referenceNumberType} = props;

    return (
        <div className="checkout-step checkout-step--shipping">
            <div className="checkout-step-header">
                <h2 className="checkout-step-header__title">6. {i18next.t('checkout_page.print-on-forms.title')}</h2>
            </div>
            <div className="checkout-step-content">
                <div className="checkout-step-content-wrapper">

                    <div className="field-wrapper field-wrapper--margin-top">
                        <h3 className="field-wrapper__title">{i18next.t('checkout_page.print-on-forms.bankAccountNumber')}</h3>
                        <Field
                            name='bankAccountNumber'
                            label={`${i18next.t('checkout_page.print-on-forms.bankAccountNumber')}*`}
                            placeholder={`${i18next.t('checkout_page.print-on-forms.bankAccountNumber')}*`}
                            component={textField}
                            maxLength="50"
                            type="text"
                            extraClasses='field-content--100'
                        />
                    </div>
                    <div className="field-wrapper field-wrapper--margin-top">
                        <h3 className="field-wrapper__title">{i18next.t('checkout_page.print-on-forms.formMessage')}</h3>
                        <Field
                            name='formMessage'
                            label={`${i18next.t('checkout_page.print-on-forms.formMessage')}`}
                            placeholder={`${i18next.t('checkout_page.print-on-forms.formMessage')}`}
                            component={textField}
                            maxLength="35"
                            type="text"
                            extraClasses='field-content--100'
                        />
                    </div>
                    <div className="field-wrapper field-wrapper--margin-top">
                        <Col span={12}>
                            <h3 className="field-wrapper__title">{i18next.t('checkout_page.print-on-forms.referenceNumberType')}</h3>
                            <Row>
                                <Col span={12}>
                                    <label className="field-content__text">
                                        <Field
                                            className="checkout-step-content__input"
                                            type="radio"
                                            name="referenceNumberType"
                                            component="input"
                                            value="1"
                                        /> fixed
                                    </label>
                                </Col>
                                <Col span={12}>
                                    <label className="field-content__text">
                                        <Field
                                            className="checkout-step-content__input"
                                            type="radio"
                                            name="referenceNumberType"
                                            component="input"
                                            value="2"
                                        /> serial
                                    </label>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                    {
                        referenceNumberType == 1 ?
                            (
                                <>
                                    <div className="field-wrapper field-wrapper--margin-top">
                                        <h3 className="field-wrapper__title">{i18next.t('checkout_page.print-on-forms.referenceNumberFixed')}</h3>
                                        <Field
                                            name='referenceNumberFixed'
                                            label={`${i18next.t('checkout_page.print-on-forms.referenceNumberFixed')}*`}
                                            placeholder={`${i18next.t('checkout_page.print-on-forms.referenceNumberFixed')}*`}
                                            component={textField}
                                            maxLength="19"
                                            type="text"
                                            extraClasses='field-content--100'
                                        />
                                    </div>
                                </>
                            ): referenceNumberType == 2 &&
                            (<>
                                <div className="field-wrapper field-wrapper--margin-top">
                                    <h3 className="field-wrapper__title">{i18next.t('checkout_page.print-on-forms.referenceNumberStart')}</h3>
                                    <Field
                                        name='referenceNumberStart'
                                        label={`${i18next.t('checkout_page.print-on-forms.referenceNumberStart')}*`}
                                        placeholder={`${i18next.t('checkout_page.print-on-forms.referenceNumberStart')}*`}
                                        component={textField}
                                        maxLength="20"
                                        type="text"
                                        extraClasses='field-content--100'
                                    />
                                </div>
                                <div className="field-wrapper field-wrapper--margin-top">
                                    <Field
                                        name='referenceNumberInterval'
                                        label={i18next.t('checkout_page.print-on-forms.referenceNumberInterval')}
                                        component={selectBox}
                                        options={[1, 10, 100, 1000]}
                                        extraClasses='field-content--100'
                                    />
                                </div>
                                <div className="field-wrapper field-wrapper--margin-top">
                                    <h3 className="field-wrapper__title">{i18next.t('checkout_page.print-on-forms.referenceNumberInternational')}</h3>
                                    <Field
                                        name='referenceNumberInternational'
                                        label={i18next.t('checkout_page.print-on-forms.referenceNumberInternational')}
                                        component={checkBox}
                                    />
                                </div>
                            </>)
                    }
                </div>
            </div>
        </div>
    );
};

export default PrintOnForms;
