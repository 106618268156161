import React, {Component} from 'react';
import Filter from '../components/Filter';
import Product from './Product';
import ProductList from './ProductList';
import {Collapse, Icon, Pagination} from 'antd';
import {connect} from "react-redux";
import {getProducts, getArticleCategories} from "../actions/actions";
import Linkify from 'linkifyjs/react';
import _ from "lodash";
import ApiConnector from "../api/apiConnection";
import i18next from 'i18next';
import {IMAGE_ARTICLE_GROUPS, SERVER_URL} from "../config/config";
import ReactHtmlParser from 'react-html-parser';
import SynLabLogo from '../img/synlab-logo.png';

/** Component representing a sorted by group article containers */
class ProductsByGroup extends Component {
    constructor(props)  {
        super(props);
        this.state = {
            mode: 'gallery',
            perPage: 20,
            currentPage: 1,
            total: 0
        };

        this.showGallery = this.showGallery.bind(this);
        this.showList = this.showList.bind(this);
        this.changePage = this.changePage.bind(this)
        this.changePerPage = this.changePerPage.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.match.params.group !== this.props.match.params.group) {
            this.props.getProducts(this.state.perPage, 0, nextProps.match.params.group )
            this.getTotal(nextProps.match.params.group)
            this.setState({currentPage: 1})
        }
    }
    getTotal(category) {
        const that = this
        ApiConnector.getTotal(category)
            .then(response => {
                that.setState({
                    total: response.data
                })
            })
    }
    componentDidMount() {
        this.props.getArticleCategories();
        this.props.getProducts(this.state.perPage, (this.state.currentPage - 1)*this.state.perPage, this.props.match.params.group )
        this.getTotal(this.props.match.params.group);
    }

    componentDidMount() {

        this.props.getArticleCategories();
        this.props.getProducts(this.state.perPage, (this.state.currentPage - 1)*this.state.perPage, this.props.match.params.group )
        this.getTotal(this.props.match.params.group);
    }

    /** Set view mode to 'gallery' */
    showGallery() {
        this.setState({mode: 'gallery'})
    };

    /** Set view mode to 'list' */
    showList() {
        this.setState({mode: 'list'})
    };

    changePerPage(newPerPage) {
        this.props.getProducts(newPerPage, 0, this.props.match.params.group)
        this.setState({perPage: newPerPage, currentPage: 1})
    }

    changePage(pageNumber) {
        const pages = Math.ceil(this.state.total/this.state.perPage)
        if(pageNumber < 1 || pageNumber > pages) {
            return
        }
        this.props.getProducts(this.state.perPage, (pageNumber - 1)*this.state.perPage, this.props.match.params.group );
        this.setState({currentPage: pageNumber});
    }

    /**
     * Render different view modes for products
     * By default - 'gallery' mode
     */
    selectMode() {
        {/** List mode */}
        if(this.state.mode === 'list') {
            return (
                <div className="products products--list">
                    <div className="products-header">
                        <p className="products-header__title">Namn</p>
                        <p className="products-header__description">Beskrivning</p>
                    </div>
                    {
                        _.map(this.props.products, product => {
                            return (
                                <ProductList key={product.id}
                                         item={product}
                                         currency={this.props.currency}
                                />
                            )})
                    }
                </div>
            )
        }
        else {
            {/** Gallery mode */}
            return (
                <div className="products products--gallery">
                    {
                        _.map(this.props.products, product => {
                            return (
                                <Product key={product.id}
                                         item={product}
                                         currency={this.props.currency}
                                />
                            )})
                    }
                </div>
            )
        }
    };

    /** Render translated article group name, image and description */
    renderDescription() {
        if(this.props.match.params.group) {
            const articleGroup = this.props.articleGroups.find (group => {
                return group.id.toString() === this.props.match.params.group
            })
            if(!articleGroup) {
                return null
            }
            const regexTest = new RegExp("\\[" + this.props.language + "\\].*?\"([\\S\\s]*?)\"")

            const matchName = regexTest.exec(articleGroup.name);
            const matchDescription = regexTest.exec(articleGroup.description);

            const translatedName = (matchName && matchName.length > 1) ? matchName[1] : articleGroup.name
            const translatedDescription = ReactHtmlParser((matchDescription && matchDescription.length > 1) ? matchDescription[1] : articleGroup.description);

            const articleGroupImage = () => {
                if(articleGroup.imageFilename) {
                    return (
                        <div className="page-header__img">
                            <img src={this.props.tenantId == 8 ? SynLabLogo :`${SERVER_URL}${IMAGE_ARTICLE_GROUPS}${articleGroup.imageFilename}`} />
                        </div>
                    )
                } else {
                    return null
                }
            }

            const { Panel } = Collapse;

            return (
                <div className="page-header">
                    <Collapse bordered={false}
                              defaultActiveKey={window.innerWidth >= 768 ? '1' : '0'}
                              expandIconPosition={'right'}
                              expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? -90 : 90} />}
                    >
                        <Panel header={translatedName} key="1" style={{textAlign: 'center', fontSize: '20px'}}>
                            {/*<div className="c">*/}
                            <div className="articleGroupsContainer" style={{textAlign: 'left'}}>
                                {articleGroupImage()}
                                <div className="page-header__desc">
                                    {/*<h1 className="page-header__title">{translatedName}</h1>*/}
                                    <Linkify tagName="p" className='page-header__text'>{translatedDescription}</Linkify>
                                </div>
                            </div>
                            {/*</div>*/}
                        </Panel>
                    </Collapse>
                </div>
            )
        } else {
            return (
                <div className="page-header">
                    <div className="c">
                        <div className="page-header__desc">
                            <h1 className="page-header__title">{i18next.t('category_page.select_group')}</h1>
                        </div>
                    </div>
                </div>
            )
        }
    }
    render() {
        if(this.props.articleGroups.length === 0) {
            return null
        }
        const groupExists = this.props.articleGroups.some( articleGroup => {
            return articleGroup.id.toString() === this.props.match.params.group
        })
        if(this.props.match.params.group && !groupExists) {
            this.props.history.push('/404')
            return null
        }
        return (
            <div className="primary-products">
                {this.renderDescription()}
                    <Filter
                        handleGallery={this.showGallery}
                        handleList={this.showList}
                        currentPage={this.state.currentPage}
                        perPage={this.state.perPage}
                        changePerPage={this.changePerPage}
                        pages={Math.ceil(this.state.total/this.state.perPage)}
                        {...this.props}
                    />

                    {this.selectMode()}

                    <div className='pagination-wrapper'>
                        <Pagination
                            total={this.state.total}
                            pageSize={this.state.perPage}
                            current={this.state.currentPage}
                            defaultCurrent={1}
                            onChange={this.changePage}
                        />
                    </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        products: state.products,
        articleGroups: state.customer.articleGroups,
        language: state.language.language.language,
        currency: state.auth.currency,
        tenantId: state.auth.tenantId,
        isProcessing: state.auth.isProcessing
    }
}
export default connect(mapStateToProps, {getProducts, getArticleCategories})(ProductsByGroup);
