import React, { Component } from 'react';
import BreadCrumbs from '../../components/navigation/BreadCrumbs';
import {LinkButtonLeft} from '../../components/links/LinkButton';
import CartItem from '../../components/CartItem';
import { Spin } from '../../components/Spin';
import i18next from 'i18next';
import {connect} from "react-redux";
import {reduxForm, Field} from "redux-form";
import {textField} from "../../components/form/formFields";
import { LinkButtonSave } from '../../components/links/LinkButton';
import { getOrder, sendAnotherConfirmationEmail, clearRedirectedToSummary } from '../../actions/actions';
import ReactGA from "react-ga";
import { GA_CODE_DOMAIN, GA_CODE_DOMAIN_UAT } from '../../config/config';

class ThankYouPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirected: false
        }
        this.confirmationEmail = this.confirmationEmail.bind(this);
    }

    componentDidMount() {
        this.props.getOrder(this.props.match.params.order);
        if (this.props.redirectedToSummary) {
            this.setState({
                redirected: true
            });
            this.props.clearRedirectedToSummary();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.orders !== this.props.orders && this.state.redirected) {
            this.executeGaScript();
        }
    }

    roundPrice = (price) => {
        const hasDecimal = price % 1;
        if (hasDecimal) {
            return price.toFixed(2);
        }
        return price;
    }


    executeGaScript = () => {
        if (window.location.hostname !== GA_CODE_DOMAIN && window.location.hostname !== GA_CODE_DOMAIN_UAT || !localStorage.getItem('ga-accepted')) {
            return;
        }
        const order = this.props.orders.find(order => {
            if(!order.orderNumber) {
                return false
            }
            return order.orderNumber.toString()  === this.props.match.params.order
        });
        if (order) {
            const shipping = order.orderItems.filter(item => {
                if (item.name.toLowerCase().includes('frakt')) {
                    return true;
                }
                return false;
            });
            ReactGA.plugin.require('ecommerce');
            ReactGA.plugin.execute('ecommerce', 'addTransaction', {
                id: order.orderNumber,
                affiliation: 'Loomis eStore WEB',
                revenue: order.totalPrice,
                shipping: shipping[0] ? shipping[0].price : 0,
                tax: order.totalPrice * (this.props.vat * 0.01)
            });
            order.orderItems.map(item => {
                if (!item.name.toLowerCase().includes('frakt')) {
                    ReactGA.plugin.execute('ecommerce', 'addItem', {
                        id: item.containerId,
                        name: item.name,
                        sku: item.articleNumber,
                        price: item.price,
                        quantity: item.amount
                    });
                }
            })
            ReactGA.plugin.execute('ecommerce', 'send', null);
            ReactGA.plugin.execute('ecommerce', 'clear', null);
        }
    }

    confirmationEmail(values) {
        const {orderNumber, orderItems}  = this.props.orders[0];
        const data = {
            email: values.email,
            orderNumber,
            orderItems
        };
        this.props.reset();
        return this.props.sendAnotherConfirmationEmail(data)
    }
    renderInvoiceAddress() {
        const order = this.props.orders.find(order => {
            if(!order.orderNumber) {
                return false
            }
            return order.orderNumber.toString()  === this.props.match.params.order
        });

        if(order.invoiceAddress) {
            return (
            <div className="delivery-info__col-two">
                <h2 className="delivery-info__title">
                    {i18next.t('thank_you_page.invoice_address')}
                </h2>
                <p>
                    {`${order.invoiceAddress.streetName} ${order.invoiceAddress.streetNumber ? order.invoiceAddress.streetNumber : ''} ${order.invoiceAddress.streetLetter ? order.invoiceAddress.streetLetter : ''}`}<br/>
                    {`${order.invoiceAddress.zipCode}, ${order.invoiceAddress.city}`}<br/>
                    {order.invoiceAddress.country}
                </p>
            </div>
            )
        } else {
            return null
        }
    }
    render() {
        const order = this.props.orders.find(order => {
            if(!order.orderNumber) {
                return false
            }
             return order.orderNumber.toString()  === this.props.match.params.order
        });

        const {handleSubmit} = this.props;

        if(!order) {
            return <Spin tenantId={this.props.tenantId}/>
        }

        const totalPrice = order.orderItems.filter(item => {
            return item.payer !== 'TENANT'
        })
            .map(item => {
                return (item.price * item.amount)
            })
            .reduce( (a, b) => {
                return a + b
            }, 0);

        let delivery = <p>{i18next.t('thank_you_page.delivery_normal')}</p>
        if(order.orderItems.some(item => {
            return item.name === 'Frakt Premium'
        })) {
            delivery = <p>{i18next.t('thank_you_page.delivery_premium')}</p>
        }
        return (
            <div className="wrapper">
                <BreadCrumbs pagename={i18next.t('thank_you_page.page_name')} />

                <div className="categories-nav">
                    <div className="category-nav">
                        <LinkButtonLeft path='#' title={i18next.t('breadcrumbs.link_back')} onClick={() => this.props.history.push('/')} />
                    </div>
                </div>

                <main className="main typeset">
                    <div className="c">
                        <div className="page-header">
                            <div className="page-header__col-one">
                                <h1 className="page-header__title">{i18next.t('thank_you_page.title')}</h1>
                                <p>
                                    {i18next.t('thank_you_page.order_number')} {order.orderNumber}<br/>
                                    {i18next.t('thank_you_page.confirm')}
                                </p>
                            </div>
                        </div>

                        <div className="main-content">
                            <div className="main-content__primary main-content__primary--full">
                                <div className="order-confirmation">

                                    <div className="delivery-info">

                                        <div className="delivery-info__col-one">
                                            <h2 className="delivery-info__title">
                                                {i18next.t('thank_you_page.delivery_address')}
                                            </h2>

                                            <p>
                                                {`${order.deliveryAddress.streetName} ${order.deliveryAddress.streetNumber ? order.deliveryAddress.streetNumber : ''} ${order.deliveryAddress.streetLetter ? order.deliveryAddress.streetLetter : ''}`}<br/>
                                                {`${order.deliveryAddress.zipCode}, ${order.deliveryAddress.city}`}<br/>
                                                {order.deliveryAddress.country}
                                            </p>
                                        </div>
                                        {this.renderInvoiceAddress()}


                                        <div className="delivery-info__col-three">
                                            <h2 className="delivery-info__title">{i18next.t('thank_you_page.delivery_method')}</h2>
                                            {delivery}
                                        </div>
                                    </div>

                                    <h2 className="order-confirmation__title">
                                        {i18next.t('thank_you_page.your_shopping_cart')}
                                    </h2>

                                    <div className="cart">
                                        {order.orderItems.map((product) => {
                                        return (
                                            <CartItem key={product.articleNumber} data={product} completed={true}/>
                                            )}
                                        )}

                                        <div className="order-summary order-summary--no-bg">
                                            <p className="order-summary-item order-summary-item--right">
                                                <span className="order-summary-item__value order-summary-item__value--dark">{this.roundPrice(totalPrice)} {this.props.currency}</span>
                                                <span className="order-summary-item__key order-summary-item__key--dark">{`${i18next.t('thank_you_page.total')} Netto`}</span>
                                            </p>
                                            <p className="order-summary-item order-summary-item--right">
                                                <span className="order-summary-item__value order-summary-item__value--dark">{this.roundPrice(totalPrice * `0.${this.props.vat}`)} {this.props.currency}</span>
                                                <span className="order-summary-item__key order-summary-item__key--dark">{`${i18next.t('thank_you_page.vat')}`}</span>
                                            </p>
                                            <p className="order-summary-item order-summary-item--right">
                                                <span className="order-summary-item__value order-summary-item__value--dark">{this.roundPrice(totalPrice * `1.${this.props.vat}`)} {this.props.currency}</span>
                                                <span className="order-summary-item__key order-summary-item__key--dark">{`${i18next.t('thank_you_page.total')} Brutto`}</span>
                                            </p>
                                        </div>
                                    </div>

                                    <div>
                                        <p>{i18next.t('thank_you_page.email_label')}</p>
                                        <form onSubmit={handleSubmit(this.confirmationEmail)} >
                                            <Field
                                                name='email'
                                                type='email'
                                                label={i18next.t('thank_you_page.another_email')}
                                                placeholder={i18next.t('thank_you_page.another_email')}
                                                component={textField}
                                                extraClasses=''
                                            />
                                            <LinkButtonSave
                                                title={i18next.t('thank_you_page.submit_btn')}
                                                extraClasses
                                            />
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </main>

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        orders: state.customer.orders,
        currency: state.auth.currency,
        vat: state.auth.vat,
        privateCustomerType: state.customer.privateCustomerType,
        tenantId: state.auth.tenantId,
        redirectedToSummary: state.customer.redirectedToSummary
    }
}

ThankYouPage = reduxForm({
    form: 'anotherEmailConfirmation'
})(ThankYouPage);

export default connect(mapStateToProps, {getOrder, sendAnotherConfirmationEmail, clearRedirectedToSummary})(ThankYouPage);
