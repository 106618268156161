import React from 'react'
import {textField} from "../../components/form/formFields";
import {Field, reduxForm} from "redux-form";
import {Alert} from "antd";
import {connect} from "react-redux";
import {changeEmail, changePassword, showCustomerSettingsModal} from "../../actions/actions";
import i18next from 'i18next';

const validate = (values) => {
    const errors = {}
    if(!values.newEmail) {
        errors.newEmail = i18next.t('errors.required')
    }
    if(values.newEmail === values.currentEmail) {
        errors.newEmail = i18next.t('errors.identical')
    }
    if(!values.currentPassword) {
        errors.currentPassword = i18next.t('errors.required')
    }
    if(!values.newPassword) {
        errors.newPassword = i18next.t('errors.required')
    }
    if(!values.confirmPassword) {
        errors.confirmPassword = i18next.t('errors.required')
    } else {
        if (values.confirmPassword !== values.newPassword) {
            errors.newPassword = i18next.t('errors.must_match')
            errors.confirmPassword = i18next.t('errors.must_match')
        }
        if (values.currentPassword === values.newPassword) {
            errors.newPassword = i18next.t('errors.identical')
        }
    }
    return errors
}

let ChangeCustomerSettings = (props) => {

    function changeSettings(values) {
        if(props.temporaryPassword  && props.email === "") {
            props.changePassword(values.currentPassword, values.newPassword)
            .then( () => {
                props.changeEmail(values.newEmail, false)
                props.reset();
                props.showCustomerSettingsModal(false);

            });
        } else if (props.temporaryPassword && props.email !== "" ) {
            props.changePassword(values.currentPassword, values.newPassword) 
            .then( () => {
                props.changeEmail(props.email, false)
                props.reset();
                props.showCustomerSettingsModal(false);
               
            });
            
        }  else if (props.email === "" && !props.temporaryPassword) {
            props.changeEmail(values.newEmail, false)
            .then( () => {
                props.reset()
                props.showCustomerSettingsModal(false);
            });
        }
    }
    const {handleSubmit, error, temporaryPassword, email} = props;
    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(changeSettings)}>
                <h3 className="field-wrapper__title">{i18next.t('account.settings.email')}</h3>
                { error && <Alert
                    message="Error"
                    description={error}
                    type="error"
                    style={{marginBottom: '10px'}}
                    showIcon
                /> }

                {props.email !== "" && 
                    <Field
                        name='currentEmail'
                        label={i18next.t('account.settings.email')}
                        placeholder={i18next.t('account.settings.email')}
                        component={textField}
                        maxLength="50"
                        type="email"
                        readOnly
                    />
                }

                {props.email === "" && 
                      <Field
                      name='newEmail'
                      label={i18next.t('account.settings.new_email')}
                      placeholder={i18next.t('account.settings.new_email')}
                      component={textField}
                      maxLength="50"
                      type="email"
                    />
                }
                    <h3 className="field-wrapper__title">{i18next.t("account.settings.change_password")}</h3>
                    { error && <Alert
                        message="Error"
                        description={error}
                        type="error"
                        style={{marginBottom: '10px'}}
                        showIcon
                    /> }

                    <Field
                        name='currentPassword'
                        label={i18next.t("account.settings.current_password")}
                        placeholder={i18next.t("account.settings.current_password")}
                        component={textField}
                        maxLength="50"
                        type="password"
                    />
                    <Field
                        name='newPassword'
                        label={i18next.t("account.settings.new_password")}
                        placeholder={i18next.t("account.settings.new_password")}
                        component={textField}
                        maxLength="50"
                        type="password"
                    />
                    <Field
                        name='confirmPassword'
                        label={i18next.t("account.settings.repeat_password")}
                        placeholder={i18next.t("account.settings.repeat_password")}
                        component={textField}
                        maxLength="50"
                        type="password"
                    />
                <button className="information-address__button link-button link-button--neg" type='submit'>
                    <span property="name" className="link-button__name link-button__name--neg">{i18next.t('account.settings.save')}</span>
                </button>
            </form>
            
        </React.Fragment>
    )
}
function mapStateToProps(state) {
    return {
        initialValues: {
            currentEmail: state.customer.email
        },
        email: state.customer.email,
        temporaryPassword: state.customer.temporaryPassword,
    }
}
ChangeCustomerSettings = reduxForm({
    form: 'password',
    validate,
    enableReinitialize: true
})(ChangeCustomerSettings);
export default connect(mapStateToProps, {changeEmail, changePassword, showCustomerSettingsModal})(ChangeCustomerSettings)