const LOCAL_SERVER = 'http://localhost:8080'
const UAT_SERVER = 'https://uat-adminmaterial.loomis.se' //TEST
const PROD_SERVER = 'https://adminmaterial.loomis.se' //PRODUCTION

let selectedServer = ''

switch (process.env.REACT_APP_STAGE.trim()) {
    case 'dev':
        selectedServer = LOCAL_SERVER
        break;
    case 'uat':
        selectedServer = UAT_SERVER
        break;
    case 'prod':
        selectedServer = PROD_SERVER
        break;
    default:
        selectedServer = LOCAL_SERVER
        break;
}

export const SERVER_URL = selectedServer;
export const IMAGE_PATH = '/img/articleContainers/';
export const FILE_PATH = '/files/articleContainers/';
export const VIDEO_PATH = '/video/articleContainers/';
export const IMAGE_ARTICLE_GROUPS = '/img/articleGroups/';
export const GA_CODE = 'UA-159251864-2';
export const GA_CODE_DOMAIN = 'estore.loomis.se';

export const GA_CODE_UAT = 'UA-159251864-3';
export const GA_CODE_DOMAIN_UAT = 'uat-estore.loomis.se';
