import React, { Component } from 'react';
import Product from '../Product';
import PreviousOrders from './PreviousOrders';
import {connect} from "react-redux";
import {getOrders, getRecommendedProducts} from "../../actions/actions";
import _ from "lodash";
import i18next from 'i18next';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 3
        }
        this.loadMore = this.loadMore.bind(this);
    }
    componentDidMount() {
        this.props.getRecommendedProducts();
    }

    loadMore() {
        this.setState({
            limit: this.state.limit + 3
        })
    }

    render() {

        return (
            <div className="main-content">
                <div className="dashboard">
                    <div className="dashboard__col-one">

                        <h5>{i18next.t('account.overview.recommended')}</h5>

                        <div className="products products--gallery products--gallery-main-content" itemScope itemType="http://schema.org/ItemList">
                            <div className="placeholder">
                                {
                                    _.map(this.props.products, product => {
                                        return (
                                            <Product key={product.id}
                                                     item={product}
                                                     currency={this.props.currency}
                                            />
                                        )})
                                }
                            </div>
                        </div>
                    </div>

                    <div className="dashboard__col-two">
                        <PreviousOrders
                            orders={this.props.orders.slice(0, this.state.limit)}
                            onClick={this.loadMore}
                            currency={this.props.currency}
                            vat={this.props.vat}
                        />
                    </div>

                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {

    return {
        products: state.products,
        orders: state.customer.orders,
        currency: state.auth.currency,
        vat: state.auth.vat
    }
}
export default connect(mapStateToProps, {getRecommendedProducts, getOrders}) (Dashboard);