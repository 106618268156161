import React from 'react';
import {Link} from 'react-router-dom';
import i18next from "i18next";
import {connect} from "react-redux";
import ApiConnector from "../api/apiConnection";
import {selectStore} from "../actions/actions";
// import Pagination1 from './Pagination';
import { Pagination } from 'antd';

class SelectStorePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            perPage: 10,
            currentPage: 1,
            total: 0,
            stores: []
        }
        this.getTotal = this.getTotal.bind(this)
        this.getStores = this.getStores.bind(this)
        this.searchStore = this.searchStore.bind(this)
        this.selectStore = this.selectStore.bind(this)
        this.changePage = this.changePage.bind(this)
    }

    getTotal(pattern = '') {
        const that = this
        ApiConnector.getTotalStoresForCustomer(pattern)
            .then(response => {
                that.setState({
                    total: response.data
                })
            })
    }
    getStores(limit, offset, pattern = '') {
        const that = this
        ApiConnector.getStoresForCustomer(limit, offset, pattern)
            .then(response => {
                that.setState({
                    stores: response.data
                })
            })
    }

    componentDidMount() {
        this.getStores(this.state.perPage, (this.state.currentPage-1)*this.state.perPage);
        this.getTotal();
    }

    searchStore(evt) {
        this.getStores(this.state.perPage, (this.state.currentPage-1)*this.state.perPage, evt.target.value);
        this.getTotal(evt.target.value);
    }

    changePage(pageNumber) {
        const pages = Math.ceil(this.state.total/this.state.perPage);

        if(pageNumber < 1 || pageNumber > pages) {
            return
        }
        this.getStores(this.state.perPage, (pageNumber - 1)*this.state.perPage );
        this.setState({currentPage: pageNumber});
    }

    selectStore(customerNumber) {
        this.props.selectStore(customerNumber)
    }

    render() {
        return (
            <React.Fragment>
                <main className="main typeset">
                    <div className="page-header">
                        <div className="c">
                            <div className="page-header__desc">
                                <h1 className="page-header__title">{i18next.t('select_store_page.page_name')}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="main-content">
                        <div className="c">
                            <div className="selectStore_search">
                                <form method="get" action="#" className='selectStore-form'>
                                    <input
                                        className="selectStore-input"
                                        type="text"
                                        role='search'
                                        placeholder={i18next.t('select_store_page.placeholder')}
                                        onChange={this.searchStore}
                                    />
                                    <button type='submit' className="selectStore-submit">
                                        <svg className="help-nav-list-icon__svg" viewBox="0 0 20 20">
                                            <use xlinkHref="#magnifier-dark"/>
                                        </svg>
                                    </button>
                                </form>
                            </div>
                            <div className="selectStore_results">
                                {this.state.stores.map((store, index) => {
                                    return (
                                        <div className={`results__item  ${index % 2 === 0 ? 'results__item-bg' : ''}`}>
                                            <div className="results__col col__one">
                                                <Link to="#" className="results__header">
                                                    <p className="results__header-id"><b>{store.customerNumber}</b></p>
                                                    <p className="results__header-name">{store.storeId}</p>
                                                </Link>
                                            </div>
                                            <div className="results__col col_two">
                                                <div className="results__button" onClick={() => this.selectStore(store.customerNumber)}>
                                                    <Link to='#' className="link-button" >
                                                        <span className="link-button__name">{i18next.t('select_store_page.select')}</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>

                            {/*<Pagination1*/}
                                {/*perPage={this.state.perPage}*/}
                                {/*currentPage={this.state.currentPage}*/}
                                {/*total={this.state.total}*/}
                                {/*goToPage={this.changePage}*/}
                            {/*/>*/}

                            <div className='pagination-wrapper'>
                                <Pagination
                                    total={this.state.total}
                                    pageSize={this.state.perPage}
                                    current={this.state.currentPage}
                                    defaultCurrent={1}
                                    onChange={this.changePage}
                                />
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}
export default connect(null, {selectStore})(SelectStorePage);

