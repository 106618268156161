import axios from "axios/index";
import {SERVER_URL} from "../config/config";
import store from '../store/store';
import headers from "../security/headers";
import qs from "qs";
import {REFRESH_TOKEN} from "../actions/actions";


const ApiConnector = (function() {
    axios.defaults.withCredentials = true
    //Do not use get, only post
    function get(target, params, anonymous = false) {
        const authData = store.getState().auth
        params.tenant = authData.tenantId
        const config = {
            headers: headers(),
            params
        }
        const loggedIn = authData.loggedIn;

        if( (authData.publicTenant && !loggedIn) || anonymous ) {
            return axios.get(`${SERVER_URL}/api/guest/${target}`, config)
                .then((response) => {
                    return response
                })
        } else {
            return axios.get(`${SERVER_URL}/api/${target}`, config)
                .then((response) => {
                    return response
                })
        }
    }
    function post(target, body, params = {}, anonymous = false) {
        const authData = store.getState().auth
        params.tenant = authData.tenantId
        const config = {
            headers: headers(),
            params
        }
        const loggedIn = authData.loggedIn;

        if( (authData.publicTenant && !loggedIn) || anonymous ) {
            return axios.post(`${SERVER_URL}/api/guest/${target}`, body, config)
                .then((response) => {
                    return response
                })
        } else {
            return axios.post(`${SERVER_URL}/api/${target}`, body, config)
                .then((response) => {
                    return response
                })
        }
    }

    //Unused functions are commented out
    // to get rid of warnings in console

    // function put(target, body, params = {}) {
    //     const config = {
    //         headers: headers(),
    //         params
    //     }
    //     return axios.put(`${SERVER_URL}/api/${target}`, body, config)
    //         .then((response) => {
    //             return response
    //         })
    // }

    // function patch(target, body, params = {}) {
    //     const config = {
    //         headers: headers(),
    //         params
    //     }
    //     return axios.patch(`${SERVER_URL}/api/${target}`, body, config)
    //         .then((response) => {
    //             return response
    //         })
    // }

    // function apiDelete(target, params) {
    //     const config = {
    //         headers: headers(),
    //         params
    //     }
    //     return axios.delete(`${SERVER_URL}/api/${target}`, config)
    //         .then((response) => {
    //             return response
    //         })
    // }

    function login(user) {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            params: {
                tenant: store.getState().auth.tenantId,
                currency: store.getState().auth.currency,
                vat: store.getState().auth.vat
            }
        };

        return axios.post(`${SERVER_URL}/api/login`, user, config)
            .catch((error) => {
                console.log(error)
            })
    }

    function refreshToken() {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };
        const body = {
            grant_type: "refresh_token",
            refresh_token: JSON.parse(localStorage.user).refresh_token
        };
        return axios.post(`${SERVER_URL}/oauth/access_token`, qs.stringify(body), config)
            .then(response => {
                const data = response.data;
                const user = {
                    access_token: data.access_token,
                    refresh_token: data.refresh_token,
                    roles: data.roles,
                    id: data.id,
                    username: data.username,
                    tenant_id: data.tenant_id,
                    currency: data.currency
                };
                if(data) {
                    localStorage.setItem('user', JSON.stringify(user));
                    store.dispatch({
                        type: REFRESH_TOKEN,
                        user
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    function getProducts(limit, offset, articleGroup = null, pattern = "") {
        return post('articleContainer/ajaxList', {
            limit,
            offset,
            articleGroup,
            pattern
        })
    }
    function getSearchResults(articleGroup = null, pattern="") {
        return post('articleContainer/ajaxList', {articleGroup, pattern})
    }
    function getProduct(id) {
        return post('articleContainer/ajaxGet', {id})
    }
    function getTotal(articleGroup = null, pattern = "") {
        return post('articleContainer/ajaxTotal', {articleGroup, pattern})
    }
    function getArticles(articleContainer) {
        return post('articleContainer/ajaxGetArticlesForArticleContainer', articleContainer)
    }
    function getImages(articleContainer) {
        return post('articleContainer/ajaxGetImagesForArticleContainer', articleContainer)
    }
    function getCustomerData() {
        return post('customer/ajaxGetCustomer', {})
    }
    function getRelated(articleContainer) {
        return post('articleContainer/ajaxGetRelatedForArticleContainer', articleContainer)
    }
    function checkLogin() {
        const config = {
            headers: headers(),
            params: {
                tenant: store.getState().auth.tenantId
            }
        };
        return axios.get(`${SERVER_URL}/api/session/isLoggedIn`, config)
            .then((response) => {
                return response
            })
            .catch(() => {
                console.log(`Unauthorized user`)
            })
    }
    function createOrder(data) {
        return post('order/ajaxCreateNew', data)
    }
    function cancelOrder(orderNumber) {
        return post('order/ajaxCancel ', {orderNumber})
    }
    function getOrders(pattern = "") {
        return post('order/ajaxList', {pattern})
    }
    function getOrder(orderNumber) {
        return post('order/ajaxGetOrder', {orderNumber})
    }
    function getOrderItems(orderNumber) {
        return post('order/ajaxGetOrderItemsForOrderEstore', {orderNumber})
    }
    function getEmail() {
        return post('user/ajaxGetEmail', {})
    }
    function changeEmail(newEmail, temporaryPassword) {
        return post('user/ajaxChangeEmail', {newEmail, temporaryPassword})
    }
    function changePassword(currentPassword, newPassword) {
        return post('user/ajaxChangePassword', {currentPassword, newPassword})
    }
    function getArticleGroups() {
        return post('articleGroup/ajaxGetArticleGroupsForCustomer')
    }
    function getDeliveryCost(totalWeight, articles) {
        return post('order/ajaxGetDeliveryCost', {totalWeight, articles})
    }
    function changeAddress(addressType, addressData) {
        return post('postalAddress/ajaxChangeAddress', {addressType, addressData})
    }
    function getCountries() {
        return post('postalAddress/ajaxGetCountries', {})
    }
    function getInstallationCountry() {
        return post('postalAddress/ajaxGetInstallationCountry', {})
    }
    function validateZipCode(zipCode) {
        return post('postalAddress/ajaxValidateZipCode', {zipCode})
    }
    function getCity(zipCode) {
        return post('postalAddress/ajaxGetCity', {zipCode})
    }
    function getStreetNames(zipCode) {
        return post('postalAddress/ajaxGetStreetNames', {zipCode})
    }
    function getAddressType(totalWeight) {
        return post('order/ajaxUseDeliveryOrPostalAddress', {totalWeight})
    }
    function getRecommendedProducts() {
        return post('articleContainer/ajaxList', {
            limit: 4,
            offset: 0,
            recommended: true
        })
    }
    function createAccount(data) {
        return post('user/ajaxCreateStoreAccount', {data} )
    }
    function sendConfirmationEmail(order) {
        const data = {
            orderNumber: order.orderNumber,
        }
        const regexTest =new RegExp("\\[" + store.getState().language.language.language + "\\].*?\"([\\S\\s]*?)\"")

        data.orderItems = order.orderItems.map( orderItem => {
            const matchName = regexTest.exec(orderItem.name)
            const matchDescription = regexTest.exec(orderItem.description)

            orderItem.name = (matchName && matchName.length > 1) ? matchName[1] : orderItem.name
            orderItem.description = (matchDescription && matchDescription.length > 1) ? matchDescription[1] : orderItem.description
            return orderItem
        })
        if(order.email) {
            data.email = order.email
        }
        return post('order/ajaxSendConfirmationEmail', data)
    }
    function forgotPassword(username) {
        const params = {
            username,
            serverName: window.location.origin
        }
        return post('register/forgotPassword', {}, params, true)
    }
    function resetPassword(data) {
        const params = {
            t: data.t,
            serverName: window.location.origin
        }
        const command = {
            password: data.newPassword,
            password2: data.newPassword
        }
        return post('register/resetPassword', command, params, true)
    }
    function getCampaigns(limit, offset) {
        return post('campaign/ajaxList', {
            limit,
            offset
        })
    }
    function changeNewsletterSetting(allowNewsletter) {
        return  post('customer/ajaxChangeNewsletterSetting', {
            allowNewsletter
        })
    }
    function selectStore(customerNumber) {
        return post('customer/ajaxSelectStore', {
            customerNumber
        })
    }
    function getStoresForCustomer(limit, offset, pattern) {
        return post('customer/ajaxGetStoresForCustomer', {
            limit,
            offset,
            pattern
        })
    }
    function getTotalStoresForCustomer(pattern = "") {
        return post('customer/ajaxGetTotalStoresForCustomer', {
            pattern
        })
    }
    function checkStores() {
        return post('store/ajaxHasStores')
    }
    function requiredInvoiceAddress() {
        const orderItemNumbers = Object.values(store.getState().cart.items).map( cartItem => {
            return cartItem.article.articleNumber
        })
        return post('order/ajaxRequiredInvoiceAddress', {
            orderItemNumbers
        })
    }
    function getArticleCategories() {
        return  post('articleCategory/ajaxGetArticleCategoriesForCustomer', {})
    }
    function getAdministrativeFee() {
        return post('order/ajaxGetAdministrativeFee', {})
    }

    function getTenantData() {
        return post('tenant/ajaxList', {})
    }
    return {
        login,
        refreshToken,
        getProducts,
        getProduct,
        getArticles,
        getImages,
        getRelated,
        checkLogin,
        getCustomerData,
        getTotal,
        createOrder,
        cancelOrder,
        getOrders,
        getOrder,
        getOrderItems,
        getEmail,
        changeEmail,
        changePassword,
        getArticleGroups,
        getDeliveryCost,
        getCountries,
        getInstallationCountry,
        validateZipCode,
        getCity,
        getStreetNames,
        changeAddress,
        getAddressType,
        getRecommendedProducts,
        createAccount,
        getSearchResults,
        sendConfirmationEmail,
        forgotPassword,
        resetPassword,
        getCampaigns,
        changeNewsletterSetting,
        selectStore,
        getStoresForCustomer,
        getTotalStoresForCustomer,
        requiredInvoiceAddress,
        getArticleCategories,
        checkStores,
        getAdministrativeFee,
        getTenantData
    }
})();

export default ApiConnector;
