import React from 'react';
import {connect} from "react-redux";
import _ from 'lodash';
import Slick from 'react-slick';
import { Link } from 'react-router-dom';
import {IMAGE_PATH, SERVER_URL} from "../config/config";
import i18next from "i18next";
import { Icon } from 'antd';
import ReactHtmlParser from 'react-html-parser';


const Campaigns = (props) => {
    if(_.isEmpty(props.data)) {
        return null
    }

    return (
        <React.Fragment>
                {
                    _.map(props.data, item => {
                        const regexTest = new RegExp("\\[" + props.language + "\\].*?\"([\\S\\s]*?)\"")

                        const matchName = regexTest.exec(item.name);
                        const matchDescription = regexTest.exec(item.description);

                        const translatedName = (matchName && matchName.length > 1) ? matchName[1] : item.name;
                        const translatedDescription = ReactHtmlParser((matchDescription && matchDescription.length > 1) ? matchDescription[1] : item.description);
                        return (
                            <div key={item.id} className='spot-item'>

                                <div>
                                    <Slick {...sliderSettings}>
                                        {
                                            item.articleContainers.map(container => {
                                                return (
                                                    <Link to={`/article/${container.id}`} key={container.id} onClick={props.onClick} >
                                                        <div className="spot-image">
                                                            <img
                                                                src={`${SERVER_URL}${IMAGE_PATH}${container.popupImageFileName ? container.popupImageFileName : null}`}
                                                                alt={container.name}
                                                                className='spot-img'
                                                            />
                                                        </div>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </Slick>
                                </div>

                                <div className="spot-content">
                                    <h3 className="spot-content__title">
                                        {translatedName}
                                    </h3>
                                    <p className="spot-content__info">
                                        {translatedDescription}
                                    </p>
                                </div>
                            </div>
                        )
                    })
                }
        </React.Fragment>
    )
};

const sliderSettings = {
    dots: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        { breakpoint: 800,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                infinite: false
            }
        }
    ]
};

/** Custom slider arrows */
function NextArrow(props) {
    return (
        <button className="next-thumb" onClick={props.onClick}>
            <svg viewBox="0 0 20 20" width="15px" height="15px">
                <use xlinkHref="#arrow-small-right"/>
            </svg>
        </button>
    );
}
function PrevArrow(props) {
    return (
        <button className="prev-thumb" onClick={props.onClick}>
            <svg viewBox="0 0 20 20" width="15px" height="15px">
                <use xlinkHref="#arrow-small-left"/>
            </svg>
        </button>
    );
}

function mapStateToProps(state) {
    return {
        language: state.language.language.language
    }
}
export default connect(mapStateToProps, null)(Campaigns);