import React from 'react';
import {Field} from "redux-form";
import {textField} from "../../components/form/formFields";
import {connect} from "react-redux";
import i18next from 'i18next';
import {LinkButtonEdit} from '../../components/links/LinkButton';
import {withRouter} from "react-router-dom";
import DeliveryAddressForm from './DeliveryAddressForm';
import PostalAddressForm from './PostalAddressForm';


const Delivery = (props) => {
    let addressName = i18next.t('checkout_page.delivery_address');
    if(props.addressType === 'postal') {
        addressName = i18next.t('checkout_page.post_address');
    }

    return (
        <div className="checkout-step checkout-step--delivery">

            <div className="checkout-step-header">
                <h2 className="checkout-step-header__title">1. {addressName}</h2>
            </div>

            <div className="checkout-step-content">
                <h3 className="checkout-step-content__subtitle">{props.tenantId !== 5 ? i18next.t('checkout_page.delivery_description') : ""}</h3>

                <div className="checkout-step-content-wrapper checkout-step-content-wrapper--card">
                    <div className="checkout-step-content-wrapper__col-two">
                        <div className="field-wrapper">
                        <div style={{display: 'none'}}>
                            <Field
                                    name='deliveryRecipient'
                                    label={i18next.t('checkout_page.recipient')}
                                    placeholder={i18next.t('checkout_page.recipient')}
                                    component={textField}
                                    maxLength="50"
                                    type="text"
                                    readOnly
                                />
                                <Field
                                    name='deliveryStreetName'
                                    label={i18next.t('checkout_page.street_name')}
                                    placeholder={i18next.t('checkout_page.street_name')}
                                    component={textField}
                                    maxLength="50"
                                    type="text"
                                    extraClasses='field-content--50'
                                    readOnly
                                />
                                <Field
                                    name='deliveryStreetNumber'
                                    label={i18next.t('checkout_page.street_number')}
                                    placeholder={i18next.t('checkout_page.street_number')}
                                    component={textField}
                                    maxLength="50"
                                    type="text"
                                    extraClasses='field-content--25'
                                    readOnly
                                />

                                <Field
                                    name='deliveryStreetLetter'
                                    label={i18next.t('checkout_page.street_number_letter')}
                                    placeholder={i18next.t('checkout_page.street_number_letter')}
                                    component={textField}
                                    maxLength="50"
                                    type="text"
                                    extraClasses='field-content--25'
                                    readOnly
                                />
                                <Field
                                    name='deliveryZipCode'
                                    label={i18next.t('checkout_page.postcode')}
                                    placeholder={i18next.t('checkout_page.postcode')}
                                    component={textField}
                                    maxLength="50"
                                    type="text"
                                    extraClasses='field-content--25'
                                    readOnly
                                />

                                <Field
                                    name='deliveryCity'
                                    label={i18next.t('checkout_page.city')}
                                    placeholder={i18next.t('checkout_page.city')}
                                    component={textField}
                                    maxLength="50"
                                    type="text"
                                    extraClasses='field-content--50'
                                    readOnly
                                />
                                <Field
                                    name='deliveryCountry'
                                    label={i18next.t('checkout_page.country')}
                                    placeholder={i18next.t('checkout_page.country')}
                                    component={textField}
                                    maxLength="50"
                                    type="text"
                                    extraClasses='field-content--25'
                                    readOnly
                                />
                            </div>
                            {props.addressTypeDelivery &&
                                <DeliveryAddressForm onAddressEdit={props.onAddressEdit}/>
                            }
                            {!props.addressTypeDelivery &&
                                <PostalAddressForm onAddressEdit={props.onAddressEdit}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
function mapStateToProps(state) {
    return {
        addressType: state.cart.addressType,
        tenantId: state.auth.tenantId
    }
}
export default withRouter(connect(mapStateToProps)(Delivery));
