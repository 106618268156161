import React from 'react';
import BreadCrumbs from './navigation/BreadCrumbs';
import i18next from 'i18next';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

const CookieInfoPage= (props) => {
    return (
        <div className="wrapper">
            <BreadCrumbs pagename={i18next.t('cookie_page.page_name')} />

            <main className="main typeset">

                <div className="main-content main-content--padding-top">

                    <div className="c">
                        {props.tenantId === 5 
                            ? (
                            <article className="main-content__primary">
                                <h1>Verkkopolitiikka ja tietosuoja</h1>
                                <div>
                                    <p>
                                    Tämän verkkokaupan sisällöstä vastaa Loomis Suomi Oy (jäljempänä "Loomis"). Sivuston tarkoituksena on antaa tietoa ja myydä tuotteita tai palveluja. Seuraavassa on tietoja siitä, kuinka henkilötietoja käsitellään Loomis Suomi Oy:n verkkosivuilla.
                                    </p>

                                    <h5><b>Vastuuvapaus</b></h5>

                                    <p>Loomis pyrkii kaikin tavoin antamaan vain luotettavaa ja oikeaa tietoa, mutta se ei silti anna takuuta sivustolla esitettävien tai sitä kautta saavutettavien tietojen oikeellisuudesta tai luotettavuudesta. Yhtiö pyrkii kaikin käytettävissä olevin keinoin keskeytyksettömään toimintaan, mutta ei voi vastata sivujen saatavuudesta. Yhtiö pidättää myös oikeuden tehdä sivustolle muutoksia jatkuvasti.</p>
                                    <p>Sivuilla on linkkejä kolmansien osapuolien omistamille tai ylläpitämille sivuille. Näille siirryttäessä palvelu avaa ulkopuolisen palveluntarjoajan sivuston uuteen selainikkunaan. Tällaisille sivuille siirtyessään käyttäjän on tutustuttava ja hyväksyttävä niiden mahdolliset käyttöehdot ennen käytön aloittamista. Loomis-konsernilla ei ole mahdollisuuksia vaikuttaa niiden sivustojen sisältöön, joihin sen sivuilta on linkkiyhteys, ja jotka on tehty tai julkaistu jonkun muun kuin Loomis-konsernin toimesta eikä yhtiö vastaa niiden sivujen sisällöstä. Tavoitteena on linkittää vain luottaviksi lähteiksi tunnetuille sivuille.</p>
                                    <p>Loomis ei vastaa sivuston ja siellä olevien tietojen käytön välittömästi tai välillisesti aiheuttamista vahingoista.</p>

                                    <h5><b>Tekijänoikeudet</b></h5>
                                    <p>Loomis eStore -sivuston sisältö on suojattu tekijänoikeuslain mukaisesti.</p>
                                    <p>Tekijänoikeudet, oikeudet sivujen ulkoasuun ja tietokantoihin sekä muut immateriaalioikeudet näiden sivujen sisältöön (sisältäen mm. tekstin, kuvat ja äänet) kuuluvat Loomis-konsernille.</p>
                                    <p>Muilta sivustoilta on lupa linkittää Loomis eStore -sivustolle. Tässä edellytetään kuitenkin hyvän tavan noudattamista. Linkitetyn Loomis eStore -sivun pitää näkyä selaimessa kokonaisuudessaan ja samanlaisena kuin Loomis eStore -palvelussa. Vastaamme mielellämme linkitystä koskeviin kysymyksiin.</p>
                                    <p>Sivuja saa katsella, selata ja tulostaa käyttäjän omaa, henkilökohtaista käyttöä varten sekä lähettää sähköpostitse tai jakaa muilla tavoin haluamilleen vastaanottajille.</p>

                                    <h5><b>Evästeet</b></h5>

                                    <p>Loomis eStore käyttää evästeitä ("cookies").</p>
                                    <p>Sivuston käyttäjän tietokoneelle voidaan ajoittain siirtää evästeitä ("cookies"). Eväste on pieni tekstitiedosto, jonka avulla voidaan kerätä tietoja siitä, miten ja milloin sivustoa käytetään: esimerkiksi mitkä sivut ovat suosituimmat ja milloin käyttäjä on niitä selannut. Evästeet eivät vahingoita käyttäjän tietokonetta tai tiedostoja.</p>
                                    <p>Evästeiden käytön tarkoituksena on analysoida ja kehittää sivustoa edelleen käyttäjiä paremmin palveleviksi. Lisäksi palveluntarjoaja ja sen yhteistyökumppanit voivat hyödyntää evästeitä sivuston kävijämäärien tilastollisessa seurannassa.</p>
                                    <p>Käyttäjällä on mahdollisuus estää evästeiden käyttö muuttamalla selaimensa asetuksia. Evästeiden käytön estäminen saattaa vaikuttaa sivuston tai sen osien toiminnallisuuteen.</p>
                                    <p>Lue lisää, kuinka Google käsittelee kerättyjä tietoja täältä.</p>
                                    <p>Näin voit muuttaa evästeiden asetuksia:</p>
                                    <ul>
                                        <li>Internet Explorer 7/8 https://support.microsoft.com/fi-fi/windows/ev%C3%A4steiden-poistaminen-ja-hallinta-168dab11-0753-043d-7c16-ede5947fc64d</li>
                                        <li>Internet Explorer 9 https://support.microsoft.com/fi-fi/windows/ev%C3%A4steiden-poistaminen-ja-hallinta-168dab11-0753-043d-7c16-ede5947fc64d</li>
                                        <li>Google Chrome https://support.google.com/chrome/answer/95647?hl=fi</li>
                                        <li>Mozilla Firefox https://support.mozilla.org/fi/kb/firefox-tyopoytaversion-tehostettu-seurannan-suoja?redirectslug=evasteiden-paalle-ja-poiskytkeminen&redirectlocale=fi</li>
                                        <li>Opera https://help.opera.com/en/latest/web-preferences/#cookies</li>
                                    </ul>
                                    <p></p>

                                    <h5><b>Henkilökohtaiset tiedot</b></h5>

                                    <p>Loomis voi kerätä henkilökohtaisia tietoja, kuten nimiä, osoitteita, puhelinnumeroita ja sähköpostiosoitetta vain, kun rekisteröidyt käyttäjäksi tai muutoin jaat nämä tiedot vapaaehtoisesti. Mitään arkaluonteisia tietoja ei kerätä. Loomis toimii vastuullisesti tällä sivustolla kerättyjen henkilötietojen suhteen.</p>
                                    <p>8Tämän verkkosivuston kautta toimitettuja henkilötietoja voidaan käyttää seuraavasti:</p>
                                    <ul>
                                        <li>Asiakkuuden ylläpitämiseen</li>
                                        <li>Tilausten toimitukseen, käsittelyyn ja arkistointiin</li>
                                        <li>Loomis eStore -sivuston toiminnan ja palveluiden kehittämiseen</li>
                                        <li>Asiakaskokemuksen parantamiseen</li>
                                        <li>Analytiikkaan ja tilastollisiin tarkoituksiin</li>
                                        <li>Henkilökohtaisemman kohdennetun sisällön ja markkinoinnin tuottamiseen</li>
                                        <li>Väärinkäytösten estämiseen</li>
                                        <li>Paremman asiakaspalvelun tarjoamiseen</li>
                                    </ul>
                                    <p>Tietoja käsitellään asiakkaan ja Loomiksen väliseen asiakassuhteeseen, sopimukseen, sivuston käyttöön, asiakkaan erilliseen nimenomaiseen suostumukseen tai lainsäädännön velvoitteisiin perustuen.</p>
                                    <p>Henkilötietoja ei käytetä markkinointitarkoituksiin, ellei meillä ole siihen käyttäjän hyväksyntää.</p>
                                    <p>Vain Loomiksella on pääsy henkilökohtaisiin tietoihisi. Voimme käyttää alihankkijoita henkilötietojen analysointiin ja käsittelyyn sekä verkkosivuston ylläpitoon ja toimintaan, mutta henkilötietoja ei jaeta kolmansien osapuolten kanssa. Henkilökohtaiset tiedot tallennetaan niin kauan kuin vaaditaan noudattamaan tätä tarkoitusta varten määritelty käyttötarkoitus sekä sovellettavan lain asettamat vaatimukset.</p>
                                    <p>Osalle tietoa lainsäädäntö asettaa velvoitteita tiedon pidempiaikaiselle tallentamiselle, mm. seuraaviin tarkoituksiin:</p>
                                    <ul>
                                        <li>Kirjanpitolaki määrittelee tiedolle pidempiä säilytysaikoja riippumatta siitä sisältääkö aineisto henkilötietoja tai ei</li>
                                        <li>Kuluttajakauppaa koskevien vastuiden täyttäminen</li>
                                        <li>Järjestelmien lokitietoa kerätään ja säilytetään lain edellyttämällä tavalla, jotta voimme tarjota lainmukaisen ja turvallisen verkkokaupan asiakkaillemme</li>
                                        <li>Riittävien varmuuskopioiden ottaminen kaupan tietokannoista ja järjestelmistä tietojen turvaamiseksi, virhetilanteiden korjaamiseksi ja tietoturvan sekä jatkuvuuden varmentamiseksi</li>
                                    </ul>
                                    <p>Loomiksella on oikeus muuttaa verkkopolitiikkaansa milloin tahansa, oman harkintansa mukaan. Loomis pidättää itsellään oikeuden muuttaa sivuston sisältöä milloin tahansa.</p>
                                    <p>Jos sinulla on kysyttävää tämän käytännön sisällöstä tai henkilötietojesi käytöstä, ota meihin yhteyttä:</p>
                                    <p>Loomis Suomi Oy<br/>
                                        PL 6000<br/>
                                        01511 Vantaa
                                    </p>
                                    <p>
                                        Tel:0204 30 3000 <br/>
                                    </p>
                                    <p><a href="mailto:fi.info@loomis.com" style={{color: '#C8002A'}}>fi.info@loomis.com</a></p>
                                </div>

                            </article>
                            )
                            : (
                            <article className="main-content__primary">
                                <h1>{i18next.t('privace_policy_page.title')}</h1>
                                <div>
                                    <p>
                                        {i18next.t('privace_policy_page.description')}
                                    </p>

                                    <h5><b>{i18next.t('privace_policy_page.responsibility')}</b></h5>

                                    <p>{i18next.t('privace_policy_page.responsibility_text')}</p>
                                    
                                    <br></br>

                                    <h5><b>{i18next.t('privace_policy_page.process')}</b></h5>
                                    <p>{i18next.t('privace_policy_page.process_text')}</p>
                                    <ul>
                                        <li>{i18next.t('privace_policy_page.list.item_1')}</li>
                                        <li>{i18next.t('privace_policy_page.list.item_2')}</li>
                                        <li>{i18next.t('privace_policy_page.list.item_3')}</li>
                                        <li>{i18next.t('privace_policy_page.list.item_4')}</li>
                                        <li>{i18next.t('privace_policy_page.list.item_5')}</li>
                                        <li>{i18next.t('privace_policy_page.list.item_6')}</li>
                                    </ul>
                                    <p>{i18next.t('privace_policy_page.list_sub')}</p>

                                    <br></br>

                                    <h5><b>{i18next.t('privace_policy_page.data')}</b></h5>

                                    <p>{i18next.t('privace_policy_page.data_text')}</p>

                                    <br></br>

                                    <h5><b>{i18next.t('privace_policy_page.basis')}</b></h5>

                                    <p>{i18next.t('privace_policy_page.basis_text_1')}</p>
                                    <p>{i18next.t('privace_policy_page.basis_text_2')}</p>

                                    <br></br>

                                    <h5><b>{i18next.t('privace_policy_page.cookies')}</b></h5>
                                    <p>{i18next.t('privace_policy_page.cookies_text_1')}</p>
                                    <p>{i18next.t('privace_policy_page.cookies_text_2')}</p>
                                    <br></br>

                                    <p>{i18next.t('privace_policy_page.address.title')}</p>
                                    <p>{i18next.t('privace_policy_page.address.purpose')}</p>
                                    <p>{i18next.t('privace_policy_page.address.service')}</p>
                                    <p>{i18next.t('privace_policy_page.address.expiry')}</p>
                                    <p>{i18next.t('privace_policy_page.address.name')}</p>
                                    <p>{i18next.t('privace_policy_page.address.supplier')}</p>

                                    <br></br>

                                    <p>{i18next.t('privace_policy_page.address_private.purpose')}</p>
                                    <p>{i18next.t('privace_policy_page.address_private.service')}</p>
                                    <p>{i18next.t('privace_policy_page.address_private.expiry')}</p>
                                    <p>{i18next.t('privace_policy_page.address_private.name')}</p>
                                    <p>{i18next.t('privace_policy_page.address_private.supplier')}</p>
                                    
                                    <br></br>

                                    <h5><b>{i18next.t('privace_policy_page.transfer')}</b></h5>
                                    <p>{i18next.t('privace_policy_page.transfer_text_1')}</p>
                                    <p>{i18next.t('privace_policy_page.transfer_text_2')}</p>
                                
                                    <br></br>

                                    <h5><b>{i18next.t('privace_policy_page.storage')}</b></h5>
                                    <p>{i18next.t('privace_policy_page.storage_text')}</p>

                                    <br></br>

                                    <h5><b>{i18next.t('privace_policy_page.rights')}</b></h5>
                                    <p>{i18next.t('privace_policy_page.right_text')}</p>

                                    <br></br>

                                    <h5><u>{i18next.t('privace_policy_page.rights_list.item_1_title')}</u></h5>
                                    <p>{i18next.t('privace_policy_page.rights_list.item_1_text')}</p>
                                    
                                    <br></br>

                                    <h5><u>{i18next.t('privace_policy_page.rights_list.item_2_title')}</u></h5>
                                    <p>{i18next.t('privace_policy_page.rights_list.item_2_text_1')}</p>
                                    <p>{i18next.t('privace_policy_page.rights_list.item_2_text_2')}</p>
                                    <p>{i18next.t('privace_policy_page.rights_list.imy')}</p>

                                    <br></br>

                                    <h5><u>{i18next.t('privace_policy_page.rights_list.item_3_title')}</u></h5>
                                    <p>{i18next.t('privace_policy_page.rights_list.item_3_text')}</p>
                                    <p>{i18next.t('privace_policy_page.rights_list.imy')}</p>

                                    <br></br>

                                    <h5><u>{i18next.t('privace_policy_page.rights_list.item_4_title')}</u></h5>
                                    <p>{i18next.t('privace_policy_page.rights_list.item_4_text')}</p>
                                    <p>{i18next.t('privace_policy_page.rights_list.imy')}</p>

                                    <br></br>

                                    <h5><u>{i18next.t('privace_policy_page.rights_list.item_5_title')}</u></h5>
                                    <p>{i18next.t('privace_policy_page.rights_list.item_5_text')}</p>
                                    <p>{i18next.t('privace_policy_page.rights_list.imy')}</p>

                                    <br></br>

                                    <h5><u>{i18next.t('privace_policy_page.rights_list.item_6_title')}</u></h5>
                                    <p>{i18next.t('privace_policy_page.rights_list.item_6_text')}</p>
                                    <p>{i18next.t('privace_policy_page.rights_list.imy')}</p>

                                    <br></br>

                                    <h5><u>{i18next.t('privace_policy_page.rights_list.item_7_title')}</u></h5>
                                    <p>{i18next.t('privace_policy_page.rights_list.item_7_text')}</p>
                                    <p>{i18next.t('privace_policy_page.rights_list.imy')}</p>

                                    <br></br>

                                    <h5><u>{i18next.t('privace_policy_page.rights_list.item_8_title')}</u></h5>
                                    <p>{i18next.t('privace_policy_page.rights_list.item_8_text')}</p>
        
                                    <br></br>

                                    <h5><u>{i18next.t('privace_policy_page.rights_list.item_9_title')}</u></h5>
                                    <p>{i18next.t('privace_policy_page.rights_list.item_9_text')}</p>
                                    <p>{i18next.t('privace_policy_page.rights_list.imy')}</p>

                                    <br></br>

                                    <h5><u>{i18next.t('privace_policy_page.rights_list.item_10_title')}</u></h5>
                                    <p>{i18next.t('privace_policy_page.rights_list.item_10_text')}</p>
                        
                                    <br></br>

                                    <h5><b>{i18next.t('privace_policy_page.other')}</b></h5>
                                    <p>{i18next.t('privace_policy_page.other_text')}</p>

                                    <br></br>
                                    
                                    <h5><b>{i18next.t('privace_policy_page.contact')}</b></h5>
                                    <p>{i18next.t('privace_policy_page.contact_text')}</p>

                                    <br></br>
                                    <p>{i18next.t('privace_policy_page.contact_address.company')}</p>
                                    <p>{i18next.t('privace_policy_page.contact_address.street')}</p>
                                    <p>{i18next.t('privace_policy_page.contact_address.post_code_city')}</p>
                                    <p>{i18next.t('privace_policy_page.contact_address.country')}</p>
                                    
                                    <br></br>
                                    <p>{i18next.t('privace_policy_page.contact_address.phone')}</p>
                                    <p>{i18next.t('privace_policy_page.contact_address.fax')}</p>
                                    <p>{i18next.t('privace_policy_page.contact_address.email')}</p>

                                    <br></br>
                                    <p>{i18next.t('privace_policy_page.end_text')}</p>
                                </div>

                            </article>
                          )
                        }

                    </div>

                </div>
            </main>

        </div>
    )
}
function mapStateToProps(state) {
    return {
        publicTenant: state.auth.publicTenant,
        tenantId: state.auth.tenantId
    }
}
export default withRouter(connect(mapStateToProps, null) (CookieInfoPage) );