import React, {Component} from 'react';
import {textField, checkBox, radioButton} from "../../components/form/formFields";
import {Field} from "redux-form";
import {Radio} from 'antd';
import i18next from 'i18next';

class GeneralInfo extends Component {
    constructor(props)  {
        super(props);

        this.renderCorporateIdField = this.renderCorporateIdField.bind(this);
        this.renderCompanyNameField = this.renderCompanyNameField.bind(this);
    }


    renderCorporateIdField() {
        if (this.props.customerType === 'business') {
            return (
                <div className="field-wrapper settings-form">
                    <h3 className="field-wrapper__title">{i18next.t('create_account.orgnumber')}</h3>
                    <p className="caption">{i18next.t('create_account.orgnumber_description')}</p>
                    <Field
                        name='corporateId'
                        label={i18next.t('create_account.orgnumber')}
                        placeholder={i18next.t('create_account.orgnumber')}
                        component={textField}
                        maxLength="50"
                        type="text"
                    />
                </div>
            )
        }
        return null
    }

    renderCompanyNameField() {
        if (this.props.customerType === 'business') {
            return (
                <div className="field-wrapper settings-form">
                    <h3 className="field-wrapper__title">{i18next.t('create_account.company')}</h3>
                    <p className="caption">{i18next.t('create_account.company_description')}</p>
                    <Field
                        name='companyName'
                        label={i18next.t('create_account.company')}
                        placeholder={i18next.t('create_account.company')}
                        component={textField}
                        maxLength="50"
                        type="text"
                    />
                </div>
            )
        } else if (this.props.customerType === 'consumer') {
            return (
                <div className="field-wrapper settings-form">
                    <h3 className="field-wrapper__title">{i18next.t('create_account.name')}</h3>
                    <p className="caption">{i18next.t('create_account.name_description')}</p>
                    <Field
                        name='companyName'
                        label={i18next.t('create_account.name')}
                        placeholder={i18next.t('create_account.name')}
                        component={textField}
                        maxLength="50"
                        type="text"
                    />
                </div>
            )
        }
        return null
    }

    render() {
        return (
            <div className="general-information">
                <Radio.Group>
                    <Field
                        name="customerType"
                        component={radioButton}
                        value="business"
                        description={i18next.t('create_account.business-customer')}
                        type="radio"
                        checked={this.props.customerType === 'business'}
                    />
                    {
                        this.props.businessPrivateToggle
                            ? (
                                <Field
                                    name="customerType"
                                    component={radioButton}
                                    value="consumer"
                                    description="Consumer"
                                    type="radio"
                                    checked={this.props.customerType === 'consumer'}
                                />
                            )
                            : null
                    }
                </Radio.Group>
                <h3 className="general-information__subtitle">{i18next.t('create_account.general_info')}</h3>
                <div className="field-wrapper settings-form">

                    <h3 className="field-wrapper__title">{i18next.t('create_account.email')}</h3>
                    <p className="caption">{i18next.t('create_account.email_description')}</p>

                    <Field
                        name='email'
                        label={i18next.t('create_account.email')}
                        placeholder={i18next.t('create_account.email')}
                        component={textField}
                        maxLength="50"
                        type="email"
                    />
                </div>

                <div className="field-wrapper settings-form">
                    <h3 className="field-wrapper__title">{i18next.t('create_account.password')}</h3>
                    <p className="caption">{i18next.t('create_account.password_description')}</p>
                    <Field
                        name='password'
                        label={i18next.t('create_account.password')}
                        placeholder={i18next.t('create_account.password')}
                        component={textField}
                        maxLength="50"
                        type="password"
                    />
                    <Field
                        name='repeatPassword'
                        label={i18next.t('create_account.repeat_password')}
                        placeholder={i18next.t('create_account.repeat_password')}
                        component={textField}
                        maxLength="50"
                        type="password"
                    />

                </div>
                {this.renderCompanyNameField()}
                {this.renderCorporateIdField()}
                <div className="field-wrapper settings-form">
                    <h3 className="field-wrapper__title">{i18next.t('create_account.newsletter')}</h3>
                    <p className="caption bold">{i18next.t('create_account.newsletter_label')}</p>
                    <Field
                        name='newsletter'
                        label={i18next.t('create_account.offers')}
                        defaultCheck={true}
                        component={checkBox}
                    />
                </div>
            </div>
        )
    }
}
export default GeneralInfo;
