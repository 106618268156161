import React from 'react';
import {connect} from "react-redux";
import {login, showForgottenPassModal} from "../../actions/actions";
import {Field, reduxForm} from "redux-form";
import {textField} from "../../components/form/formFields";
import { Alert } from 'antd';
import {Link, withRouter} from 'react-router-dom';
import i18next from 'i18next';
import ForgottenPassModal from '../../components/ForgottenPassModal';

const validate = (values) => {
    const errors = {};
    if(!values.username) {
        errors.username = i18next.t('errors.required')
    }
    if(!values.password) {
        errors.password = i18next.t('errors.required')
    }
    return errors
}

let Login = (props) => {
    function renderStyles() {
        let styles = '';
        if(!props.publicTenant) {
            styles = {
                margin: '0 auto',
                float: 'none',
                textAlign: 'center'
            }
        } else {
            return null
        }
        return styles;
    }

    function login(credentials) {
        return props.login(credentials.username, credentials.password).then(() => {
            props.history.push('/')
        })
    }

    const {handleSubmit, submitting, error} = props;
    return (
        <div className="my-account-login__col-one" style={renderStyles()}>
            <h2 className="h5">{i18next.t('loginpage.login_title')}</h2>
            <p className="caption">
                {i18next.t('loginpage.login_text')}
            </p>
            <form onSubmit={handleSubmit(login)}>
                <div className="my-account-login-action">
                    { error && <Alert
                        message={i18next.t('notification.error')}
                        description={error}
                        type="error"
                        style={{marginBottom: '10px'}}
                    /> }
                    <Field
                        name='username'
                        placeholder={i18next.t('loginpage.username')}
                        label={i18next.t('loginpage.username')}
                        component={textField}
                        type='text'
                    />
                    <Field
                        name='password'
                        placeholder={i18next.t('loginpage.password')}
                        label={i18next.t('loginpage.password')}
                        component={textField}
                        type='password'
                    />
                    <div className="field-content">
                        <button disabled={submitting} type="submit" className="field-content__button">{i18next.t('loginpage.login_button')}</button>
                    </div>
                    <div className="field-content">
                        <Link
                            onClick={props.showForgottenPassModal}
                            to='#'
                            className='link'>
                            {i18next.t('loginpage.forgot_password')}
                        </Link>
                    </div>
                </div>
            </form>
            <ForgottenPassModal />
        </div>
    )
};

function mapStateToProps(state) {
    return {
        language: state.language.language.language,
        publicTenant: state.auth.publicTenant
    }
}

Login = withRouter(connect (mapStateToProps, {login, showForgottenPassModal})(Login));
export default reduxForm({
    form: 'login',
    validate,
})(Login);