import React from 'react';
import {change, Field, formValueSelector} from "redux-form";
import {reduxForm} from "redux-form";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { LinkButton, LinkButtonEdit, LinkButtonSave } from '../../components/links/LinkButton';
import {selectBox, textField, numberField} from "../../components/form/formFields";
import {changeAddress, SET_STREETS} from '../../actions/actions';
import ApiConnector from "../../api/apiConnection";
import store from './../../store/store';
import i18next from 'i18next';
import {Alert} from "antd";

const validate = (values) => {
    const errors = {}
    if(!values.postalRecipient) {
        errors.postalRecipient = i18next.t('errors.required')
    }
    if(!values.postalStreetName) {
        errors.postalStreetName = i18next.t('errors.required')
    }
    if(!values.postalZipCode) {
        errors.postalZipCode = i18next.t('errors.required')
    }
    if(!values.postalCity) {
        errors.postalCity = i18next.t('errors.required')
    }
    if(!values.postalCountry) {
        errors.postalCountry = i18next.t('errors.required')
    }
    return errors
}

const asyncValidate = (values, dispatch) => {
    const state = store.getState()
    const addressValidation = (selector(state, 'postalCountry') && selector(state, 'postalCountry').toLowerCase() === state.address.installationCountry.toLowerCase())
    if(addressValidation) {
        return ApiConnector.validateZipCode(values.postalZipCode)
            .then(response => {
                if (response.data === true) {
                    ApiConnector.getCity(values.postalZipCode)
                        .then(response => {
                            dispatch(change('postalAddress', 'postalCity', response.data))
                        })
                    ApiConnector.getStreetNames(values.postalZipCode)
                        .then(response => {
                            dispatch({
                                type: SET_STREETS,
                                streets: response.data
                            })
                            if(response.data && response.data.length > 0) {
                                dispatch(change('postalAddress', 'postalStreetName', response.data[0]))
                            }
                        })
                } else {
                    dispatch(change('postalAddress', 'postalCity', ''))
                    dispatch({
                        type: SET_STREETS,
                        streets: []
                    })
                    throw {postalZipCode: 'Invalid'}
                }
                dispatch(change('postalAddress', 'postalStreetNumber', ''))
                dispatch(change('postalAddress', 'postalStreetLetter', ''))
            })
    } else {
        return Promise.resolve()
            .then(() => {
                if(!values.postalZipCode) {
                    throw {postalZipCode: 'Required'}
                }
            })
    }
}

class ChangePostalAddress extends React.Component {
    constructor(props) {
        super(props);
        this.changePostalAddress = this.changePostalAddress.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.renderButtons = this.renderButtons.bind(this);
        this.renderPostCode = this.renderPostCode.bind(this);
        this.state = {
            edit: false
        }
    }
    toggleEdit() {
        if(!this.state.edit && this.props.streets && this.props.addressValidation) {
            ApiConnector.validateZipCode(this.props.initialValues.postalZipCode)
                .then(response => {
                    if (response.data === true) {
                        ApiConnector.getCity(this.props.initialValues.postalZipCode)
                            .then(response => {
                                store.dispatch(change('postalAddress', 'postalCity', response.data))
                            })
                        ApiConnector.getStreetNames(this.props.initialValues.postalZipCode)
                            .then(response => {
                                store.dispatch({
                                    type: SET_STREETS,
                                    streets: response.data
                                })
                            })
                    }
                })
        } else {
            this.props.reset()
        }
        this.setState({edit: !this.state.edit})
    }
    changePostalAddress(values) {
        this.toggleEdit();
        const postalAddress = {
            recipient: values.postalRecipient,
            streetName: values.postalStreetName,
            streetNumber: values.postalStreetNumber,
            streetLetter: values.postalStreetLetter,
            zipCode: values.postalZipCode,
            city: values.postalCity,
            country: values.postalCountry
        };
        if(!postalAddress.streetNumber) {
            postalAddress.streetNumber = null
        }
        store.dispatch({
            type: SET_STREETS,
            streets: []
        });
        if(this.props.location === '/checkout') {
            this.props.reset();
            return this.props.changeAddress('postalAddress', postalAddress).then(() => {
                this.props.resetLocation();
                this.props.history.push('/checkout');
            })
        } else {
            this.props.reset();
            return this.props.changeAddress('postalAddress', postalAddress)
        }
    }
    renderButtons() {
        if(this.state.edit) {
            return (
                <div>
                    <LinkButtonSave
                        title={i18next.t('account.information.save')}
                        extraClasses='save-button'/>
                    <LinkButton
                        path="#"
                        title={i18next.t('account.information.cancel')}
                        onClick={this.toggleEdit} />
                </div>
            )

        } else {
            return  <LinkButtonEdit
                path='#'
                title={i18next.t('account.information.edit')}
                extraClasses='edit-button'
                onClick={this.toggleEdit}
            />
        }
    }
    renderPostCode() {
        if(!this.state.edit || this.props.country) {
            return <Field
                name='postalZipCode'
                label={i18next.t('account.information.postcode')}
                placeholder={i18next.t('account.information.postcode')}
                component={textField}
                maxLength="50"
                type="text"
                extraClasses='field-content--25'
                readOnly = {!this.state.edit}
                autocomplete="off"
            />
        }
    }
    renderCityAndStreet() {
        if(!this.state.edit || this.props.city || (this.props.country && !this.props.addressValidation)) {
            let options = this.props.streets
            let streetComponent = selectBox
            if( (this.props.streets.length === 0 && this.props.initialValues) || !this.state.edit || !this.props.addressValidation) {
                streetComponent = textField
                options = [this.props.initialValues.postalStreetName]
            }
            return <React.Fragment>
                <Field
                    name='postalCity'
                    label={i18next.t('account.information.city')}
                    placeholder={i18next.t('account.information.city')}
                    component={textField}
                    maxLength="50"
                    type="text"
                    extraClasses='field-content--50'
                    readOnly = {this.props.addressValidation || !this.state.edit}
                />
                <Field
                    name='postalStreetName'
                    label={i18next.t('account.information.street_name')}
                    placeholder={i18next.t('account.information.street_name')}
                    component={streetComponent}
                    options={options}
                    maxLength="50"
                    type="text"
                    extraClasses='field-content--50'
                    readOnly = {!this.state.edit}
                />
                <Field
                    name='postalStreetNumber'
                    label={i18next.t('account.information.street_number')}
                    placeholder={i18next.t('account.information.street_number')}
                    component={numberField}
                    maxLength="50"
                    extraClasses='field-content--25'
                    readOnly = {!this.state.edit}
                />

                <Field
                    name='postalStreetLetter'
                    label={i18next.t('account.information.street_number_letter')}
                    placeholder={i18next.t('account.information.street_number_letter')}
                    component={textField}
                    maxLength="50"
                    type="text"
                    extraClasses='field-content--25'
                    readOnly = {!this.state.edit}
                />
            </React.Fragment>
        }
    }
    render() {
        const {handleSubmit, error} = this.props;
        return (
            <React.Fragment>
                <form onSubmit={handleSubmit(this.changePostalAddress)}>
                    <div className="field-wrapper">
                        <h3 className="field-wrapper__title">{i18next.t('account.information.recipient')}</h3>
                        <Field
                            name='postalRecipient'
                            label={i18next.t('account.information.recipient')}
                            placeholder={i18next.t('account.information.recipient')}
                            component={textField}
                            maxLength="50"
                            type="text"
                            extraClasses='field-content--100'
                            readOnly = {!this.state.edit}
                        />
                    </div>
                    <div className="field-wrapper field-wrapper--margin-top">
                        <h3 className="field-wrapper__title">{i18next.t('account.information.address')}</h3>
                        <Field
                            name='postalCountry'
                            label={i18next.t('account.information.country')}
                            placeholder={i18next.t('account.information.country')}
                            component={selectBox}
                            options={this.props.countries}
                            extraClasses='field-content--25'
                            readOnly = {!this.state.edit || !this.props.publicTenant}
                        />
                        {this.renderPostCode()}
                        {this.renderCityAndStreet()}
                    </div>
                    { error && <Alert
                        message="Error"
                        description={error}
                        type="error"
                        style={{marginTop: '10px'}}
                        showIcon
                    /> }
                    {this.renderButtons()}
                </form>
            </React.Fragment>
        )
    }
}
const selector = formValueSelector('postalAddress')
function mapStateToProps(state) {

    let initialValues = {};

    if(state.customer.postalAddress) {
        const {postalAddress} = state.customer;

        initialValues = {...initialValues,
            postalRecipient: postalAddress.recipient ? postalAddress.recipient : '',
            postalStreetName: postalAddress.streetName ? postalAddress.streetName : '',
            postalStreetNumber: postalAddress.streetNumber ? postalAddress.streetNumber : '',
            postalStreetLetter: postalAddress.streetLetter ? postalAddress.streetLetter : '',
            postalZipCode: postalAddress.zipCode ? postalAddress.zipCode : '',
            postalCity: postalAddress.city ? postalAddress.city : '',
            postalCountry: postalAddress.country ? postalAddress.country.replace('Sverige', 'Sweden') : ''
        }
    } else {
        initialValues = {
            ...initialValues,
            postalCountry: 'Sweden'
        }
    }
    const addressValidation = (selector(state, 'postalCountry') && selector(state, 'postalCountry').toLowerCase() === state.address.installationCountry.toLowerCase())
    return {
        initialValues,
        country: selector(state, 'postalCountry'),
        postCode: selector(state, 'postalZipCode'),
        streetName: selector(state, 'postalStreetName'),
        countries: state.address.countries,
        streets: state.address.streets,
        city: selector(state, 'postalCity'),
        addressValidation,
        publicTenant: state.auth.publicTenant
    }
}

ChangePostalAddress = reduxForm({
    form: 'postalAddress',
    validate,
    asyncValidate,
    enableReinitialize: true,
    asyncChangeFields: ['postalZipCode']
})(ChangePostalAddress);
export default withRouter( connect(mapStateToProps, {changeAddress})(ChangePostalAddress) );
