import React, {Component} from 'react';
import {change, Field, formValueSelector} from "redux-form";
import {reduxForm, reset} from "redux-form";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {selectBox, textField, numberField} from "../../components/form/formFields";
import {showPostalAddressModal, changeAddress, SET_STREETS, getCountries} from '../../actions/actions';
import ApiConnector from "../../api/apiConnection";
import store from '../../store/store';
import i18next from 'i18next';
import {Card, Alert, Button} from "antd";


const validate = (values) => {
    const errors = {}
    if(!values.postalRecipient) {
        errors.postalRecipient = i18next.t('errors.required')
    }
    if(!values.postalStreetName) {
        errors.postalStreetName = i18next.t('errors.required')
    }
    if(!values.postalZipCode) {
        errors.postalZipCode = i18next.t('errors.required')
    }
    if(!values.postalCity) {
        errors.postalCity = i18next.t('errors.required')
    }
    if(!values.postalCountry) {
        errors.postalCountry = i18next.t('errors.required')
    }
    return errors
}

const asyncValidate = (values, dispatch) => {
    const state = store.getState();
    const addressValidation = (selector(state, 'postalCountry') && selector(state, 'postalCountry').toLowerCase() === state.address.installationCountry.toLowerCase())
    if(addressValidation) {
        return ApiConnector.validateZipCode(values.postalZipCode)
            .then(response => {
                if (response.data === true) {
                    ApiConnector.getCity(values.postalZipCode)
                        .then(response => {
                            dispatch(change('postalAddress', 'postalCity', response.data))
                        })
                    ApiConnector.getStreetNames(values.postalZipCode)
                        .then(response => {
                            dispatch({
                                type: SET_STREETS,
                                streets: response.data
                            })
                            if(response.data && response.data.length > 0 && !values.postalStreetName) {
                                dispatch(change('postalAddress', 'postalStreetName', response.data[0]))
                            }
                        })
                } else {
                    dispatch(change('postalAddress', 'postalCity', ''))
                    dispatch({
                        type: SET_STREETS,
                        streets: []
                    })
                    throw {postalZipCode: 'Invalid'}
                }
                 dispatch(change('postalAddress', 'postalStreetNumber', ''))
                 dispatch(change('postalAddress', 'postalStreetLetter', ''))
            })
    } else {
        return Promise.resolve()
            .then(() => {
                if(!values.postalZipCode) {
                    throw {postalZipCode: 'Required'}
                }
            })
    }
}

class PostalAddressForm extends Component {
    constructor(props) {
        super(props);
        this.changePostalAddress = this.changePostalAddress.bind(this);
        this.renderPostCode = this.renderPostCode.bind(this);
        this.cancelModal = this.cancelModal.bind(this);
    }


    componentDidUpdate(prevProps, props) {
        if(prevProps.isProcessing === true && this.props.isProcessing === false) {
            this.props.getCountries();
        }
    }


    cancelModal() {
        this.props.reset();
        this.props.showPostalAddressModal();
    }

    async changePostalAddress(values) {
        const postalAddress = {
            recipient: values.postalRecipient,
            streetName: values.postalStreetName,
            streetNumber: values.postalStreetNumber,
            streetLetter: values.postalStreetLetter,
            zipCode: values.postalZipCode,
            city: values.postalCity,
            country: values.postalCountry
        };
        if(!postalAddress.streetNumber) {
            postalAddress.streetNumber = null
        }
        store.dispatch({
            type: SET_STREETS,
            streets: []
        });
        const changeAddress = () => {
            return this.props.changeAddress('postalAddress', postalAddress);
        };
        await changeAddress();
        this.cancelModal();
    }

    renderPostCode() {
        if(this.props.country) {
            return <Field
                name='postalZipCode'
                label={`${i18next.t('account.information.postcode')}*`}
                placeholder={`${i18next.t('account.information.postcode')}*`}
                component={textField}
                maxLength="50"
                type="text"
                extraClasses='field-content--25'
                autocomplete="off"
            />
        }
    }

    renderCityAndStreet() {
        if(this.props.city || (this.props.country && !this.props.addressValidation)) {
            let options = this.props.streets;
            let streetComponent = selectBox;
            if( (this.props.streets.length === 0 && this.props.initialValues) || !this.props.addressValidation) {
                streetComponent = textField
                options = [this.props.initialValues.postalStreetName]
            }
            return <React.Fragment>
                <Field
                    name='postalCity'
                    label={`${i18next.t('account.information.city')}*`}
                    placeholder={`${i18next.t('account.information.city')}*`}
                    component={textField}
                    maxLength="50"
                    type="text"
                    extraClasses='field-content--50'
                />
                <Field
                    name='postalStreetName'
                    label={`${i18next.t('account.information.street_name')}*`}
                    placeholder={`${i18next.t('account.information.street_name')}*`}
                    component={streetComponent}
                    options={options}
                    maxLength="50"
                    type="text"
                    extraClasses='field-content--50'
                />
                <Field
                    name='postalStreetNumber'
                    label={i18next.t('account.information.street_number')}
                    placeholder={i18next.t('account.information.street_number')}
                    component={numberField}
                    maxLength="25"
                    extraClasses='field-content--25'
                />

                <Field
                    name='postalStreetLetter'
                    label={i18next.t('account.information.street_number_letter')}
                    placeholder={i18next.t('account.information.street_number_letter')}
                    component={textField}
                    maxLength="50"
                    type="text"
                    extraClasses='field-content--25'
                />
            </React.Fragment>
        }
    }

    render() {
        const {handleSubmit, error} = this.props;
        return (
            <Card title={i18next.t('checkout_page.post_address')}>
                <form onSubmit={handleSubmit(this.changePostalAddress)} id="postalAddressModal">
                    <div className="field-wrapper">
                        <h3 className="field-wrapper__title">{i18next.t('account.information.recipient')}*</h3>
                        <Field
                            name='postalRecipient'
                            label={`${i18next.t('account.information.recipient')}*`}
                            placeholder={`${i18next.t('account.information.recipient')}*`}
                            component={textField}
                            maxLength="50"
                            type="text"
                            extraClasses='field-content--100'
                        />
                    </div>
                    <div className="field-wrapper">
                        <h3 className="field-wrapper__title">{i18next.t('account.information.address')}*</h3>
                        <Field
                            name='postalCountry'
                            label={`${i18next.t('account.information.country')}*`}
                            placeholder={`${i18next.t('account.information.country')}*`}
                            component={selectBox}
                            options={this.props.countries}
                            extraClasses='field-content--25'
                            readOnly = {!this.props.publicTenant}
                        />
                        {this.renderPostCode()}
                        {this.renderCityAndStreet()}
                    </div>
                    <div>
                        { error && <Alert
                            message="Error"
                            description={error}
                            type="error"
                            style={{marginTop: '10px'}}
                            showIcon
                        /> }
                          <div style={{marginTop: 10}}>
                          <Button key="back" onClick={this.cancelModal}>{i18next.t('checkout_page.cancel')}</Button>
                          <Button key="submit" type="primary" onClick={handleSubmit(this.changePostalAddress)}>
                            {i18next.t('checkout_page.save')}
                          </Button>
                        </div>
                    </div>
                </form>
            </Card>
        )
    }
}

const selector = formValueSelector('postalAddress')
function mapStateToProps(state) {

    let initialValues = {};

    if(state.customer.postalAddress) {
        const {postalAddress} = state.customer;

        initialValues = {...initialValues,
            postalRecipient: postalAddress.recipient ? postalAddress.recipient : '',
            postalStreetName: postalAddress.streetName ? postalAddress.streetName : '',
            postalStreetNumber: postalAddress.streetNumber ? postalAddress.streetNumber : '',
            postalStreetLetter: postalAddress.streetLetter ? postalAddress.streetLetter : '',
            postalZipCode: postalAddress.zipCode ? postalAddress.zipCode : '',
            postalCity: postalAddress.city ? postalAddress.city : '',
            postalCountry: postalAddress.country ? postalAddress.country : ''
        }
    } else {
        initialValues = {
            ...initialValues,
            postalCountry: state.address.installationCountry
        }
    }
    const addressValidation = (selector(state, 'postalCountry') && selector(state, 'postalCountry').toLowerCase() === state.address.installationCountry.toLowerCase())
    return {
        initialValues,
        country: selector(state, 'postalCountry'),
        postCode: selector(state, 'postalZipCode'),
        streetName: selector(state, 'postalStreetName'),
        countries: state.address.countries,
        streets: state.address.streets,
        city: selector(state, 'postalCity'),
        addressValidation,
        showModal: state.layout.showPostalAddressModal,
        publicTenant: state.auth.publicTenant,
        isProcessing: state.auth.isProcessing
    }
}

PostalAddressForm = reduxForm({
    form: 'postalAddress',
    validate,
    asyncValidate,
    enableReinitialize: true,
    asyncChangeFields: ['postalZipCode']
})(PostalAddressForm);
export default withRouter( connect(mapStateToProps, {showPostalAddressModal, getCountries, changeAddress})(PostalAddressForm) );

