import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../components/navigation/BreadCrumbs';
import {LinkButtonLeft} from '../../components/links/LinkButton';
import OrderSummary from '../cartPage/OrderSummary';
import Delivery from './Delivery';
import Shipping from './Shipping';
import Invoice from './Invoice';
import Message from './Message';
import Newsletter from './Newsletter';
import InvoiceAddressForm from './InvoiceAddressForm';
import Contact from './Contact';
import store from './../../store/store'
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {formValueSelector,  reduxForm, change} from "redux-form";
import _ from 'lodash'
import {acceptNewsletter, getAddressType, getShippingCost, placeOrder, showPostalAddressModal, showDeliveryAddressModal, showInvoiceAddressModal, SET_ADDRESS_TYPE, checkNumberOfForms, getCountries} from "../../actions/actions";
import i18next from 'i18next';
import {Alert} from 'antd';
import PrintOnForms from './PrintOnForms';

const validate = (values, props) => {
    const errors = {};
    if(!values.deliveryContactPerson) {
        errors.deliveryContactPerson = i18next.t('errors.required');
    }
    if(!values.deliveryContactPhoneNumber) {
        errors.deliveryContactPhoneNumber = i18next.t('errors.required')
    }
    if(!values.deliveryContactEmail) {
        errors.deliveryContactEmail = i18next.t('errors.required')
    }

    if(!values.electronicInvoiceSystemAddress && props.tenantId === 5 && props.invoiceTypeSelect === 'E-INVOICE') {
        errors.electronicInvoiceSystemAddress = i18next.t('errors.required')
    }
    if(!values.electronicOperator && props.tenantId === 5 && props.invoiceTypeSelect === 'E-INVOICE') {
        errors.electronicOperator = i18next.t('errors.required')
    }

    if(!values.emailInvoiceAddress && props.tenantId === 5 && props.invoiceTypeSelect === 'PDF') {
        errors.emailInvoiceAddress = i18next.t('errors.required')
    }

    if (props.adminOrderFormType === 'standard' && (props.numberOfForms && props.numberOfForms > 0)) {
        if (!values.bankAccountNumber) {
            errors.bankAccountNumber = i18next.t('errors.required');
        }
        // if (!values.formMessage) {
        //     errors.formMessage = i18next.t('errors.required');
        // }
        if(props.tenantId !== 5) {
            if (!values.referenceNumberFixed  && values.referenceNumberType === '1') {
                errors.referenceNumberFixed = i18next.t('errors.required');
            }
        }
        if (!values.referenceNumberStart && values.referenceNumberType === '2') {
            errors.referenceNumberStart = i18next.t('errors.required');
        }
        if (!values.referenceNumberInterval && values.referenceNumberType === '2') {
            errors.referenceNumberInterval = i18next.t('errors.required');
        }
    }

    return errors
};

const onChange = (values, dispatch, props, previousValues) => {
        if(values.copyDelivery) {
            dispatch( change('order', 'invoiceRecipient', values.deliveryRecipient) )
            dispatch( change('order', 'invoiceStreetName', values.deliveryStreetName) )
            dispatch( change('order', 'invoiceStreetNumber', values.deliveryStreetNumber) )
            dispatch( change('order', 'invoiceStreetLetter', values.deliveryStreetLetter) )
            dispatch( change('order', 'invoiceZipCode', values.deliveryZipCode) )
            dispatch( change('order', 'invoiceCity', values.deliveryCity) )
            dispatch( change('order', 'invoiceCountry', values.deliveryCountry) )
            dispatch( change('order', 'copyDelivery', values.copyDelivery) )

        } else {
            dispatch( change('order', 'invoiceRecipient', props.initialValues.invoiceRecipient) )
            dispatch( change('order', 'invoiceStreetName', props.initialValues.invoiceStreetName) )
            dispatch( change('order', 'invoiceStreetNumber', props.initialValues.invoiceStreetNumber) )
            dispatch( change('order', 'invoiceStreetLetter', props.initialValues.invoiceStreetLetter) )
            dispatch( change('order', 'invoiceZipCode', props.initialValues.invoiceZipCode) )
            dispatch( change('order', 'invoiceCity', props.initialValues.invoiceCity) )
            dispatch( change('order', 'invoiceCountry', props.initialValues.invoiceCountry) )
            dispatch( change('order', 'copyDelivery', props.initialValues.copyDelivery) )
        }
       // dispatch( change('order', 'referenceNumberType', values.referenceNumberType) )
    }

class CheckoutPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            incompleteOrder: false,
            errorMessage: [],
            alert: false,
        };
        this.createOrder = this.createOrder.bind(this);
        this.renderMessage = this.renderMessage.bind(this);
    }

    /** Check total weight for Loomis tenant only and set {addressType} depends on the weight.
     * Otherwise {addressType} should always equal 'delivery'
     * */
    componentDidMount() {
        if(!this.props.publicTenant) {
            if(Object.keys(this.props.cart).length > 0) {
                const totalWeight = _.map(this.props.cart, cartItem => {
                    return cartItem.amount * (cartItem.article.weight)
                })
                    .reduce((a, b) => {
                        return a + b
                    }, 0)
                this.props.getAddressType(totalWeight)
            } else {
                store.dispatch({
                    type: SET_ADDRESS_TYPE,
                    addressType: 'postal'
                })
            }
        } else {
            store.dispatch({
                type: SET_ADDRESS_TYPE,
                addressType: 'delivery'
            })
        }

        this.props.checkNumberOfForms(this.props.cart);
        this.props.getCountries();
    }

    /** Render warning if delivery, postal or invoice address is missing */
    renderWarning() {
        const {tenant,
            addressType,
            deliveryAddress,
            postalAddress,
            showDeliveryAddressModal,
            requiredInvoiceAddress,
            showInvoiceAddressModal,
            guest,
            loggedIn} = this.props;

        const invoiceAddress = _.has(this.props.invoiceAddress, 'recipient');

        /** Render delivery address warning for Loomis and Public tenants */
        if (loggedIn && !guest && addressType === 'delivery' && !deliveryAddress) {
            const deliveryAddressDescription = () => {
                return (
                    <p>
                        {i18next.t('errors.deliveryAddressDescription')}
                        {/* <Link to='#' onClick={() => showDeliveryAddressModal()} style={{fontSize: '14px', color: '#C8002A'}}>
                            {i18next.t('checkout_page.edit_link')}
                        </Link> */}
                    </p>
                )
            }
            return <Alert
                message={i18next.t('errors.deliveryAddressMessage')}
                description={deliveryAddressDescription()}
                type="warning"
                className='checkAddressAlert'
                showIcon
            />
        }
        /** Render postal address warning for Loomis tenant only */
        else if (!this.props.publicTenant && addressType === 'postal' && !postalAddress) {
            const postAddressDescription = () => {
                return (
                    <p>
                        {i18next.t('errors.postAddressDescription')}
                        <Link to='#' onClick={() => this.props.showPostalAddressModal()} style={{fontSize: '14px', color: '#C8002A'}}>
                            {i18next.t('checkout_page.edit_link')}
                        </Link>
                    </p>
                )
            }
            return <Alert
                message={i18next.t('errors.postAddressMessage')}
                description={postAddressDescription()}
                type="warning"
                className='checkAddressAlert'
                showIcon
            />
        }
        /** Render invoice address warning for Loomis and Public tenants */
        else if (!guest && requiredInvoiceAddress && !invoiceAddress) {
            const invoiceAddressDescription = () => {
                return (
                    <p>
                        {i18next.t('errors.invoiceAddressDescription')}
                        <Link to='#' onClick={() => showInvoiceAddressModal()} style={{fontSize: '14px', color: '#C8002A'}}>
                            {i18next.t('checkout_page.edit_link')}
                        </Link>
                    </p>
                )
            };
            return <Alert
                message={i18next.t('errors.invoiceAddressMessage')}
                description={invoiceAddressDescription()}
                type="warning"
                className='checkAddressAlert'
                showIcon
            /> }
        return null
    }

    createOrder(values) {
        const data = {
            deliveryAddress: {
                recipient: values.deliveryRecipient,
                streetName: values.deliveryStreetName,
                streetNumber: values.deliveryStreetNumber,
                streetLetter: values.deliveryStreetLetter,
                zipCode: values.deliveryZipCode,
                city: values.deliveryCity,
                country: values.deliveryCountry,
                contactPerson: values.deliveryContactPerson,
                contactPhoneNumber: values.deliveryContactPhoneNumber,
                contactNotificationInformation: values.deliveryContactEmail
            },
            postalAddress: {
                recipient: values.postalRecipient,
                streetName: values.postalStreetName,
                streetNumber: values.postalStreetNumber,
                streetLetter: values.postalStreetLetter,
                zipCode: values.postalZipCode,
                city: values.postalCity,
                country: values.postalCountry,
                contactPerson: values.postalContactPerson,
                contactPhoneNumber: values.postalContactPhoneNumber,
                contactNotificationInformation: values.postalContactEmail
            },
            invoiceAddress: {
                recipient: values.invoiceRecipient,
                streetName: values.invoiceStreetName,
                streetNumber: values.invoiceStreetNumber,
                streetLetter: values.invoiceStreetLetter,
                zipCode: values.invoiceZipCode,
                city: values.invoiceCity,
                country: values.invoiceCountry,
                invoiceReference: values.invoiceReference,
                emailInvoiceAddress: values.emailInvoiceAddress,
                organisationNumber: values.organisationNumber,
                gln: values.gln,
                electronicInvoiceSystemAddress: values.electronicInvoiceSystemAddress,
                electronicOperator: values.electronicOperator
            },
            articles: Object.values(_.mapValues(this.props.cart, (value) => {
                return {
                    articleNumber: value.article.articleNumber,
                    amount: value.amount
                }
            }))
        };
        data.message = '';
        let hasError = false;
        const messages = [];
        if(!data.deliveryAddress.recipient) {
            hasError = true;
            messages.push(`${i18next.t('notification.no_delivery_addr')}`);
        }
        /** Prevent function if invoice address required but missing */
        if (!this.props.itemsInCart) {
            hasError = true;
            messages.push(`${i18next.t('notification.empty_cart')}`);
        }
        /** Prevent function if invoice address required, but missing */
        if (!data.invoiceAddress.recipient && this.props.requiredInvoiceAddress) {
            hasError = true;
            messages.push(`${i18next.t('notification.no_invoice_addr')}`);
        }
        /** Prevent function if eInvoice option is not selected */
        if (!this.props.emailInvoiceAddress && (this.props.corporateIdentityNumber || this.props.organisationNumber) && !this.props.eInvoiceSelected && this.props.requiredInvoiceAddress && this.props.tenantId !== 6) {
            hasError = true;
            messages.push(`${i18next.t('notification.no_einvoice_accept')}`);
        }

        if (!hasError) {
            this.setState({
                incompleteOrder: false,
                errorMessage: []
            })
        } else {
            this.setState({
                incompleteOrder: true,
                errorMessage: messages
            });
            return null;
        }

        /** Don't send invoice address if it's not required */
        if (!this.props.requiredInvoiceAddress) {
            data.invoiceAddress = null
        }
        /** Don't send postal address if it's a guest user */
        if (this.props.guest) {
            data.postalAddress = null;
        }
        if (values.includePDFInvoice) {
            data.invoiceAddress.emailInvoiceAddress = values.emailInvoiceAddress
        }
        if (values.includeMessage) {
            data.message = values.message
        }
        if(this.props.administrativeFee){
            data.hasAdministrativeFee = true;
        }

        if (this.props.tenantId === 5) {
            if (this.props.invoiceTypeSelect === 'E-INVOICE') {
                data.invoiceAddress.emailInvoiceAddress = null;
            }
            if (this.props.invoiceTypeSelect === 'PDF') {
                data.invoiceAddress.electronicInvoiceSystemAddress = null;
                data.invoiceAddress.electronicOperator = null;
            }
        }

        if (this.props.adminOrderFormType === 'standard' && (this.props.numberOfForms && this.props.numberOfForms > 0)) {
            data.printContactInformation = values.printContactInformation;
            data.bankAccountNumber = values.bankAccountNumber;
            data.formMessage = values.formMessage;
            data.referenceNumberType = values.referenceNumberType;
            if (values.referenceNumberType === '1') {
                data.referenceNumberFixed = values.referenceNumberFixed;
            }

            if (values.referenceNumberType === '2') {
                data.referenceNumberStart = values.referenceNumberStart;
                data.referenceNumberInterval = values.referenceNumberInterval;
                data.referenceNumberInternational = values.referenceNumberInternational;
            }
        }

        data.premiumSelected = this.props.premiumSelected;
        data.allowNewsletter = values.allowNewsletter;
        return this.props.placeOrder(data, this.props.history);
    }
    /** Don't render message section if it's Loomis tenant */
    renderMessage() {
        if (!this.props.publicTenant || this.props.tenantId === 5) {
            return null
        }
        return <Message includeMessage={this.props.includeMessage} />
    }

    getLabel = (k) => {
        switch (k) {
            case 'deliveryZipCode': {
                return i18next.t('checkout_page.delivery_address') +" - " +i18next.t('account.information.postcode')
            }
            case 'deliveryCity': {
                return i18next.t('checkout_page.delivery_address') +" - " +i18next.t('account.information.city')
            }
            case 'deliveryStreetName': {
                return i18next.t('checkout_page.delivery_address') +" - " +i18next.t('account.information.street_name')
            }
            case 'deliveryStreetNumber': {
                return i18next.t('checkout_page.delivery_address') +" - " +i18next.t('account.information.street_number')
            }
            case 'deliveryStreetLetter': {
                return i18next.t('checkout_page.delivery_address') +" - " +i18next.t('account.information.street_number_letter')
            }
            case 'deliveryRecipient': {
                return i18next.t('checkout_page.delivery_address') +" - " +i18next.t('account.information.recipient')
            }
            case 'deliveryCountry': {
                return i18next.t('checkout_page.delivery_address') +" - " +i18next.t('account.information.country')
            }
            case 'postalZipCode': {
                return i18next.t('checkout_page.post_address') +" - " +i18next.t('account.information.postcode')
            }
            case 'postalCity': {
                return i18next.t('checkout_page.post_address') +" - " +i18next.t('account.information.city')
            }
            case 'postalStreetName': {
                return i18next.t('checkout_page.post_address') +" - " +i18next.t('account.information.street_name')
            }
            case 'postalStreetNumber': {
                return i18next.t('checkout_page.post_address') +" - " +i18next.t('account.information.street_number')
            }
            case 'postalStreetLetter': {
                return i18next.t('account.information.street_number_letter')
            }
            case 'postalRecipient': {
                return i18next.t('checkout_page.post_address') +" - " +i18next.t('account.information.recipient')
            }
            case 'postalCountry': {
                return i18next.t('checkout_page.post_address') +" - " +i18next.t('account.information.country')
            }
            case 'deliveryContactPerson': {
                return i18next.t('checkout_page.contact_person')
            }
            case 'deliveryContactPhoneNumber': {
                return i18next.t('checkout_page.contact_person_phone')
            }
            case 'deliveryContactEmail': {
                return i18next.t('checkout_page.contact_person_email')
            }
            case 'invoiceZipCode': {
                return i18next.t('checkout_page.payment') +" - " +i18next.t('account.information.postcode')
            }
            case 'invoiceCity': {
                return i18next.t('checkout_page.payment') +" - " +i18next.t('account.information.city')
            }
            case 'invoiceStreetName': {
                return i18next.t('checkout_page.payment') +" - " +i18next.t('account.information.street_name')
            }
            case 'invoiceStreetNumber': {
                return i18next.t('checkout_page.payment') +" - " +i18next.t('account.information.street_number')
            }
            case 'invoiceStreetLetter': {
                return i18next.t('checkout_page.payment') +" - " +i18next.t('account.information.street_number_letter')
            }
            case 'invoiceRecipient': {
                return i18next.t('checkout_page.payment') +" - " +i18next.t('account.information.recipient')
            }
            case 'invoiceCountry': {
                return i18next.t('checkout_page.payment') +" - " +i18next.t('account.information.country')
            }
            case 'bankAccountNumber': {
                return i18next.t('checkout_page.print-on-forms.title') +" - " +i18next.t('checkout_page.print-on-forms.bankAccountNumber')
            }
            case 'formMessage': {
                return i18next.t('checkout_page.print-on-forms.title') +" - " +i18next.t('checkout_page.print-on-forms.formMessage')
            }
            case 'referenceNumberType': {
                return i18next.t('checkout_page.print-on-forms.title') +" - " +i18next.t('checkout_page.print-on-forms.referenceNumberType')
            }
            case 'referenceNumberFixed': {
                return i18next.t('checkout_page.print-on-forms.title') +" - " +i18next.t('checkout_page.print-on-forms.referenceNumberFixed')
            }
            case 'referenceNumberStart': {
                return i18next.t('checkout_page.print-on-forms.title') +" - " +i18next.t('checkout_page.print-on-forms.referenceNumberStart')
            }
            case 'referenceNumberInternational': {
                return i18next.t('checkout_page.print-on-forms.title') +" - " +i18next.t('checkout_page.print-on-forms.referenceNumberInternational')
            }
            default: {
                return k;
            }
        }
    }

    render() {
        const {handleSubmit,
            error,
            addressType,
            showPostalAddressModal,
            showDeliveryAddressModal,
            showInvoiceAddressModal,
            premiumSelected,
            allowNewsletter} = this.props;

        return (
            <div className="wrapper">
                <BreadCrumbs pagename={i18next.t('checkout_page.page_name')}/>
                <div className="categories-nav">
                    <div className="category-nav">
                        <LinkButtonLeft path='#' title={i18next.t('breadcrumbs.link_back')} onClick={() => this.props.history.push('/')}/>
                    </div>
                </div>
                <main className="main typeset">
                    <div className="c">
                        <div className="page-header">
                            <div className="page-header__col-one">
                                <h1 className="page-header__title">{i18next.t('checkout_page.checkout')}</h1>
                                <p className="page-header__product-count">( {i18next.t('plurals.keyWithCount', {count: this.props.itemsInCart})} )</p>
                            </div>
                        </div>

                        <div className="main-content">
                            <div className="main-content__primary">
                                <div className="checkout">
                                    {
                                        this.state.alert &&
                                            this.renderWarning()
                                    }

                                    <form onSubmit={handleSubmit(this.createOrder)}>
                                        <Delivery addressTypeDelivery={addressType === 'postal' ? false : true}/>
                                        <Contact />

                                        <Shipping premiumSelected={premiumSelected} currency={this.props.currency} />
                                        <Invoice onAddressEdit={showInvoiceAddressModal} tenantId={this.props.tenantId} />

                                        { this.renderMessage() }
                                        {
                                            (this.props.adminOrderFormType === 'standard' && (this.props.numberOfForms && this.props.numberOfForms > 0))  ? (
                                                <PrintOnForms referenceNumberType={this.props.referenceNumberType} />
                                            ):null
                                        }

                                        { this.props.submitFailed && this.props.forms ?
                                            <>
                                                {
                                                    Object.values(this.props.forms).map(f => {
                                                        console.log(f)
                                                        if (f.syncErrors) {
                                                            return Object.keys(f.syncErrors).map(k => {
                                                                const label = this.getLabel(k);
                                                                return <Alert
                                                                    message={`${label}: ${f.syncErrors[k]}`}
                                                                    type="error"
                                                                    style={{marginBottom: '10px'}}
                                                                />
                                                            })
                                                        }
                                                        return null;
                                                    })
                                                }
                                            </>: null
                                        }
                                        { this.state.incompleteOrder ?
                                            <>{
                                                this.state.errorMessage.map(m => {
                                                   return <Alert
                                                        message={m}
                                                        type="error"
                                                        style={{marginBottom: '10px'}}
                                                    />
                                                })
                                            }</>
                                             : null
                                        }
                                        {
                                            error ? (<Alert
                                                message={i18next.t('notification.error')}
                                                description={error}
                                                type="error"
                                                style={{marginBottom: '10px'}}
                                            /> ):null
                                        }
                                        <Newsletter allowNewsletter={allowNewsletter} />
                                    </form>
                                </div>
                            </div>
                            <aside className="main-content__sidebar">
                                <OrderSummary key={new Date().getTime()} currency={this.props.currency} />
                            </aside>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
};


const selector = formValueSelector('order')
function mapStateToProps(state) {
    let initialValues = {
        invoiceTypeSelect: "E-INVOICE"
    }
    if(state.cart.addressType === 'postal' && state.customer.postalAddress) {
        const {postalAddress} = state.customer;
        initialValues = {...initialValues,
            deliveryRecipient: postalAddress.recipient ? postalAddress.recipient : '',
            deliveryStreetName: postalAddress.streetName ? postalAddress.streetName : '',
            deliveryStreetNumber: postalAddress.streetNumber ? postalAddress.streetNumber : '',
            deliveryStreetLetter: postalAddress.streetLetter ? postalAddress.streetLetter : '',
            deliveryZipCode: postalAddress.zipCode ? postalAddress.zipCode : '',
            deliveryCity: postalAddress.city ? postalAddress.city : '',
            deliveryCountry: postalAddress.country ? postalAddress.country : '',
        }
    } else if(state.cart.addressType === 'delivery' && state.customer.deliveryAddress) {
        const {deliveryAddress} = state.customer;
        initialValues = {...initialValues,
            deliveryRecipient: deliveryAddress.recipient ? deliveryAddress.recipient : '',
            deliveryStreetName: deliveryAddress.streetName ? deliveryAddress.streetName : '',
            deliveryStreetNumber: deliveryAddress.streetNumber ? deliveryAddress.streetNumber : '',
            deliveryStreetLetter: deliveryAddress.streetLetter ? deliveryAddress.streetLetter : '',
            deliveryZipCode: deliveryAddress.zipCode ? deliveryAddress.zipCode : '',
            deliveryCity: deliveryAddress.city ? deliveryAddress.city : '',
            deliveryCountry: deliveryAddress.country ? deliveryAddress.country : ''
        }
    }
    if(state.customer.invoiceAddress) {
        const {invoiceAddress} = state.customer;
        let eInvoice = "NO";
        if(state.cart.eInvoice){
            eInvoice = "YES";
        }
        initialValues = {
            ...initialValues,
            invoiceRecipient: invoiceAddress.recipient ? invoiceAddress.recipient : '',
            invoiceStreetName: invoiceAddress.streetName ? invoiceAddress.streetName : '',
            invoiceStreetNumber: invoiceAddress.streetNumber ? invoiceAddress.streetNumber : '',
            invoiceStreetLetter: invoiceAddress.streetLetter ? invoiceAddress.streetLetter : '',
            invoiceZipCode: invoiceAddress.zipCode ? invoiceAddress.zipCode : '',
            invoiceCity: invoiceAddress.city ? invoiceAddress.city : '',
            invoiceCountry: invoiceAddress.country ? invoiceAddress.country: '',
            invoiceReference: invoiceAddress.invoiceReference ? invoiceAddress.invoiceReference : '',
            emailInvoiceAddress: invoiceAddress.emailInvoiceAddress ? invoiceAddress.emailInvoiceAddress : '',
            gln: invoiceAddress.gln ? invoiceAddress.gln: '',
            electronicInvoiceSystemAddress: invoiceAddress.electronicInvoiceSystemAddress ? invoiceAddress.electronicInvoiceSystemAddress : '',
            electronicOperator: invoiceAddress.electronicOperator ? invoiceAddress.electronicOperator : '',
            payment: "INVOICE",
            eInvoice,
            eInvoiceSelect: "YES",

        }
    }

    if (state.auth.adminOrderFormType === 'standard' && state.numberOfForms.numberOfForms > 0) {
        initialValues = {
            ...initialValues,
            referenceNumberType:  '',
            referenceNumberInterval: 1,
            ... (state.auth.tenantId === 5 ? { payment: "INVOICE"}: {})
        };
    }
    initialValues.allowNewsletter = state.customer.allowNewsletter;
    const emailInvoiceAddress = selector(state, 'emailInvoiceAddress');
    const organisationNumber = selector(state, 'organisationNumber');
    const copyDelivery = selector(state, 'copyDelivery');
    return {
        itemsInCart: state.cart.itemsInCart,
        initialValues,
        includeMessage: selector(state, 'includeMessage'),
        cart: state.cart.items,
        premiumSelected: state.cart.premiumSelected,
        deliveryAddress: state.customer.deliveryAddress,
        postalAddress: state.customer.postalAddress,
        invoiceAddress: state.customer.invoiceAddress,
        addressType: state.cart.addressType,
        publicTenant: state.auth.publicTenant,
        allowNewsletter: state.customer.allowNewsletter,
        requiredInvoiceAddress: state.cart.requiredInvoiceAddress,
        guest: state.auth.guest,
        administrativeFee: state.cart.administrativeFee,
        eInvoice: state.cart.eInvoice,
        eInvoiceSelected: state.cart.eInvoiceSelected,
        emailInvoiceAddress: emailInvoiceAddress,
        corporateIdentityNumber: state.customer.corporateIdentityNumber,
        organisationNumber: organisationNumber,
        currency: state.auth.currency,
        vat: state.auth.vat,
        isProcessing: state.auth.isProcessing,
        loggedIn: state.auth.loggedIn,
        tenantId: state.auth.tenantId,
        adminOrderFormType: state.auth.adminOrderFormType,
        referenceNumberInterval: selector(state, 'referenceNumberInterval'),
        referenceNumberType: selector(state, 'referenceNumberType'),
        invoiceTypeSelect: selector(state, 'invoiceTypeSelect'),
        numberOfForms: state.numberOfForms.numberOfForms,
        forms: state.form
    }
}

CheckoutPage = reduxForm({
    form: 'order',
    validate,
    onChange,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(CheckoutPage);
export default withRouter( connect(mapStateToProps, {placeOrder, getShippingCost, getAddressType, acceptNewsletter, showPostalAddressModal, showDeliveryAddressModal, showInvoiceAddressModal, SET_ADDRESS_TYPE, checkNumberOfForms, getCountries})(CheckoutPage) );

