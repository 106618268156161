import React, { Component } from 'react';
import i18next from 'i18next';
import {connect} from "react-redux";
import {getSearchValue} from "../actions/actions";

class SearchForm extends Component {

    onSubmit(evt) {
        evt.preventDefault()
        this.props.getSearchValue(this.searchInput.value);
        this.searchInput.value = '';
        this.props.history.push('/results');
    }

    render() {
        return(
            <form method="get" className="search-form" action="#" onSubmit={(e) => this.onSubmit(e)}>
                <input
                    className="search-field"
                    id="s" type="text"
                    role='search'
                    placeholder={i18next.t('header.search')}
                    ref={(input) => {this.searchInput = input}}
                />
                <button className="search-submit">
                    <span className="search-form__screen-reader-text">Sök</span>
                    <svg className="help-nav-list-icon__svg" viewBox="0 0 20 20">
                        <use xlinkHref="#magnifier"/>
                    </svg>
                </button>
            </form>
        );
    }
}
function mapStateToProps(state) {
    return {
        searchValue: state.searchValue
    }
}
export default connect(mapStateToProps, {getSearchValue})(SearchForm);