import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'

import auth from './reducer_auth'
import products from './reducer_products'
import cart from './reducer_cart'
import customer from './reducer_customer'
import address from './reducer_address'
import language from './reducer_language'
import layout from './reducer_layout'
import searchValue from './reducer_search';
import numberOfForms from './numberOfForms';

const rootReducer = combineReducers({
    auth,
    products,
    cart,
    customer,
    address,
    language,
    layout,
    searchValue,
    numberOfForms,
    form: formReducer,
});
export default rootReducer;
