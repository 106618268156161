import React from 'react';
import {withRouter} from 'react-router-dom';
import i18next from 'i18next';
import {connect} from "react-redux";
import {LinkButton} from './links/LinkButton';
import HomeSlider from './HomeSlider';

const HomeBanner = (props) => {
    const {tenantId} = props;
    return (
        <div className={`hero ${tenantId === 5 ? 'hero--solid':''}`}>
            <div className='hero-content'>
                <div className='hero-content__inner'>
                    <h1 className="hero-content__title">{i18next.t(`homepage-${props.tenantId}.banner_title`)}</h1>
                    <p className="hero-content__info">{i18next.t(`homepage-${props.tenantId}.banner_description`)}</p>
                        <LinkButton title={i18next.t(`homepage-${props.tenantId}.banner_btn`)} path={`/shop/${props.defaultArticleGroup}`} />
                    {props.tenantId === 4 ?
                        <div className='hero-content__certificate'>
                            <a id="celink716" href="http://www.ehandelscertifiering.se/" target="_blank" certifierad>ehandel</a>
                        </div> :
                        null}
                </div>
            </div>
            <div className="hero-image">
                <HomeSlider publicTenant={props.publicTenant} tenantId={props.tenantId} />
            </div>
        </div>
    )
};
function mapStateToProps(state) {
    return {
        publicTenant: state.auth.publicTenant,
        tenantId: state.auth.tenantId,
        defaultArticleGroup: state.customer.defaultArticleGroup
    }
}
export default withRouter( connect(mapStateToProps, null)(HomeBanner) );
