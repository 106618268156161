import React from 'react';
import Logo from '../img/logo.jpg';
import LogoLoomis from '../img/Loomis.png'

export const Spin = (props) => {
    const {tenantId} = props;
    
    return (
        <div className="ipl-progress-indicator" id="ipl-progress-indicator">
            <div className="ipl-progress-indicator-head">
                <div className="first-indicator"></div>
                <div className="second-indicator"></div>
            </div>
            <div className="insp-logo-frame">
                <img src={tenantId === 5 ? LogoLoomis : Logo} className="logo__img insp-logo-frame-img" />
            </div>
        </div>
    )
}