import React from 'react';
import {connect} from "react-redux";
import BreadCrumbs from './navigation/BreadCrumbs';
import { Divider } from 'antd';
import i18next from "i18next";

const ContactPage = (props) => {
    return (
        <div className="wrapper">
            <BreadCrumbs pagename={i18next.t(`contact_page-${props.tenantId}.page_name`)} />

            <main className="main typeset">

                <div className="main-content main-content--padding-top">

                    <div className="c">
                        <div className="contact-info__wrapper">
                            <div className="contact-info__col">
                                <div className="info-item">
                                    {props.tenantId !== 5 ?
                                     <p>
                                        Loomis eStore AB<br/>
                                        Tallvägen 2<br/>
                                        151 38 SÖDERTÄLJE<br/>
                                        SWEDEN
                                    </p>
                                    :
                                    <p>
                                        Loomis Suomi Oy<br/>
                                        PL 6000<br/>
                                        01511 VANTAA<br/>
                                        FINLAND
                                    </p>
                                }
                                </div>
                            </div>

                            <div className="contact-info__col">
                                <div className="info-item">
                                    <h4>{i18next.t(`contact_page-${props.tenantId}.phone_service`)}</h4>
                                    <p>
                                    <b>{props.tenantId === 5 ? '+358 20 430 1151' : '08-522 247 70'}</b><br/>
                                        {i18next.t(`contact_page-${props.tenantId}.opening_hours`)}
                                    </p>
                                </div>

                                <div className="info-item">
                                    <h4>{i18next.t(`contact_page-${props.tenantId}.email_service`)}</h4>
                                    <p>
                                        <a href={`mailto: ${props.tenantId === 5 ? 'fi.asiakaspalvelu@loomis.com' : 'les.kundtjanst@loomis.com'}`} style={{'color': '#C8002A'}}>{props.tenantId === 5 ? 'fi.asiakaspalvelu@loomis.com' : 'les.kundtjanst@loomis.com'}</a><br/>
                                       {props.tenantId !== 5 &&
                                            i18next.t(`contact_page-${props.tenantId}.reply`)
                                       }
                                    </p>
                                    <Divider />
                                </div>

                                <div className="info-item">
                                    <p><b>{i18next.t(`contact_page-${props.tenantId}.registration_number`)}</b></p>
                                    <p><b>{i18next.t(`contact_page-${props.tenantId}.vat_number`)}</b></p>
                                    {props.tenantId !== 5 &&
                                        <a 
                                            href="https://www.bisnodegroup.com/about-bisnode/what-we-do/our-approach-to-risk-and-credit/credit-rating-model-company/"
                                            target="_blank" 
                                            style={{'textDecoration': 'none'}}
                                        >
                                            <img style={{'border': '0px'}}
                                                onContextMenu="return false"
                                                title="Our company is credit worthy according to Bisnode's credit assessment system that is based on a number of decision rules. This credit rating is updated on a daily basis&#44; and always shows the current rating and date."
                                                alt="Our company is credit worthy according to Bisnode's credit assessment system that is based on a number of decision rules. This credit rating is updated on a daily basis&#44; and always shows the current rating and date."
                                                id="img_273_73_px"
                                                src="https://merit.soliditet.se/merit/imageGenerator/display?lang=EN&country=SE&cId=7GJN6q0%2B2AeFzs3nZ7ccHw%3D%3D&cUid=32IkA1WhxmI%3D&imgType=img_273_73_px"/>
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </main>
        </div>
    )
};

function mapStateToProps(state) {
    return {
        tenantId: state.auth.tenantId
    }
}
export default connect(mapStateToProps, null) (ContactPage);