import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import Lightbox from 'react-image-lightbox';
import {SERVER_URL, IMAGE_PATH, FILE_PATH} from '../config/config';
import {withRouter} from 'react-router-dom';
import {addToCart, showSideCart} from "../actions/actions";
import {connect} from "react-redux";
import { Collapse, InputNumber } from 'antd';
import i18next from 'i18next';
import Linkify from 'linkifyjs/react';
import ReactHtmlParser from 'react-html-parser';
import 'react-image-lightbox/style.css';
// import "../style/img-gallery.css";

class Article extends Component  {
    constructor(props)  {
        super(props);
        this.state = {
            amount: 1,
            photoIndex: 0,
            isOpen: false,
            articleNumber: props.item.articles.length > 0 ? props.item.articles[0].articleNumber : null,
            price: props.item.articles.length > 0 ? props.item.articles[0].price : ""
        };
        this.changeAmount = this.changeAmount.bind(this);
        this.addToCart = this.addToCart.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        const { item } = nextProps;
        if(!item) {
            return null
        }
        if(item.articles.length > 0) {

            this.setState({
                price: item.articles[0].price,
                articleNumber: item.articles[0].articleNumber,
            })
        }
    }

    changeAmount(value) {
        this.setState({amount: value});
    }

    changeArticle(article) {
        this.setState({
            articleNumber: article.articleNumber,
            price: article.price
        })
    }
    addToCart() {
        const {item} = this.props
        const article = item.articles.find( article => {
            return article.articleNumber === this.state.articleNumber
        })
        this.props.addToCart(item, article, this.state.amount);

        this.props.showSideCart(true);

    }

    render() {
        const { item } = this.props;

        if(!item) {
            return null
        }

        const images = item.images.map(img => {
            return {original: `${SERVER_URL}${IMAGE_PATH}${img.fileName}`,  thumbnail: `${SERVER_URL}${IMAGE_PATH}${img.fileName}`}
        });

        const that = this;
        const fileList = item.attachedFiles.map( attachedFile => {
            return <li>
                <a href={SERVER_URL + FILE_PATH + item.id + "_" + attachedFile} target="_blank">{attachedFile}</a>
            </li>
        });

        const quantityList = item.articles.map (article => {
            let classes = "product-choices-list__item"
            if(article.articleNumber === that.state.articleNumber) {
                classes = "product-choices-list__item product-choices-list__item--active"
            }
            return <li className={classes} onClick={() => that.changeArticle(article)} key={article.articleNumber}>
                <span>{article.quantity}</span>
            </li>
        });

        const regexTest = new RegExp("\\[" + this.props.language + "\\].*?\"([\\S\\s]*?)\"")

        const matchName = regexTest.exec(item.name);
        const matchDescription = regexTest.exec(item.description);
        const matchDetail = regexTest.exec(item.detailedDescription);
        const matchSpecification = regexTest.exec(item.specification);

        const translatedName = (matchName && matchName.length > 1) ? matchName[1] : item.name
        const translatedDescription = ReactHtmlParser((matchDescription && matchDescription.length > 1) ? matchDescription[1] : item.description);
        const translatedDetail = ReactHtmlParser((matchDetail && matchDetail.length > 1) ? matchDetail[1] : item.detailedDescription);
        const translatedSpecification = ReactHtmlParser((matchSpecification && matchSpecification.length > 1) ? matchSpecification[1] : item.specification);

        const { photoIndex, isOpen } = this.state;
        const imagesLightBox = item.images.map(img => {
            return `${SERVER_URL}${IMAGE_PATH}${img.fileName}`
        });
        return (
            <article className="product">

                <div className="product-image">
                    <ImageGallery
                        items={images}
                        originalClass='product-image__img'
                        thumbnailClass='product-primary-content-image-thumbnail__img'
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showNav={true}
                        onClick={() => this.setState({isOpen: true})}
                        renderLeftNav = {(onClick) => {
                            return (
                                <button className="prev-thumb" onClick={onClick}>
                                    <svg className="prev-thumb__svg">
                                        <use xlinkHref="#arrow-left-circle"/>
                                    </svg>
                                </button>
                            )}
                        }
                        renderRightNav = {(onClick) => {
                            return (
                                <button className="next-thumb" onClick={onClick}>
                                    <svg className="prev-thumb__svg">
                                        <use xlinkHref="#arrow-right-circle"/>
                                    </svg>
                                </button>
                            )}
                        }
                    />
                </div>

                {isOpen && (
                    <Lightbox
                        mainSrc={imagesLightBox[photoIndex]}
                        nextSrc={ images.length > 1 ? imagesLightBox[(photoIndex + 1) % images.length]  : null }
                        prevSrc={ images.length > 1 ? imagesLightBox[(photoIndex + images.length - 1) % imagesLightBox.length] : null}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + imagesLightBox.length - 1) % imagesLightBox.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % imagesLightBox.length,
                            })
                        }
                    />
                )}

                <div className="product-content">

                    <h1 className="product-content__title">
                        {translatedName}
                    </h1>
                    <Linkify tagName="h2" className='product-content__subtitle'>{translatedDescription}</Linkify>
                    <div className="product-choices">
                        <h2 className="product-choices__title">{i18next.t('article_page.select_package')}</h2>
                        <ul className="product-choices-list">
                            {quantityList}
                        </ul>

                        <p className="product-choices__part"><b>Art. nr.</b> {this.state.articleNumber}</p>
                    </div>

                    <div className="product-actions">
                        <h2 className="product-actions__title">{i18next.t('article_page.select_amount')}</h2>

                        <div className="product-actions-inner">
                            <div className="product-actions__col-one">
                                <InputNumber
                                    className="product-actions__amount"
                                    id="#"
                                    pattern="\d*"
                                    value={this.state.amount}
                                    onChange={this.changeAmount}
                                    min={1}
                                />
                                <p className="product-actions__current-price">
                                {this.props.privateCustomerType ? this.state.price * `1.${this.props.vat}` : this.state.price} {this.props.currency}
                                </p>
                            </div>

                            <div className="product-actions__col-two" style={{background: '#C8002A'}} onClick={this.addToCart}>
                                <svg className="product-actions__icon">
                                    <use xlinkHref="#cart"/>
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div className="product-information">
                        <Collapse bordered={false} defaultActiveKey={['1']}>
                            {translatedDetail ?
                            <Collapse.Panel header={i18next.t('article_page.description')} key="1">
                                <p>
                                    {translatedDetail}
                                </p>
                            </Collapse.Panel> : null}
                            {fileList.length > 0 ?
                            <Collapse.Panel header={i18next.t('article_page.related')} key="2">
                               <ul>
                                   {fileList}
                               </ul>
                            </Collapse.Panel> : null}
                            {translatedSpecification ?
                            <Collapse.Panel header={i18next.t('article_page.specification')} key="3">
                                <p>
                                    {translatedSpecification}
                                </p>
                            </Collapse.Panel> : null }
                        </Collapse>
                    </div>

                </div>
            </article>
        )
    }
};

function mapStateToProps(state) {
    return {
        language: state.language.language.language,
        currency: state.auth.currency,
        privateCustomerType: state.customer.privateCustomerType,
        vat: state.auth.vat,
    }
}

export default withRouter(connect(mapStateToProps, {addToCart, showSideCart})(Article) );
