import React, {Component} from 'react';
import BreadCrumbs from '../components/navigation/BreadCrumbs';
import {Route, Switch} from 'react-router-dom';
import {getArticleCategories} from '../actions/actions';
import {connect} from "react-redux";
import i18next from "i18next";
import {Collapse, Button} from 'antd';
import _ from 'lodash';
import ProductsByGroup from './ProductsByGroup';

/** Component representing a shop page with article containers */
class ShopPage extends Component {
    constructor(props){
        super(props);

        this.onGroupChange = this.onGroupChange.bind(this);
    }

    componentWillMount() {
        // const link = ShopPage.querySelector('li.main-nav-list__item-shop > a');
        // link.setAttribute('aria-current', 'true');
        // link.classList.add('active');

    }

    /** Render appropriate shop names in the breadcrumbs */
    renderShopName() {
        const LoomisShop = i18next.t(`header.shop_name-${this.props.tenantId}`);

        return LoomisShop  
    }

    /** Checkbox trigger function
     * @params {number} groupId - The article group ID
     *
     * This function redirects to the {pathname} and saves group ID to react router local state.
     * If passed {groupId} equals {this.props.history.location.state.groupId} this article group will be selected.
     *
     * See {@link https://reacttraining.com/react-router/core/api/history}
     */

    onGroupChange(groupId) {
        this.props.history.push({
            pathname: `/shop/${groupId}`,
            state: {
                groupId: groupId
            }
        });
        window.scrollTo(0, 0)
    };

    /** Render article categories and groups */
    renderArticleCategories() {
        const groupId = this.props.history.location.pathname.substr(this.props.history.location.pathname.lastIndexOf("/") + 1)
        const regexTest = new RegExp("\\[" + this.props.language + "\\].*?\"([\\S\\s]*?)\"")
        const { Panel } = Collapse;
        let articleCategories = this.props.articleCategories;
        if (articleCategories !== {}) {

            const customPanelStyle = {
                background: '#f5f5f5',
                border: 0,
                overflow: 'hidden',
              };

            return (
                <Collapse accordion bordered={false} defaultActiveKey={['1']}>
                {
                    /** Return article categories */
                    _.map(articleCategories, category => {
                        /** Translate the name for article categories */
                        const matchName = regexTest.exec(category.name);
                        const translatedName = (matchName && matchName.length > 1) ? matchName[1] : category.name;
                        return (
                            <Panel header={translatedName} key={category.id} style={customPanelStyle}>
                                {
                                    _.map(category.articleGroups, group => {
                                        const matchName = regexTest.exec(group.name);
                                        /* Translate the name for groups */
                                        const translatedName = (matchName && matchName.length > 1) ? matchName[1] : group.name;

                                        const buttonClasses = ['btn-link'];

                                        if (group.id === parseInt(groupId)) {
                                            buttonClasses.push('btn-link__active')
                                        }

                                        /* Return groups for every category */
                                        return (
                                            <div>
                                                <Button type="link"
                                                        key={group.id}
                                                        className={buttonClasses.join(' ')}
                                                        onClick={() => this.onGroupChange(group.id)}
                                                >
                                                    {translatedName}
                                                </Button>
                                            </div>
                                        )
                                    })
                                }
                            </Panel>
                        )
                    })
                }
                </Collapse>
            )
        }
        return null
    }

    renderAside() {
        if (window.innerWidth >= 768) {
            return (
                <aside className="sidebar-categories">
                    {this.renderArticleCategories()}
                </aside>
            )
        }
        return null
    }

    render() {

        if(!this.props.loggedIn && !this.props.publicTenant) {
            this.props.history.push('/')
            return null
        }
        return (
            <React.Fragment>
                <BreadCrumbs pagename={this.renderShopName()}/>
                <main className="main typeset">
                    <div className="main-content__products">
                        {
                            /** Sidebar with categories and groups */
                            this.renderAside()
                        }

                        {/** Main content with sorted by group article containers. See {ProductsByGroup} component */}
                        <Switch>
                            <Route path={`${this.props.match.path}/:group`} component={ProductsByGroup}/>
                        </Switch>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        articleCategories: state.customer.articleCategories,
        defaultArticleGroup: state.customer.defaultArticleGroup,
        products: state.products,
        loggedIn: state.auth.loggedIn,
        language: state.language.language.language,
        publicTenant: state.auth.publicTenant,
        tenantId: state.auth.tenantId,
    }
}

export default connect(mapStateToProps, {getArticleCategories})(ShopPage);