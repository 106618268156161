import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import ChangeDeliveryAddress from './ChangeDeliveryAddress';
import ChangeInvoiceAddress from './ChangeInvoiceAddress';
import ChangePostalAddress from "./ChangePostalAddress";
import {connect} from "react-redux";
import {getCountries} from "../../actions/actions";
import i18next from 'i18next';

/** Component representing a user account information page */
class Information extends Component {
    constructor(props) {
        super(props);
        this.renderPostalAddress = this.renderPostalAddress.bind(this);
    }

    componentDidMount() {
        this.props.getCountries()
    }

    /** Postal address should be rendered only for Loomis shop */
    renderPostalAddress() {
        if (!this.props.publicTenant) {
            return (
                <div className="information-address">
                    <p className="information-address__subtitle bold">{i18next.t('account.information.post_address')}</p>
                    <ChangePostalAddress/>
                </div>
            )
        }
       return null
    }

    render() {
        return (
            <div className="main-content">
                <div className="placeholder">
                    <div className="main-content__primary">
                        <h2 className="preamble">{i18next.t('account.information.my_information')}</h2>
                        <div className="information-address">
                            <p className="information-address__subtitle bold">{i18next.t('account.information.delivery_address')}</p>
                                <ChangeDeliveryAddress/>
                        </div>

                        {this.renderPostalAddress}

                        <div className="information-address">
                            <p className="information-address__subtitle bold">{i18next.t('account.information.invoice_address')}</p>
                                <ChangeInvoiceAddress/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        publicTenant: state.auth.publicTenant,
    }
}

export default withRouter( connect(mapStateToProps, {getCountries})(Information) );