import React from 'react';
import {change, Field, formValueSelector} from "redux-form";
import {reduxForm} from "redux-form";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { LinkButton, LinkButtonEdit, LinkButtonSave } from '../../components/links/LinkButton';
import {selectBox, textField, numberField } from "../../components/form/formFields";
import {changeAddress, SET_STREETS} from '../../actions/actions';
import ApiConnector from "../../api/apiConnection";
import store from './../../store/store';
import i18next from 'i18next';
import {Alert} from "antd";

const validate = (values) => {
    const errors = {}
    if(!values.deliveryRecipient) {
        errors.deliveryRecipient = i18next.t('errors.required')
    }
    if(!values.deliveryStreetName) {
        errors.deliveryStreetName = i18next.t('errors.required')
    }
    if(!values.deliveryZipCode) {
        errors.deliveryZipCode = i18next.t('errors.required')
    }
    if(!values.deliveryCity) {
        errors.deliveryCity = i18next.t('errors.required')
    }
    if(!values.deliveryCountry) {
        errors.deliveryCountry = i18next.t('errors.required')
    }
    return errors
}

const asyncValidate = (values, dispatch) => {
    const state = store.getState();
    const addressValidation = (selector(state, 'deliveryCountry') && selector(state, 'deliveryCountry').toLowerCase() === state.address.installationCountry.toLowerCase());
    if(addressValidation) {
        return ApiConnector.validateZipCode(values.deliveryZipCode)
            .then(response => {
                if (response.data === true) {
                    ApiConnector.getCity(values.deliveryZipCode)
                        .then(response => {
                            dispatch(change('deliveryAddress', 'deliveryCity', response.data))
                        })
                    ApiConnector.getStreetNames(values.deliveryZipCode)
                        .then(response => {
                            dispatch({
                                type: SET_STREETS,
                                streets: response.data
                            });
                            if(response.data && response.data.length > 0) {
                                dispatch(change('deliveryAddress', 'deliveryStreetName', response.data[0]))
                            }
                        })
                } else {
                    dispatch(change('deliveryAddress', 'deliveryCity', ''))
                    dispatch({
                        type: SET_STREETS,
                        streets: []
                    })
                    throw {deliveryZipCode: 'Invalid'}
                }
                dispatch(change('deliveryAddress', 'deliveryStreetNumber', ''))
                dispatch(change('deliveryAddress', 'deliveryStreetLetter', ''))
            })
    } else {
        return Promise.resolve()
            .then(() => {
                if(!values.deliveryZipCode) {
                    throw {deliveryZipCode: 'Required'}
                }
            })
    }
}

class ChangeDeliveryAddress extends React.Component {
    constructor(props) {
        super(props);
        this.changeDeliveryAddress = this.changeDeliveryAddress.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.renderButtons = this.renderButtons.bind(this);
        this.renderPostCode = this.renderPostCode.bind(this);
        this.state = {
            edit: false
        }
    }
    toggleEdit() {
        if(!this.state.edit && this.props.streets && this.props.addressValidation) {
            ApiConnector.validateZipCode(this.props.initialValues.deliveryZipCode)
                .then(response => {
                    if (response.data === true) {
                        ApiConnector.getCity(this.props.initialValues.deliveryZipCode)
                            .then(response => {
                                store.dispatch(change('deliveryAddress', 'deliveryCity', response.data))
                            })
                        ApiConnector.getStreetNames(this.props.initialValues.deliveryZipCode)
                            .then(response => {
                                store.dispatch({
                                    type: SET_STREETS,
                                    streets: response.data
                                })
                            })
                    }
                })
        } else {
            this.props.reset()
        }
        this.setState({edit: !this.state.edit})
    }
    changeDeliveryAddress(values) {
        this.toggleEdit();
        const deliveryAddress = {
                recipient: values.deliveryRecipient,
                streetName: values.deliveryStreetName,
                streetNumber: values.deliveryStreetNumber,
                streetLetter: values.deliveryStreetLetter,
                zipCode: values.deliveryZipCode,
                city: values.deliveryCity,
                country: values.deliveryCountry
            };
        if(!deliveryAddress.streetNumber) {
            deliveryAddress.streetNumber = null
        }
        store.dispatch({
            type: SET_STREETS,
            streets: []
        });
        this.props.reset();
        return this.props.changeAddress('deliveryAddress', deliveryAddress);
        // if(this.props.location === '/checkout') {
        //     this.props.reset();
        //     return this.props.changeAddress('deliveryAddress', deliveryAddress).then(() => {
        //         this.props.resetLocation();
        //         this.props.history.push('/checkout');
        //     })
        // } else {
        //     this.props.reset();
        //     return this.props.changeAddress('deliveryAddress', deliveryAddress)
        // }
    }
    renderButtons() {
        if(this.state.edit) {
                return (
                    <div>
                        <LinkButtonSave
                            title={i18next.t('account.information.save')}
                            extraClasses='save-button'/>
                        <LinkButton
                            path="#"
                            title={i18next.t('account.information.cancel')}
                            onClick={this.toggleEdit} />
                    </div>
                )
        } else {
            return  <LinkButtonEdit
                path='#'
                title={i18next.t('account.information.edit')}
                extraClasses='edit-button'
                onClick={this.toggleEdit}
            />
        }
    }
    renderPostCode() {
        if(!this.state.edit || this.props.country) {
            return <Field
                name='deliveryZipCode'
                label={i18next.t('account.information.postcode')}
                placeholder={i18next.t('account.information.postcode')}
                component={textField}
                maxLength="50"
                type="text"
                extraClasses='field-content--25'
                readOnly = {!this.state.edit}
                autocomplete="off"
            />
        }
    }
    renderCityAndStreet() {
        if(!this.state.edit || this.props.city || (this.props.country && !this.props.addressValidation)) {
            let options = this.props.streets
            let streetComponent = selectBox
            if( (this.props.streets.length === 0 && this.props.initialValues) || !this.state.edit || !this.props.addressValidation) {
                streetComponent = textField
                options = [this.props.initialValues.deliveryStreetName]
            }
            return <React.Fragment>
                <Field
                    name='deliveryCity'
                    label={i18next.t('account.information.city')}
                    placeholder={i18next.t('account.information.city')}
                    component={textField}
                    maxLength="50"
                    type="text"
                    extraClasses='field-content--50'
                    readOnly = {this.props.addressValidation || !this.state.edit}
                />
                <Field
                    name='deliveryStreetName'
                    label={i18next.t('account.information.street_name')}
                    placeholder={i18next.t('account.information.street_name')}
                    component={streetComponent}
                    options={options}
                    maxLength="50"
                    type="text"
                    extraClasses='field-content--50'
                    readOnly = {!this.state.edit}
                />
                <Field
                    name='deliveryStreetNumber'
                    label={i18next.t('account.information.street_number')}
                    placeholder={i18next.t('account.information.street_number')}
                    component={numberField}
                    maxLength="50"
                    extraClasses='field-content--25'
                    readOnly = {!this.state.edit}
                />

                <Field
                    name='deliveryStreetLetter'
                    label={i18next.t('account.information.street_number_letter')}
                    placeholder={i18next.t('account.information.street_number_letter')}
                    component={textField}
                    maxLength="50"
                    type="text"
                    extraClasses='field-content--25'
                    readOnly = {!this.state.edit}
                />
            </React.Fragment>
        }
    }
    render() {
        const {handleSubmit, error} = this.props;
        return (
            <React.Fragment>
                <form onSubmit={handleSubmit(this.changeDeliveryAddress)}>
                    <div className="field-wrapper">
                        <h3 className="field-wrapper__title">{i18next.t('account.information.recipient')}</h3>
                        <Field
                            name='deliveryRecipient'
                            label={i18next.t('account.information.recipient')}
                            placeholder={i18next.t('account.information.recipient')}
                            component={textField}
                            maxLength="50"
                            type="text"
                            extraClasses='field-content--100'
                            readOnly = {!this.state.edit}
                        />
                    </div>
                    <div className="field-wrapper field-wrapper--margin-top">
                        <h3 className="field-wrapper__title">{i18next.t('account.information.address')}</h3>
                        <Field
                            name='deliveryCountry'
                            label={i18next.t('account.information.country')}
                            placeholder={i18next.t('account.information.country')}
                            component={selectBox}
                            options={this.props.countries}
                            extraClasses='field-content--25'
                            readOnly = {!this.state.edit || !this.props.publicTenant}
                        />
                        {this.renderPostCode()}
                        {this.renderCityAndStreet()}
                    </div>
                    { error && <Alert
                        message="Error"
                        description={error}
                        type="error"
                        style={{marginTop: '10px'}}
                        showIcon
                    /> }
                    {this.renderButtons()}
                </form>
            </React.Fragment>
        )
    }
}
const selector = formValueSelector('deliveryAddress')
function mapStateToProps(state) {

    let initialValues = {};

    if(state.customer.deliveryAddress) {
        const {deliveryAddress} = state.customer;
        initialValues = {...initialValues,
            deliveryRecipient: deliveryAddress.recipient ? deliveryAddress.recipient : '',
            deliveryStreetName: deliveryAddress.streetName ? deliveryAddress.streetName : '',
            deliveryStreetNumber: deliveryAddress.streetNumber ? deliveryAddress.streetNumber : '',
            deliveryStreetLetter: deliveryAddress.streetLetter ? deliveryAddress.streetLetter : '',
            deliveryZipCode: deliveryAddress.zipCode ? deliveryAddress.zipCode : '',
            deliveryCity: deliveryAddress.city ? deliveryAddress.city : '',
            deliveryCountry: deliveryAddress.country ? deliveryAddress.country.replace('Sverige', 'Sweden') : ''
        }
    } else {
        initialValues = {
            ...initialValues,
            deliveryCountry: 'Sweden'
        }
    }
    const addressValidation = (selector(state, 'deliveryCountry') && selector(state, 'deliveryCountry').toLowerCase() === state.address.installationCountry.toLowerCase())
    return {
        initialValues,
        country: selector(state, 'deliveryCountry'),
        postCode: selector(state, 'deliveryZipCode'),
        streetName: selector(state, 'deliveryStreetName'),
        countries: state.address.countries,
        streets: state.address.streets,
        city: selector(state, 'deliveryCity'),
        addressValidation,
        publicTenant: state.auth.publicTenant
    }
}

ChangeDeliveryAddress = reduxForm({
    form: 'deliveryAddress',
    validate,
    asyncValidate,
    enableReinitialize: true,
    asyncChangeFields: ['deliveryZipCode']
})(ChangeDeliveryAddress);
export default withRouter( connect(mapStateToProps, {changeAddress})(ChangeDeliveryAddress) );
