import React from 'react';
import BreadCrumbs from '../../components/navigation/BreadCrumbs';
import { LinkButtonLeft, LinkButtonSave } from '../../components/links/LinkButton';
import GeneralInfo from './GeneralInfo';
import {Alert} from "antd";
import { formValueSelector, reduxForm } from "redux-form";
import {createAccount, getCountries} from "../../actions/actions";
import {connect} from "react-redux";
import i18next from 'i18next';


const validate = (values) => {

    const errors = {};

    if(!values.email) {
        errors.email = i18next.t('errors.required')
    }
    if(!values.password) {
        errors.password = i18next.t('errors.required')
    }
    if(!values.repeatPassword) {
        errors.repeatPassword = i18next.t('errors.confirm_password')
    }
    if(values.password !== values.repeatPassword) {
        errors.repeatPassword = i18next.t('errors.not_match_pass')
    }
    if(!values.corporateId) {
        errors.corporateId = i18next.t('errors.required')
    }
    if(!values.companyName) {
        errors.companyName = i18next.t('errors.required')
    }
    if(!values.customerType) {
        errors.customerType = i18next.t('errors.required')
    }
    return errors
}


let CreateAccountPage = (props) => {

    function createAccount(values) {
        const data = {
                customerType: values.customerType,
                email: values.email,
                password: values.password,
                passwordConfirm: values.repeatPassword,
                companyName: values.companyName,
                newsletter: values.newsletter
        };
        if (values.customerType === 'business') {
            data.corporateId = values.corporateId;
        }
        return props.createAccount(data).then(() => props.history.push('/'));
        }

        const {handleSubmit, error} = props;
        return (

            <div className="wrapper">
                <BreadCrumbs pagename={i18next.t('create_account.create_account')} />

                <main className="main typeset">
                    <div className="c">
                        <div className="page-header">
                            <div className="page-header__col-one">
                                <h1 className="page-header__title">{i18next.t('create_account.create_account')}</h1>
                            </div>
                        </div>

                        <div className="main-content">
                            <div className="placeholder">
                                <div className="main-content__primary">

                                    <form onSubmit={handleSubmit(createAccount)}>
                                        { error && <Alert
                                            message="Error"
                                            description={error}
                                            type="error"
                                            style={{marginBottom: '10px'}}
                                            showIcon
                                        /> }

                                        <GeneralInfo {...props} />

                                        <LinkButtonSave title={i18next.t('create_account.create_account')} extraClasses='' />
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )

};

const selector = formValueSelector('createAccount');
function mapStateToProps(state) {
    const {businessPrivateToggle} = state.auth;

    return {
        businessPrivateToggle,
        initialValues: {
            customerType: businessPrivateToggle === false ? 'business' : null,
        },
        customerType: selector(state, 'customerType'),
    }
}

CreateAccountPage = reduxForm({
    form: 'createAccount',
    validate,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(CreateAccountPage);



export default connect(mapStateToProps, { createAccount, getCountries }) (CreateAccountPage);
