import React from 'react';
import {Field} from "redux-form";
import {checkBox, textArea} from "../../components/form/formFields";
import i18next from 'i18next';
import {withRouter} from "react-router-dom";

const Message = (props) => {

    return(
        <div className='checkout-step checkout-step--delivery'>
            <div className='checkout-step-header'>
                <h4 className='checkout-step-header__title'>5. {i18next.t('checkout_page.message')}</h4>
            </div>
            <div className='checkout-step-content'>
                <h3 className='checkout-step-content__subtitle'>{i18next.t('checkout_page.message_description')}</h3>
                <div className='checkout-step-content-wrapper checkout-step-content-wrapper--card'>
                    <div className='checkout-step-content-wrapper__col-one'></div>

                    <div className="checkout-step-content-wrapper__col-two">

                        <div className="field-content-wrapper">
                            <Field
                                name='includeMessage'
                                label={i18next.t('checkout_page.message_confirm')}
                                component={checkBox}
                            />
                        </div>

                        <div className="field-wrapper">
                            <Field
                              name='message'
                              component={textArea}
                              label={i18next.t('checkout_page.message_placeholder')}
                              placeholder={i18next.t('checkout_page.message_placeholder')}
                              disabled={!props.includeMessage}
                                />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
};
export default withRouter(Message);