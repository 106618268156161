import React from 'react';
import BreadCrumbs from './navigation/BreadCrumbs';
import Background from '../img/hero.jpg';
import i18next from 'i18next';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

const AboutPage = (props) => {

    function renderImage() {
        if(!props.publicTenant) {
            return <img src={Background} alt='hero' />
        } else {
            return null
        }
    }

    return (
        <div className="wrapper">
            <BreadCrumbs pagename={i18next.t(`about_us_page.description-${props.tenantId}.page_name`)} />
            <main className="main typeset">
                <div className="main-content main-content--padding-top">
                    <div className="c">

                        <article className="main-content__primary main-content__primary--page">
                            {props.tenantId === 5 
                                ? (
                                    <>
                                     <h1>{i18next.t(`about_us_page.description.title`)}</h1>
                                     { renderImage() }
                                     <p>{i18next.t(`about_us_page.description.text1`)}</p>
                                     <p>{i18next.t(`about_us_page.description.text2`)}</p>
                                     <p>{i18next.t(`about_us_page.description.text3`)}</p>
                                     <p>{i18next.t(`about_us_page.description.text4`)}</p>
                                    </>
                                )
                                : (
                                    <>
                                    <h1>{i18next.t(`about_us_page.description-${props.tenantId}.title`)}</h1>
                                    { renderImage() }
                                    <p>{i18next.t(`about_us_page.description-${props.tenantId}.text`)}</p>
                                    </>
                                )
                            }
                        </article>

                    </div>

                </div>
            </main>
        </div>
    )
};

function mapStateToProps(state) {
    return {
        publicTenant: state.auth.publicTenant,
        tenantId: state.auth.tenantId
    }
}

export default withRouter(connect(mapStateToProps, null) (AboutPage) );