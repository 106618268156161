import React, {useEffect, useState} from 'react';
import { ReactComponent as CloseIcon }  from  '../../../img/x.svg';
import styles from './CookiesNotice.module.scss'
import i18next from 'i18next';


const CookiesNotice = props => {
    const {openModal, accepted, setAccepted} = props;
    const [openBanner, setOpenBanner] = useState(localStorage.getItem('closeCookieModal'));


    return (
        <div className={`${styles.wrapper} ${!openBanner ? styles.open: ''}`}>
            <div className={styles.content}>
                {i18next.t('cookie_page.popup_desc')}
                <a href='/cookieinformation' style={{color: '#fff', marginLeft: 5}}>{i18next.t('cookie_page.popup_link')}</a>
                <div className={styles.buttons}>
                    <button onClick={() => {openModal(true)}} className={styles.outlined}>{i18next.t('manage-cookies')}</button>
                </div>
            </div>
            <div className={styles.closeButtonContainer} onClick={() => {
                setOpenBanner(true)
                localStorage.setItem('closeCookieModal', true)
                }}>
                <CloseIcon />
            </div>
        </div>
    );
};

export default CookiesNotice;
