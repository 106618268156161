import React, {Component} from 'react';
import { Link, withRouter } from 'react-router-dom';
import {SERVER_URL, IMAGE_PATH} from '../config/config';
import {connect} from "react-redux";
import {addToCart} from "../actions/actions";
import ImagePlaceholder from '../img/placeholder.png';
import _ from "lodash";
import ReactHtmlParser from "react-html-parser";

class ProductList extends Component {

    constructor(props)  {
        super(props);
        const { item } = this.props;

        let price = '';
        let articleNumber = ''
        if(item.articles[0] ) {
            price = item.articles[0].price;
            articleNumber = item.articles[0].articleNumber
        }

        this.state = {
            price,
            articleNumber
        }
        this.changeArticle = this.changeArticle.bind(this);
        this.addToCart = this.addToCart.bind(this);
    };

    componentWillReceiveProps(nextProps) {
        const { item } = nextProps;
        let price = '';
        let articleNumber = ''
        if(item.articles[0] ) {
            price = item.articles[0].price;
            articleNumber = item.articles[0].articleNumber
            this.setState({
                price,
                articleNumber
            });
        }
    }

    changeArticle(evt) {
        const selectedArticle = this.props.item.articles.find (article => {
            return article.articleNumber === evt.target.value
        })
        this.setState({
            price: selectedArticle.price,
            articleNumber: selectedArticle.articleNumber
        })
    }

    addToCart() {
        const {item} = this.props
        const article = item.articles.find( article => {
            return article.articleNumber === this.state.articleNumber
        })
        this.props.addToCart(item, article, 1)
    }

    render() {
        const { item } = this.props;

        const regexTest = new RegExp("\\[" + this.props.language + "\\].*?\"([\\S\\s]*?)\"")
        const matchName = regexTest.exec(item.name)
        const matchDescription = regexTest.exec(item.description)

        const translatedName = (matchName && matchName.length > 1) ? matchName[1] : item.name
        const translatedDescription = ReactHtmlParser((matchDescription && matchDescription.length > 1) ? matchDescription[1] : item.description)

        const source = () => {
            const fileName = _.get(_.head(item.images), 'fileName');
            if(fileName) {
                return `${SERVER_URL}${IMAGE_PATH}${fileName}`
            }
            return `${ImagePlaceholder}`
        };

        const articleOptions = item.articles.map(article => {
            return <option key={article.articleNumber} value={article.articleNumber}>{article.quantity}</option>
        })

        return (
            <article className="product-card-list">
                <Link to={`/article/${item.id}`} className="product-card-list__link">
                    <div className="product-card-list-image">
                <span className="product-card-list-image-wrapper" style={{fontSize: '12px', textAlign: 'left'}}>
                    <img src={source()} alt={translatedName} className="product-card-list-image__img" />
                </span>
                    </div>
                    <div className="product-card-list-content">
                        <h2 className="product-card-list-content-title">
                    <span className="product-card-list-content-title__text">
                        {translatedName}
                    </span>
                        </h2>
                        <p className="product-card-list-content-description">
                    <span className="product-card-list-content-description__text">
                        {translatedDescription}
                    </span>
                        </p>
                        <p className="product-card-list-content-artno">
                    <span className="product-card-list-content-artno__text">
                        {item.articles.articleNumber}
                    </span>
                        </p>
                    </div>
                </Link>
                <div className="product-card-list-actions">
                    <div className="product-card-list-actions-cols">
                        <div className="product-card-actions__col-one">
                            <select className="product-card-actions__select" onChange={this.changeArticle}>
                                {articleOptions}
                            </select>
                            <p className="product-card-actions__current-price">
                                {this.props.privateCustomerType ? this.state.price * `1.${this.props.vat}` :  this.state.price} {this.props.currency}
                            </p>
                        </div>
                        <div className="product-card-actions__col-two" onClick={this.addToCart}>
                            <svg className="product-card-actions__icon">
                                <use xlinkHref="#cart"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </article>
        )
    }
};

function mapStateToProps(state) {
    return {
        language: state.language.language.language,
        privateCustomerType: state.customer.privateCustomerType,
        vat: state.auth.vat
    }
}

export default withRouter( connect(mapStateToProps, {addToCart})(ProductList) );