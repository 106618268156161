import React from "react";
import { Select, Input, Checkbox} from 'antd';

export const textField = ({
                              name,
                              input,
                              label,
                              type,
                              placeholder,
                              extraClasses,
                              readOnly,
                              required,
                              style,
                              scrollBy,
                              meta: {touched, error, submitting}
                          }) => {
    let classes = 'field-content ' + extraClasses
    if(touched && error) {
        classes += ' has-error'
    }

    return (
        <div className={classes}>
            <label className="field-content__label" htmlFor={name}><span>{label}</span></label>
            <Input
                {...input}
                readOnly={readOnly}
                placeholder={placeholder}
                disabled={submitting || readOnly}
                type={type}
                style={style}
                className='field-content__input field-content__input--border'
            />
            {touched && error && <span className='help-block'>{error}</span>}
        </div>
    )
}

export const textArea = ({
                             name,
                             input,
                             label,
                             disabled,
                             placeholder,
                             meta: {touched, error, submitting}
                         }) => {
    return (
        <div className="field-content">
            <label className="field-content__label label-screen" htmlFor="textarea-1">{label}</label>
            <Input.TextArea
                {...input}
                disabled={disabled}
                className="field-content__textarea field-content__textarea--border"
                placeholder={placeholder}
            />
        </div>
    )
}

export const radioButton = ({
    name,
    input,
    label,
    description,
    value,
    defaultChecked,
    id,
    onClick,
    checked,
    meta: {touched, error, submitting}
}) => {
    return (
        <div className="checkout-step-content-wrapper">
            <div className="checkout-step-content-wrapper__col-one">
                <input {...input} disabled={submitting} className="checkout-step-content__input" type="radio" checked={checked} id={id}  onClick={onClick} />
                <label htmlFor={id} className="label-screen">{label}</label>
            </div>

            <div className="checkout-step-content-wrapper__col-two">
                <div className="field-content-wrapper">
                    <div className="field-content">
                        <p className="field-content__text">{description}</p>
                        {touched && error && <span className='error-label'>{error}</span>}
                    </div>
                </div>
            </div>
        </div>
    )
};

export const checkBox = ({
                             name,
                             input,
                             label,
                             description,
                             extraClasses,
                             placeholder,
                             type,
                             disabled,
                             defaultChecked,
                             // checked,
                             meta: {touched, error, submitting}
                         }) => {
    let classes = 'field-content ' + extraClasses;
    if(touched && error) {
        classes += ' has-error'
    }
    return (
        <div className={classes}>
            <Checkbox {...input}
                      disabled={disabled}
                      type="checkbox"
                      id={name}
                      defaultChecked={defaultChecked}
                      // checked={checked}
            />
            <label className="field-content__small-text" htmlFor={name}>{label}</label>
            {touched && error && <span className='help-block'>{error}</span>}
        </div>
    )
}

export const selectBox = ({
                              name,
                              input,
                              label,
                              options,
                              readOnly,
                              placeholder,
                              extraClasses,
                              meta: {touched, error, submitting}
                          }) => {
    let classes = 'field-content ' + extraClasses;
    if(touched && error) {
        classes += ' has-error'
    }
    const selectOptions = options.map( option => {
        return <Select.Option key={option} >{option}</Select.Option>
    })
    return (
        <div className={classes}>
            <Select {...input} disabled={submitting || readOnly } placeholder={placeholder}  size='large' >
                {selectOptions}
            </Select>
            {touched && error && <span className='help-block'>{error}</span>}
        </div>
    )
}

export const numberField = ({
                              name,
                              input,
                              label,
                              placeholder,
                              extraClasses,
                              readOnly,
                              meta: {touched, error, submitting}
                          }) => {
    let classes = 'field-content ' + extraClasses
    if(touched && error) {
        classes += ' has-error'
    }

    const onChange = (e) => {
        const { value } = e.target;
        const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
            input.onChange(value);
        }
    }

    return (
        <div className={classes}>
            <label className="field-content__label" htmlFor={name}><span>{label}</span></label>
            <Input {...input} onChange={(e) => onChange(e)} readOnly={readOnly} placeholder={placeholder} disabled={submitting || readOnly} className='field-content__input field-content__input--border' />
            {touched && error && <span className='help-block'>{error}</span>}
        </div>
    )
}
